import { Injectable } from "@angular/core";
import { UrlBuilderService } from "./services/url-builder.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Auth0Service } from "./auth0.service";

@Injectable()
export class FeaturesService {
  constructor(
    private http: HttpClient,
    private urlBuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  private getFeaturesFromServer() {
    return this.http
      .get<any>(
        `${this.urlBuilder.propertyApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat(`/settings/features`)}`,
      )
      .toPromise();
  }


  getAllModules(): Promise<any> {
    return this.getFeaturesFromServer();
  }
}
