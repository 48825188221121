import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { RequestsManagerService } from "../../service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ComponentMonitorResultModule } from "../../../components/monitor-result/module";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    TooltipModule,
    ComponentMonitorResultModule
  ],
  exports: [MyComponent],
  providers: [RequestsManagerService],
})
export class RequestsPreviewGuestInformationModule { }
