import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { MaterialModule } from "../../../../material.module";
import { CheckInTimerModule } from "../../timer/module";
import { FormsModule } from '@angular/forms';
import { CheckInService } from "../../service";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule,
    CheckInTimerModule
  ],
  exports: [MyComponent],
  providers: [CheckInService]
})
export class CheckInPreviewDetailModule { }
