import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../services/url-builder.service";
import { Auth0Service } from "../auth0.service";
import { environment } from "../../environments/environment";

@Injectable()
export class RequestsManagerService {
  getFiltersDefaults: any = {
    loadingSearch: false,
    searching: false,
    searchInComments: false,
    contentImg: false,
    searchTxt: "",
    order: "priority-desc",
    state: "active",
    statuses: [],
    feedback: [],
    date: [null, null],
    location: "",
    type: "All",
    who: "",
    where: "",
    is: null,
    includeSuggested: null,
    assigneeId: null,
    creationUserId: null,
    priority: "All",
    includeComments: "true",
    departmentId: null,
    categoryId: null,
    subcategoryId: null,
  };

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  getReservationById(reservationId: string): any {
    return this.http
      .get<any>(
        `${this.urlbuilder.appApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/reservations?confirmation=")
          .concat(reservationId)}`
      )
      .toPromise()
      .then((response) => response[0]);
  }

  getAllRooms() {
    return this.http
      .get<any>(
        `${this.urlbuilder.subscriptionApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/rooms")}`
      )
      .toPromise();
  }

  getById(input) {
    return this.http
      .get<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)}`
      )
      .toPromise();
  }


  create(data) {
    if (!data.executionDate) {
      data.executionDate = new Date();
    }
    return this.http.post<any>(`${this.urlbuilder.requestsApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      ).concat('/request')}`, Object.assign({}, data, this.auth && this.auth.userProfile && this.auth.userProfile.id ? {
        userId: this.auth.userProfile.id,
        images: data.images.length > 0 ? data.images.map(imagen => ({ url: imagen.url, id: imagen.id })) : []
      } : {})).toPromise();
  }

  updateFuq(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/fuq")}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  updatePriority(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/priority")}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  updateAssignee(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/assignee")}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  updateStatus(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  updateStatusCancelled(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/cancelled")}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  updateWhen(input, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/when")}`,
        Object.assign({}, data, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  listAllStatuses() {
    return this.http
      .get<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/statuses")}`
      )
      .toPromise();
  }

  uploadImgs(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${this.auth.getChosenProperty
      }`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    ).toPromise();
  }

  uploadImgs2(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${this.auth.getChosenProperty
      }`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  listAllDepartmentsPlain(term: string, limit?: number) {
    return this.http
      .get<any>(
        this.urlbuilder.requestConfiguration.departments.listPlain() +
        `?status=active${term && term !== "" ? "&search=" + term : ""}${limit ? "&limit=" + limit : ""
        }`
      )
      .toPromise();
  }

  listAllUsers() {
    return this.http
      .get<any>(`${this.urlbuilder.appApi.concat("/people-center/users")}`)
      .toPromise();
  }

  listDepartmentsUsedIn(filter) {
    return this.http
      .get<any>(
        `${this.urlbuilder.propertyApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/process-builder/departments-by-monitor-used/normal")
          .concat(filter.state ? "?state=" + filter.state : "")}`
      )
      .toPromise();
  }

  exportExcelAll(filter: any) {
    const StrFecha = new Date().toString();
    const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
    const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
    const Horas = Math.round(HorasLocalesRespectoAGmt);
    const Minutos =
      HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt);

    let state;
    if (!filter.searching && filter.searchTxt.length == 0) {
      state = filter.state;
    } else if (filter.searching || filter.searchTxt.length > 0) {
      state = "all";
    }

    return new Promise((resolve, rejected) => {
      this.http
        .get<any>(
          this.urlbuilder.requestsApi
            .concat("/")
            .concat(this.auth.getChosenProperty.toString())
            .concat("/export-excel/")
            .concat(state)
            .concat("?")
            .concat(this.handleFilter(filter, true))
            .concat(
              `&gmt=${timeZone}&action=${Horas < 0 ? "add" : "subtract"
              }&hours=${Horas}&minutes=${Math.floor(Math.abs(Minutos * 100))}`
            ),
          { responseType: "blob" as "json" }
        )
        .subscribe(
          (response: any) => {
            let filename = "" + Date.now();
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob(binaryData, { type: dataType })
            );
            if (filename) downloadLink.setAttribute("download", filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            resolve(true);
          },
          (error: any) => {
            rejected(error);
          }
        );
    });
  }

  listFutures(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/futures")
      .concat(this.handleFilter(filter))}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listToDo(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/to-do")
      .concat(
        `?${filter.page ? "page=" + filter.page : ""}${"userId=" + this.auth.userProfile.id
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listValidate(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/validate")
      .concat(this.handleFilter(filter))}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listActives(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/actives")
      .concat(this.handleFilter(filter))}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  handleFilter(filter, disablePage: boolean = false) {
    return `?${!disablePage ? (filter.page ? `page=${filter.page}` : ``) : ``}${filter.searchTxt
      ? `&searchTxt=${encodeURIComponent(filter.searchTxt)}`
      : ``
      }${filter.date &&
        filter.date[0] &&
        filter.date[1] &&
        filter.date[0] != `null` &&
        filter.date[1] != `null`
        ? `&fromDate=${new Date(filter.date[0]).toISOString()}&toDate=${new Date(filter.date[1]).toISOString()}`
        : ``
      }${filter.who ? `&who=${encodeURIComponent(filter.who)}` : ``}${filter.where ? `&where=${encodeURIComponent(filter.where)}` : ``
      }${filter.location ? `&location=${encodeURIComponent(filter.location)}` : ``
      }${filter.type && filter.type != `All`
        ? `&type=${encodeURIComponent(filter.type)}`
        : ``
      }${filter.searchInComments
        ? `&searchInComments=${filter.searchInComments}`
        : ``
      }${filter.departmentId ? `&departmentId=${filter.departmentId}` : ``}${filter.categoryId ? `&categoryId=${filter.categoryId}` : ``
      }${filter.subcategoryId ? `&subcategoryId=${filter.subcategoryId}` : ``}${filter.statuses
        ? `&statuses=${encodeURIComponent(filter.statuses.toString())}`
        : ``
      }${filter.feedback
        ? "&feedback=" + encodeURIComponent(filter.feedback.toString())
        : ""
      }${filter.is ? `&is=${encodeURIComponent(filter.is)}` : ``}${filter.includeSuggested
        ? `&includeSuggested=${encodeURIComponent(filter.includeSuggested)}`
        : ``
      }${filter.includeComments
        ? `&includeComments=${encodeURIComponent(filter.includeComments)}`
        : ``
      }${filter.assigneeId ? `&assigneeId=${filter.assigneeId}` : ``}${filter.priority && filter.priority != `All`
        ? `&priority=${filter.priority}`
        : ``
      }${filter.creationUserId ? `&creationUserId=${filter.creationUserId}` : ``
      }${filter.contentImg ? `&contentImg=${filter.contentImg}` : ``}${filter.typeWidget ? `&typeWidget=${filter.typeWidget}` : ``
      }${filter.from && filter.from == 'dashboard'
        ? `&from=${encodeURIComponent(filter.from)}` : ``
      }${filter.order ? `&order=${encodeURIComponent(filter.order)}` : ``}`;
  }

  listAll(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/all")
      .concat(this.handleFilter(filter))}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listFinished(filter: any, onlyCount = false) {
    const URL = `${this.urlbuilder.requestsApi
      .concat("/")
      .concat(this.auth.getChosenProperty.toString())
      .concat("/finished")
      .concat(this.handleFilter(filter))}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  createNewGuestRequest(data) {
    return this.http
      .post<any>(
        `${this.urlbuilder.appApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/requests-new/guest")}`,
        data
      )
      .toPromise();
  }

  commentCreate(input, data) {
    return this.http
      .post<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/comment")}`,
        {
          body: data.body,
          userId:
            this.auth && this.auth.userProfile && this.auth.userProfile.id
              ? this.auth.userProfile.id
              : undefined,
          type: data.type,
          images: data.images,
        }
      )
      .toPromise();
  }

  commentMarkToRead(input, type = "internal") {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(input.id)
          .concat("/comment")}`,
        {
          userId:
            this.auth && this.auth.userProfile && this.auth.userProfile.id
              ? this.auth.userProfile.id
              : undefined,
          type: type,
        }
      )
      .toPromise();
  }

  deleteComment(data) {
    return this.http
      .delete<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(data.requestId)
          .concat("/comment/")
          .concat(data.id)}`,
        {}
      )
      .toPromise();
  }

  searchWho(e: any) {
    return this.http
      .get<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/search-who")
          .concat(`${e ? "?q=" + encodeURIComponent(e) : ""}`)}`
      )
      .toPromise();
  }

  searchWhere(e: any) {
    return this.http
      .get<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/search-where")
          .concat(`${e ? "?q=" + encodeURIComponent(e) : ""}`)}`
      )
      .toPromise();
  }

  listAllDepartments(e: string) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      ).concat("/departments").concat(
        `${e
          ? "?q=" + encodeURIComponent(e)
          : ""
        }`
      )}`).toPromise();
  }

  searchForMatches(data: {
    where: any;
    what: {
      processId: any;
      departmentSuggested: any;
      subcategorySuggested: any;
    };
  }) {
    return this.http.post<any>(`${this.urlbuilder.requestsApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/search-for-matches")
      }`, data).toPromise();
  }

  searchWhat(e: string) {
    return this.http
      .get<any>(
        `${this.urlbuilder.propertyApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/search-what")
          .concat(`${e ? "?q=" + encodeURIComponent(e) : ""}`)}`
      )
      .toPromise();
  }

  updateGeneral(id: string, request) {
    return this.http
      .put<any>(
        `${this.urlbuilder.requestsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/" + id)
          .concat("/general")}`,
        Object.assign({}, request, { userId: this.auth.userProfile.id })
      )
      .toPromise();
  }

  getSubcategoryById(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${this.urlbuilder.appApi
          .concat("/process-builder/process")
          .concat("/" + id)}`
      )
      .toPromise();
  }

  getCategoryById(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${this.urlbuilder.appApi
          .concat("/process-builder/category")
          .concat("/" + id)}`
      )
      .toPromise();
  }
}
