<div class="modal up">

  <header class="modal-content text-inicial mod__new__sett col s12 l12 m12 xl12 center">
    <h1 *ngIf="config.title">
      {{ config.title | translate}}
    </h1>
  </header>
  <div class="modal-content scoll-me text-inicial  col s12 l12 m12 xl12 center"
    [ngClass]="{'removePadding': config.title}">
    <div class="cont__message--attention" *ngIf="config.description">
      <span class="text__title">{{ config.description | translate}}</span>
    </div>

    <div class="list_radious" *ngIf="config.radio">
      <label *ngFor="let radio of config.radio" for="radio{{radio.value}}" style="margin-right: 24px;">
        <input class="with-gap" id="radio{{radio.value}}" name="radioOption" [(checked)]="radio.checked"
          (change)="changeRadio(radio)" type="radio" />
        <span style="margin-bottom: 1rem;"> {{ radio.title | translate }}</span>
      </label>
    </div>

    <div class="list_radious" *ngIf="config.list">
      <div *ngFor="let support of config.list" class="modalItem">
        <i class="material-icons">{{support.icon}}</i>
        <span style="padding-left: 8px;">
          {{ support.name | translate }}
        </span>
      </div>
    </div>

    <div class="list_radious" *ngIf="config.checkboxes">
      <label *ngFor="let checkbox of config.checkboxes" for="checkbox{{checkbox.value}}" style="margin-right: 24px;">
        <input class="with-gap" id="checkbox{{checkbox.value}}" name="radioOption" [(checked)]="checkbox.checked"
          (change)="changeCheckbox(checkbox, $event.target.checked)" type="checkbox" />
        <span style="margin-bottom: 1rem;"> {{ checkbox.title | translate }}</span>
      </label>
    </div>

    <div class="cont__all__chips" *ngIf="config.chips">
      <div class="cont__chip" *ngFor="let chip of config.chips | slice: 0 : (viewMore ? config.chips.length  : 5)">
        <span class="txt__chip">{{chip.name}}</span>
      </div>

      <div class="cont__viewmore pointer" (click)="viewMore = !viewMore" *ngIf="config.chips.length > 5">
        <span class="viewMore">{{(viewMore ?  'Hide' : 'See all') | translate}}</span>
        <i class="material-icons icon__viewMore" > {{viewMore ?  'keyboard_arrow_up' : 'expand_more' }}</i>

      </div>
      
      
    </div>

  </div>

  <footer class="modal-content text-inicial col s12 l12 m12 xl12 center">
    <div class="cont__buttons">
      <button *ngIf="config.textCancel" class="btn btn-success row button--return pointer" (click)="cancel.emit(true)">
        <span class="text--return">{{ config.textCancel | translate}} </span>
      </button>

      <button class="btn btn-success row button--go pointer" *ngIf="config.textConfirm" (click)="confirm.emit(true)"
        [disabled]="config.disabledButton" [ngClass]="{'button--delete': config.type == 'negative'}">
        <span class="text--delete">{{ config.textConfirm | translate}}</span>
      </button>
    </div>
  </footer>
</div>

<div class="modal-overlay ups" (click)="cancel.emit(true);"></div>