<div class="modal" style="border-radius: 8px; z-index: 994 !important;">
    <div class="modal-content text-inicial white">
        <div class="col s12 modal-margin-top modal-padding-leftright">
            <!-- <i class="material-icons right pointer color-base-two cancel-icon icon-back"
                (click)="closeModal()">clear</i> -->
        </div>

        <div class="container">
            <h2 style="  margin-left: 0.5rem !important;"
                class="col s12 color-base font24 blod modal-padding-leftright marg-top marg-bot title-task-modal">
                {{ "Customize recurring task" | translate }}
            </h2>
            <div class="clearfix"></div>

            <div class="modal-padding-leftright scroll-hei">
                <div class="col s12 padding0 startDate">
                    <label for="startDate" class="col s4 textInputDate">
                        {{ 'Start' | translate }}
                    </label>
                    <input id="startDate" class="col s8 textInputDate" [owlDateTime]="dt7"
                        placeholder="{{ 'Now' | translate }}" [owlDateTimeTrigger]="dt7"
                        [(ngModel)]="dataObject.startingDate" [min]="minDate">
                    <owl-date-time [hour12Timer]="'true'" (afterPickerClosed)="onKeySearch()" #dt7></owl-date-time>
                </div>

                <div class="col s12 padding0 startDate" style="margin-top: 0.5rem;">
                    <label for="repeatCount" class="col s6 textInputDate">
                        {{ 'Repeat every' | translate }}
                    </label>
                    <div class="col s1"></div>
                    <input style="margin: 0 0 3px 0; width: 2.5rem;" class="col s1 textInputDate" id="repeatCount"
                        max="99" [(ngModel)]="dataObject.repeatCount" min="1" type="number" autocomplete="off" required
                        [disabled]="dataObject.customizeType == 'no-repeat'">
                    <div class="col s1"></div>
                    <mat-select class="col s3" style="display: block;" [(ngModel)]="dataObject.customizeType"
                        id="selectRecurrency" class="cellInput" (selectionChange)="changeTypeRepeat($event.value)">
                        <mat-option value="no-repeat" selected>{{ 'Not repeat' | translate }}</mat-option>
                        <mat-option value="day">{{ 'Day' | translate }}</mat-option>
                        <mat-option value="week">{{ 'Week' | translate }}</mat-option>
                        <mat-option value="month">{{ 'Month' | translate }}</mat-option>
                        <mat-option value="year">{{ 'Year' | translate }}</mat-option>
                    </mat-select>
                </div>

                <div [ngSwitch]="dataObject.customizeType" class="col s12 padding0 ">
                    <ul *ngSwitchCase="'week'" class="list-day-week daysSelect prevent-select ">
                        <li class="pointer " *ngFor="let day of programmingRepeatService.daysOfWeek"
                            (click)="selectedDayOfWeek(day)" [ngClass]="{'active': isActiveDayOfWeek(day)}"
                            title="{{ day.name | translate }}">
                            {{ day.initial | translate }}
                        </li>
                    </ul>

                    <div *ngSwitchCase="'month'" class="col s12 padding0 startDate" style="margin-top: 0.5rem;">
                        <label for="repeatCount" class="col s2 textInputDate" style="margin:initial ;">
                            {{ 'Day' | translate }}
                        </label>
                        <div class="col s2"></div>
                        <input style="margin: 0px -4px 3px -6px ;width: 2.5rem;" class="col s1" id="repeatDay" max="99"
                            [(ngModel)]="dataObject.repeatDay" min="1" max="31" type="number" autocomplete="off"
                            required>
                        <div class="col s1"></div>
                        <div class="col s6" style="display: block;">
                        </div>
                    </div>
                    <div *ngSwitchCase="'year'" class="col s12 padding0 startDate">
                        <label for="repeatDay" class="col s4 textInputDate">
                            {{ 'Day' | translate }}
                        </label>
                        <input style="margin: 0px -4px 3px -6px ;width: 2.5rem;" class="col s1" id="repeatDay" max="99"
                            [(ngModel)]="dataObject.repeatDay" min="1" max="31" type="number" autocomplete="off"
                            required>
                        <div class="col s1"></div>
                        <mat-select style="display: block; width: 50%;" [(value)]="dataObject.repeatMonth" [(ngModel)]="dataObject.repeatMonth"
                            id="selectRecurrency" class="cellInput">
                            <mat-option *ngFor="let month of programmingRepeatService.listMonts"
                                [value]="month.value" [selected]="month.value == dataObject.repeatMonth">
                                {{month.name | translate }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="col s12 padding0 startDate" style="margin-top: 1rem;"
                    *ngIf="dataObject.customizeType != 'no-repeat'">
                    <label for="finishedDate" class="col s4 textInputDate">
                        {{ 'End' | translate }}
                    </label>
                    <input style="width: 17.5rem;" id="finishedDate" class="col s8 textInputDate" [owlDateTime]="dt8"
                        placeholder="{{ 'Select a date' | translate }}" [owlDateTimeTrigger]="dt8"
                        [(ngModel)]="dataObject.finishedDate" [min]="minDate">
                    <owl-date-time [hour12Timer]="'true'" (afterPickerClosed)="onKeySearch()" #dt8></owl-date-time>
                </div>

                <div [ngSwitch]="dataObject.customizeType" class="col s12 padding0">
                    <p style="text-align: left; margin-left: 0.7rem; color: #2F4050;">
                        <ng-containe *ngSwitchCase="'no-repeat'">
                            {{ 'This task is not repeat' | translate }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'day'">
                            <ng-container *ngIf="dataObject.repeatCount == 1">
                                {{ 'Every day' | translate }}
                            </ng-container>
                            <ng-container *ngIf="dataObject.repeatCount > 1">
                                {{ 'Each' | translate }} {{ dataObject.repeatCount }} {{ 'days' | translate }}
                            </ng-container>
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'week'">
                            <ng-container *ngIf="dataObject.repeatCount == 1">
                                {{ 'Every week on' | translate }}
                            </ng-container>
                            <ng-container *ngIf="dataObject.repeatCount > 1">
                                {{ 'Each' | translate }} {{ dataObject.repeatCount }} {{ 'weeks on' | translate }}
                            </ng-container>
                            {{ programmingRepeatService.allDaysRepeat(dataObject) }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'month'">
                            <ng-container *ngIf="dataObject.repeatCount == 1">
                                {{ 'Every month' | translate }}
                            </ng-container>
                            <ng-container *ngIf="dataObject.repeatCount > 1">
                                {{ 'Each' | translate }} {{ dataObject.repeatCount }} {{ 'months' | translate }}
                            </ng-container>
                            {{ 'the day' | translate }} {{ dataObject.repeatDay }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'year'">
                            <ng-container *ngIf="dataObject.repeatCount == 1">
                                {{ 'Every year' | translate }}
                            </ng-container>
                            <ng-container *ngIf="dataObject.repeatCount > 1">
                                {{ 'Each' | translate }} {{ dataObject.repeatCount }} {{ 'years' | translate }}
                            </ng-container>
                            {{ 'the day' | translate }} {{ dataObject.repeatDay }}
                            {{ programmingRepeatService.getMonthDetail(dataObject) }}
                        </ng-containe>
                        <ng-containe *ngIf="dataObject.customizeType != 'no-repeat'">
                            {{ 'starting on' | translate }} {{ dataObject.startingDate | date:'dd MMM yyyy':'TZ' :
                            langug}}
                            <ng-containe *ngIf="dataObject.finishedDate">
                                {{ 'ending the' | translate }} {{ dataObject.finishedDate | date:'dd MMM yyyy':'TZ'
                                :langug}}
                            </ng-containe>

                        </ng-containe>
                    </p>
                </div>
            </div>
        </div>


        <div style="border-top: transparent;" class="nopadding mygavete-buttons">
            <div class="center-align">
                <button (click)="closeModal()" container="body" placement="top"
                    class="btn btn-falt-ant btn-small button-cancel">
                    {{ 'Cancel' | translate }}
                </button>
                <button (click)="save()" container="body" placement="top"
                    class="btn btn-falt-ant btn-small button-save">
                    {{ 'Accept' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal-overlay ups" (click)="closeModal()" style="z-index: 993 !important;"></div>