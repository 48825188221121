import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MyComponent } from './component';
import { MaterialModule } from '../../../../src/app/material.module';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FormsModule } from '@angular/forms'
import { ProgrammingRepeatService } from './service';

@NgModule({
    declarations: [
        MyComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MaterialModule
    ],
    exports: [
        MyComponent
    ],
    providers: [ProgrammingRepeatService]
})
export class ProgramingRepeatModule { }
