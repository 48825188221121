<div *ngIf="!timeLeft.negative" [ngClass]="{'margin-top30':preview}">
    <div class="progress col s6 l7 m6 x7 padding0 margin-top3">
        <div class="determinate progress-{{ stateLocal }}" style="width: {{ percentage }}%"></div>
    </div>
    <div class="col s6 l5 xl5 m6 padding0 right margin-top-5">
        <span class="progress-text-{{ stateLocal }} right font12">
            {{calculate(timeLeft)}}
        </span>
    </div>
</div>

<div *ngIf="timeLeft.negative" class="margin-top-5">
    <div class="col s3 l5 m6 xl6  padding0 fix__middle">
        <i class="iconwaring material-icons left">warning</i>
        <span class="timedescexceeded font12"> {{ 'Exceeded' | translate}} </span>
    </div>
    <div class="col s2 l7 xl6 m6 padding0 ">
        <div class="timestateinexceeded " [ngClass]="{'right': !preview ,
            'left':preview}">
            <span class="progress-text-{{ stateLocal }} font12 right">
                {{calculate(timeLeft)}}
            </span>
        </div>
    </div>
</div>