import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "./services/url-builder.service";

@Injectable({
  providedIn: "root",
})
export class ManagerColorService {
  managerColor: Subject<{
    '--gh-font-family': string,
    '--gh-background': string,
    '--gh-color-dynamic-1': string,
    '--gh-color-dynamic-2': string,
    '--gh-color-rgb-dynamic': string,
    '--gh-color-letter': string,
    '--gh-filter-svg-dynamic': string,
    '--gh-filter-svg-base': string,
    '--gh-color-white': string,
    '--gh-color-light-grey-1': string,
    '--gh-color-light-grey-2': string,
    '--gh-color-light-grey-3': string,
    '--gh-color-grey': string,
    '--gh-color-dark-grey': string,
    '--gh-color-black': string,
    '--gh-color-dark-blue': string,
    '--gh-color-light-blue': string,
    '--gh-color-blue': string,
    '--gh-color-light-orange': string,
    '--gh-color-orange': string,
    '--gh-color-light-red': string,
    '--gh-color-red': string,
    '--gh-color-light-violet': string,
    '--gh-color-violet': string,
    '--gh-color-light-yellow': string,
    '--gh-color-yellow': string,
    '--gh-color-velo': string
  }> = new Subject();
  modeSelected: string = localStorage.getItem('modeSelected') || 'white';
  currentPallete: any = null;

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService
  ) { }

  setColor() {
    if (this.modeSelected == "dark") {//Is dark
      this.currentPallete = {
        '--gh-font-family': "\"Open Sans\", sans-serif",
        '--gh-background': '#212121',
        '--gh-color-dynamic-1': `#1AB394`,
        '--gh-color-dynamic-2': `rgb(26, 179, 148, 0.20)`,
        '--gh-color-rgb-dynamic': '26, 179, 148',
        '--gh-color-letter': "#F4F4F4",
        '--gh-filter-svg-dynamic': "invert(55%) sepia(58%) saturate(585%) hue-rotate(118deg) brightness(93%) contrast(86%)",
        '--gh-filter-svg-base': "invert(0%) sepia(96%) saturate(0%) hue-rotate(215deg) brightness(102%) contrast(102%)",
        '--gh-color-white': '#2c2c2c',
        '--gh-color-light-grey-1': '#2c2c2c',
        '--gh-color-light-grey-2': '#363636',
        '--gh-color-light-grey-3': '#c3c3c3',
        '--gh-color-grey': '#e3e3e3',
        '--gh-color-dark-grey': '#f4f4f4',
        '--gh-color-black': '#ffffff',
        '--gh-color-dark-blue': '#ffffff',
        '--gh-color-light-blue': 'rgba(0, 132, 196, 0.20)',
        '--gh-color-blue': '#0084c4',
        '--gh-color-light-orange': 'rgba(249, 155, 15, 0.20)',
        '--gh-color-orange': '#f99b0f',
        '--gh-color-light-red': 'rgba(255, 72, 85, 0.20)',
        '--gh-color-red': '#ff4855',
        '--gh-color-light-violet': 'rgba(156, 39, 176, 0.20)',
        '--gh-color-violet': '#9c27b0',
        '--gh-color-light-yellow': 'rgba(254, 188, 44, 0.20)',
        '--gh-color-yellow': '#febc2c',
        '--gh-color-velo': 'rgba(33, 33, 33, 0.50)'
      };
      this.managerColor.next(this.currentPallete);
    } else {//Is white
      this.currentPallete = {
        '--gh-font-family': "\"Open Sans\", sans-serif",
        '--gh-background': '#f3f3f5',
        '--gh-color-dynamic-1': `#1AB394`,
        '--gh-color-dynamic-2': `rgb(26, 179, 148, 0.20)`,
        '--gh-color-rgb-dynamic': '26, 179, 148',
        '--gh-color-letter': "#2F4050",
        '--gh-filter-svg-dynamic': "invert(59%) sepia(12%) saturate(3507%) hue-rotate(121deg) brightness(92%) contrast(80%)",
        '--gh-filter-svg-base': "invert(100%) sepia(6%) saturate(755%) hue-rotate(169deg) brightness(117%) contrast(100%)",
        '--gh-color-white': '#ffffff',
        '--gh-color-light-grey-1': '#f4f4f4',
        '--gh-color-light-grey-2': '#e3e3e3',
        '--gh-color-light-grey-3': '#c3c3c3',
        '--gh-color-grey': '#999999',
        '--gh-color-dark-grey': '#686c6d',
        '--gh-color-black': '#212121',
        '--gh-color-dark-blue': '#2f4050',
        '--gh-color-light-blue': 'rgba(0, 132, 196, 0.20)',
        '--gh-color-blue': '#0084c4',
        '--gh-color-light-orange': 'rgba(249, 155, 15, 0.20)',
        '--gh-color-orange': '#f99b0f',
        '--gh-color-light-red': 'rgba(255, 72, 85, 0.20)',
        '--gh-color-red': '#ff4855',
        '--gh-color-light-violet': 'rgba(156, 39, 176, 0.20)',
        '--gh-color-violet': '#9c27b0',
        '--gh-color-light-yellow': 'rgba(254, 188, 44, 0.20)',
        '--gh-color-yellow': '#febc2c',
        '--gh-color-velo': 'rgba(33, 33, 33, 0.50)'
      };
      this.managerColor.next(this.currentPallete);
    }
  }

  getSettingsManagerColors() {
    return this.http.get<any>(this.urlbuilder.guestApp.getSettings())
      .toPromise();
  }
}
