import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../services/url-builder.service";
import { Auth0Service } from "../../auth0.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class CheckInService {
  getFiltersDefaults: any = {
    searching: false,
    searchTxt: "",
    order: "executionDate-desc",
    state: "active",
    date: [null, null],
    location: "",
    company: "",
    confirmation: ""
  };

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  getAllRooms() {
    return this.http.get<any>(`${this.urlbuilder.subscriptionApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/rooms")}`).toPromise();
  }

  getById(input) {
    return this.http.get<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  update(input, data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http.put<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)}`, data).toPromise();
  }

  updateWhere(input, data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http.put<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/where")}`, data).toPromise();
  }

  uploadImgs(data) {
    data.append('userId', this.auth.userProfile.id);
    return this.http.post<any>(`${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${this.auth.getChosenProperty}`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  setGetUserPreferences(userId, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.propertyApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat(`/user/${userId}/preferences`)}`,
        data
      )
      .toPromise();
  }

  exportExcelAll(filter: any): string | URL {
    const StrFecha = new Date().toString();
    const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
    const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
    const Horas = (Math.round(HorasLocalesRespectoAGmt));
    const Minutos = (HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt));

    return this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/export-excel/")
      .concat(filter.state)
      .concat('?language=')
      .concat(localStorage.getItem('lang')
        ? JSON.parse(localStorage.getItem('lang')).lang
        : 'en')
      .concat(
        `${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.date && filter.date[0] && filter.date[1] && filter.date[0] != 'null' && filter.date[1] != 'null'
          ? "&fromDate=" + new Date(filter.date[0]).toISOString() +
          "&toDate=" + new Date(filter.date[1]).toISOString()
          : ""
        }${filter.location
          ? "&location=" + encodeURIComponent(filter.location)
          : ""
        }${filter.company
          ? "&company=" + encodeURIComponent(filter.company)
          : ""
        }${filter.confirmation
          ? "&confirmation=" + encodeURIComponent(filter.confirmation)
          : ""
        }${filter.statuses
          ? "&statuses=" + encodeURIComponent(filter.statuses.toString())
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      ).concat(`&gmt=${timeZone}&action=${Horas < 0 ? 'add' : 'subtract'}&hours=${Horas}&minutes=${Math.floor(Math.abs(Minutos * 100))}`);
  }

  exportDetailPDF(request): string | URL {
    const StrFecha = new Date().toString();
    const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
    const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
    const Horas = (Math.round(HorasLocalesRespectoAGmt));
    const Minutos = (HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt));

    return this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/export-detail-pdf/")
      .concat(request.id)
      .concat(`?gmt=${timeZone}&action=${Horas < 0 ? 'add' : 'subtract'}&hours=${Horas}&minutes=${Math.floor(Math.abs(Minutos * 100))}`);
  }

  exportTermsAndConditionsPDF(input): string | URL {
    const StrFecha = new Date().toString();
    const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
    const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
    const Horas = (Math.round(HorasLocalesRespectoAGmt));
    const Minutos = (HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt));

    return this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/export-terms-and-conditions-pdf/")
      .concat(input.requestId)
      .concat("/")
      .concat(input.termId)
      .concat(`?gmt=${timeZone}&action=${Horas < 0 ? 'add' : 'subtract'}&hours=${Horas}&minutes=${Math.floor(Math.abs(Minutos * 100))}`);
  }

  listValidates(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/validates").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listActives(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/actives").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listAll(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/all").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.date && filter.date[0] && filter.date[1] && filter.date[0] != 'null' && filter.date[1] != 'null'
          ? "&fromDate=" + new Date(filter.date[0]).toISOString() +
          "&toDate=" + new Date(filter.date[1]).toISOString()
          : ""
        }${filter.location
          ? "&location=" + encodeURIComponent(filter.location)
          : ""
        }${filter.company
          ? "&company=" + encodeURIComponent(filter.company)
          : ""
        }${filter.confirmation
          ? "&confirmation=" + encodeURIComponent(filter.confirmation)
          : ""
        }${filter.statuses
          ? "&statuses=" + encodeURIComponent(filter.statuses.toString())
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listAllStatuses() {
    return [
      { name: 'Validate', value: 'validate' },
      { name: 'Accepted', value: 'accepted' },
      { name: 'Declined', value: 'declined' },
      { name: 'Confirmed', value: 'confirmed' },

    ];
  }

  listConfirmed(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/confirmed").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  commentCreate(input, data) {
    return this.http.post<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/comment")}`, {
      body: data.body,
      userId: this.auth && this.auth.userProfile && this.auth.userProfile.id ? this.auth.userProfile.id : undefined,
      guestName: undefined,
      type: data.type,
      hasImage: data.hasImage
    }).toPromise();
  }

  commentMarkToRead(input, type: String = 'internal') {
    return this.http.put<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/comment")}`, {
      userId: this.auth && this.auth.userProfile && this.auth.userProfile.id ? this.auth.userProfile.id : undefined,
      type: type
    }).toPromise();
  }

  deleteComment(data) {
    return this.http.delete<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(data.requestId)
      .concat("/comment/")
      .concat(data.id)
      }`, {}).toPromise();
  }

  getReservations(input) {
    return this.http
      .get<any>(`${this.urlbuilder.appApi.concat(`/${input.propertyId}/reservations`)}`)
      .toPromise()
      .then(guests => guests.map(guest => this.mapGuests(guest)))
  }

  private mapGuests(guest) {
    return Object.assign(
      {},
      {
        id: guest.id,
        name: guest["guest.firstName"] + " " + guest["guest.lastName"],
        email: guest["guest.email"],
        confirmation: guest["info.confirmation"],
        phoneNumber: guest["guest.phoneNumber"],
        internal: false,
      }
    );
  }

  sendReport(data: { filter: any; users: any[], onlyCheckIn: boolean }) {
    return this.http.post<any>(
      `${this.urlbuilder.checkInApi
        .concat("/")
        .concat(this.auth.getChosenProperty.toString())
        .concat("/manual")}`,
      Object.assign(data, {
        userId:
          this.auth && this.auth.userProfile && this.auth.userProfile.id
            ? this.auth.userProfile.id
            : undefined,
      })
    )
      .toPromise();
  }
}
