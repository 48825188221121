<div class="modal up">
    <div>
        <div class="m-header">
            <a (click)="close.emit()" class="right close-btn">
                <i class="material-icons">close</i>
            </a>
        </div>
        <div class="content-area" role="listbox">
            <video style="width: 100%;" #video id="video" autoplay></video>
            <canvas style="display: none;" width="1280" height="720" #canvas id="canvas"></canvas>
            <!-- <div *ngIf="webcamImage">
                <h2>Imagen capturada:</h2>
                <img [src]="webcamImage" alt="Imagen de la cámara">
            </div> -->
            <li *ngFor="let c of captures">
                <img src="{{ c }}" style="width: 100%;" />
            </li>
            <button (click)="capture()" id="capture-button">capture</button>
        </div>
    </div>
</div>