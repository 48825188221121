<p class="col s12 m12 xl12"></p>
<div class="modal-padding-leftright">
    <div class="left padding0" *ngIf="!searchOpen" style="margin-bottom: 21px; margin-top: -11px;">
        <ul class="list-message margin-top0 margin0">
            <li class="center pointer btnTabMessages" (click)="filterComments('guest');"
                [ngClass]="{'active_btn': typeComments=='guest'}">
                <i class="material-icons icon-message reative-top-2">person</i>
                {{'Guest' | translate}}
                <span class="right notification-red" *ngIf="countComments('guest') > 0"></span>
            </li>
            <li class="center pointer btnTabMessages" (click)="filterComments('internal');"
                [ngClass]="{'active_btn': typeComments=='internal'}">
                <i class="material-icons icon-message reative-top-2">home</i>
                {{'Internal' | translate}}
                <span class="right notification-red" *ngIf="countComments('internal') > 0"></span>
            </li>
        </ul>
    </div>
    <div class="right" *ngIf="!searchOpen">
        <i class="material-icons color-base-two icons-search pointer" (click)="searchOpen = !searchOpen;">search</i>
    </div>
    <div class="clearfix" *ngIf="!searchOpen"></div>
    <div class="input-field col s12   padding0 border-input sear__top" *ngIf="searchOpen">
        <i class="material-icons prefix color-base-two icons-search pointer"
            (click)="searchClose(); searchInComments('');">keyboard_arrow_left</i>
        <input id="searchComments" placeholder="{{'Search' | translate}}" type="text"
            class="input-search input-search-message" [(ngModel)]="searchTxt"
            (keyup)="searchInComments($event.target.value)" />
        <i class="material-icons subprefix color-base-two icons-search i__delet pointer"
            (click)="searchClear(); searchInComments('');">clear</i>
    </div>
</div>


<ul class="modal-padding-leftright content-messages scrolling-gavete" #scrollMe [scrollTop]="scrollMe.scrollHeight">
  <li *ngFor="let comment of commentsFilter | filter:searchTxt ; let i = index" class="padding0 {{ auth.userProfile.id != comment.authorId ? 'my-message-left' : '' }}">
    <div class="col s9">
      <div class="message-header {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }}">
        <strong>
          {{ comment.authorName }}
        </strong>
        <span class=" color-base-two font14">
          {{ comment.creationDate | date: "MMM" | translate }}
        </span>
        <span class=" color-base-two font14">
          {{ comment.creationDate | date: "dd yyyy h:mm a" }}
        </span>
      </div>
      <div
        class="message-body {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }} font-content font-message"
        [innerHtml]="comment.body"></div>
      <div class="clearfix"></div>
    </div>
    <div class="col s2">
      <img class="imagen-avatare" *ngIf="comment.authorPicture && auth.userProfile.id == comment.authorId"
        src="{{ comment.authorPicture }}" width="100%" />
    </div>
    <div class="col s1">
      <div *ngIf="auth.userProfile.id == comment.authorId" style="position: relative;">
        <div class="pointer" (click)="comment.showMoreOptions = !comment.showMoreOptions" data-target='dropdown1'>
          <i class="material-icons button-three-point__icon top03" style="color: #686C6D;">more_vert</i>
          <div class="clearfix"></div>
        </div>
        <div class="hidden-backgound" *ngIf="comment.showMoreOptions" (click)="comment.showMoreOptions = false;">
        </div>
        <ul id='dropdown1' class='dropdown-content drop-cont padding '
          [ngClass]="{'inDropDown':comment.showMoreOptions}">
          <li class="cont-li">
            <a (click)="comment.showMoreOptions = false;eventDeleteComment.emit(comment);" class="color-txt">
              <i class="material-icons">delete</i>
              {{ 'Delete' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>


    <!--div class="message-guest" [ngClass]="{'myauthor': auth.userProfile.id == comment.authorId}">
      <div class="col s11 padding0 myauthor-title" >
        <div style="display: flex;" class="padding0 col s12 m12 l12 xl12 myauthor-body">
          <div class="font-name font14 font-message blod">
            {{ comment.authorName }}
          </div>
          <div class=" color-base-two font14">{{ comment.creationDate | date: "MMM" | translate }}
          </div>
          <div style="margin-right: 10px;" class=" color-base-two font14"> {{ comment.creationDate | date: "dd yyyy h:mm a" }}</div>
        </div>

        <div class="col s12 comment " style="overflow-y: auto; word-break: break-all; padding-left: 6px;">
          <div [innerHtml]="comment.body" class="font-content font-message"></div>
        </div>
      </div>

      <div style="display: flex;" class="col s1 padding0" >
        <img class="imagen-avatare" *ngIf="comment.authorPicture && auth.userProfile.id == comment.authorId"
          src="{{ comment.authorPicture }}" width="100%" />
          <div *ngIf="auth.userProfile.id == comment.authorId">
            <div class="pointer"  (click)="comment.showMoreOptions = !comment.showMoreOptions"
              data-target='dropdown1'>
              <i class="material-icons button-three-point__icon top03" style="color: #686C6D;">more_vert</i>
              <div class="clearfix"></div>
            </div>
             <div class="hidden-backgound" *ngIf="comment.showMoreOptions" (click)="comment.showMoreOptions = false;">
            </div> >
            <ul id='dropdown1' class='dropdown-content drop-cont padding '
              [ngClass]="{'inDropDown1':comment.showMoreOptions}">
              <li class="cont-li">
                <a (click)="comment.showMoreOptions = false;eventDeleteComment.emit(comment);" class="color-txt">
                  <i class="material-icons">delete</i>
                  {{ 'Delete' | translate }}
                </a>
              </li>
            </ul>
          </div>
      </div>

      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div-->
      <div class="clearfix"></div>
  </li>
</ul>


<!-- <ul class="col s12 padding0 modal-padding-leftright content-messages scrolling-gavete" #scrollMe
  [scrollTop]="scrollMe.scrollHeight">
  <li *ngFor="let comment of commentsFilter | filter:searchTxt ; let i = index" class="padding0 ">
    <div class="message-guest" [ngClass]="{'myauthor': auth.userProfile.id == comment.authorId}">
      <div class="col s11 padding0 myauthor-title" >
        <div style="display: flex;" class="padding0 col s12 m12 l12 xl12 myauthor-body">
          <div class="font-name font14 font-message blod">
            {{ comment.authorName }}
          </div>
          <div class=" color-base-two font14">{{ comment.creationDate | date: "MMM" | translate }}
          </div>
          <div style="margin-right: 10px;" class=" color-base-two font14"> {{ comment.creationDate | date: "dd yyyy h:mm a" }}</div>
        </div>

        <div class="col s12 comment " style="overflow-y: auto; word-break: break-all; padding-left: 6px;">
          <div [innerHtml]="comment.body" class="font-content font-message"></div>
        </div>
      </div>

      <div style="display: flex;" class="col s1 padding0" >
        <img class="imagen-avatare" *ngIf="comment.authorPicture && auth.userProfile.id == comment.authorId"
          src="{{ comment.authorPicture }}" width="100%" />
          <div *ngIf="auth.userProfile.id == comment.authorId">
            <div class="pointer"  (click)="comment.showMoreOptions = !comment.showMoreOptions"
              data-target='dropdown1'>
              <i class="material-icons button-three-point__icon top03" style="color: #686C6D;">more_vert</i>
              <div class="clearfix"></div>
            </div>
            <div class="hidden-backgound" *ngIf="comment.showMoreOptions" (click)="comment.showMoreOptions = false;">
            </div>
            <ul id='dropdown1' class='dropdown-content drop-cont padding '
              [ngClass]="{'inDropDown':comment.showMoreOptions}">
              <li class="cont-li">
                <a (click)="comment.showMoreOptions = false;eventDeleteComment.emit(comment);" class="color-txt">
                  <i class="material-icons">delete</i>
                  {{ 'Delete' | translate }}
                </a>
              </li>
            </ul>
          </div>
      </div>

      <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>

  </li>
</ul> -->
<div class="col s12 xl12 send-message">
    <div class="col s2  padding0 cont__send__img">
        <input hidden type="file" #uploader (change)="uploadFile($event)" accept="image/*" />
        <!--multiple-->
        <i class="material-icons white-text pointer buttonCamare" (click)="uploader.click()"
            [ngClass]="{'disabled_button': disabledButtonUpload}">
            photo_camera
        </i>
    </div>
    <div class="input-field col s8 padding0 margin0 input-sms txt__inp__cont">
        <textarea id="message" class="validate  input-search-message-sms newMessage" #textAreaChat
            placeholder="{{'Enter new message'| translate}}" maxlength="2000" [(ngModel)]="newComent"
            (keypress)="onKeyPress($event)" (keyup)="onKeyUp($event)" autocomplete="off" autofocus
            appTextareaAutoresize></textarea>
    </div>

    <div class="col s2 send__cont">
        <button class="pointer" (click)="addComments()" [disabled]="disabledButton"
            class="float-send-btn padding0 right" [ngClass]="{'inactive-btn': disabledButton}">
            <i class="material-icons white-text">
                send
            </i>
        </button>

    </div>

</div>