import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject
} from "@angular/core";
import { Auth0Service } from "../../auth0.service";
import { TopService } from "./service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

import { environment } from "../../../environments/environment";
import { ManagerColorService } from '../../manager-colors.service';
import { NotificationsService } from "./notifications/service";

import { SideService } from "../side/service";
import { EventsService } from "../../events.service";

@Component({
  selector: "app-guesthub-navigation-top",
  styleUrls: ["styles.scss"],
  templateUrl: "template.html",
})
export class TopNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myDivSimulator') myDivSimulator: ElementRef;
  @Output() outputShowSidebar: EventEmitter<Object> = new EventEmitter<
    Object
  >();
  showMenu = true;
  mailText = "";
  popupabVisible = false;
  myLanguage: any;
  modalOpenMyProfile = false;
  connectOffline = false;
  isAProd = window.location.hostname.includes('guesthub.io');
  languages: Array<any> = [
    {
      name: 'English',
      code: 'en'
    }, {
      name: 'Spanish',
      code: 'es'
    }
  ];

  private notificationSound = new Audio('assets/sound/zapsplat_multimedia_ringtone_smartphone_simple_63270.mp3');
  public soundEnabled = true;
  private isPlaying = false;
  notification: any;
  intervalSimulator: any;
  url: any = window.location.host;
  showNotifications: boolean;
  showMessages: boolean;
  notificationsCount = 0;
  messagesCount = 0;
  notificationsUnRead: any[] = [];
  notificationsUnReadDesplay: any[] = [];
  viewOpenPreview: any;
  viewPreview: any;
  itemSelected: { id: any; type: string; tab?: string };

  constructor(
    public auth: Auth0Service,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private topService: TopService,
    protected managerColorService: ManagerColorService,
    private notificationsService: NotificationsService,
    @Inject("EventsService") private eventsService: EventsService,
    private sideService: SideService
  ) {
    window.addEventListener('offline', function (event) {
      this.connectOffline = true;
    }.bind(this));
    window.addEventListener('online', function (event) {
      this.connectOffline = false;
    }.bind(this));
  }

  enabledDisabledDarkMode(checked = false) {
    this.managerColorService.modeSelected = checked ? "dark" : 'white';
    localStorage.setItem('modeSelected', this.managerColorService.modeSelected);
    this.managerColorService.setColor();
  }

  goToSubscription() {
    window.location.href = environment.PROTOCOL.concat(
      environment.FRONTENDSUBSCRIPTION.HOST
    ).concat(environment.FRONTENDSUBSCRIPTION.DOMAIN);
  }

  mailMe() {
    this.mailText = `mailto:${this.translate.currentLang === "es" ? "soporte" : "support"}@guesthub.io?subject=&body=`
    window.location.href = this.mailText;
  }

  ngAfterViewInit() {
    this.myDivSimulator.nativeElement.click();
    // this.intervalSimulator = setInterval(() => {
    //   this.myDivSimulator.nativeElement.click();
    // }, 1000);
    this.initComponent();
  }

  ngOnInit() {
    this.myLanguage = {
      lang: localStorage.getItem("lang") ? JSON.parse(localStorage.getItem("lang")).lang : this.translate.currentLang,
      name: (
        localStorage.getItem("lang") ? JSON.parse(localStorage.getItem("lang")).lang : this.translate.currentLang
      ) == 'es' ? 'Spanish' : 'English'
    };

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.type && queryParams.requestId) {
        this.itemSelected = {
          id: queryParams.requestId,
          type: queryParams.type,
          tab: 'detail'
        };
        this.viewPreview = true;
        this.viewOpenPreview = queryParams.type;
      }
    });
  }

  initComponent() {
    this.topService.subscribePicture((res) => {
      this.auth.userProfile.picture = res;
    });

    const functionNotificationSidebar = () =>
      this.sideService.sidebarMenuNotifications({})
        .then(sidebarMenu => {
          this.notification = sidebarMenu;
          this.__autoPlaySound();
        });

    this.topService.activeSound$.subscribe((res) => {
      this.soundEnabled = res;
      functionNotificationSidebar();
    });

    this.topService.refreshUserSoundPreferences$.subscribe((res) => {
      functionNotificationSidebar();
    });

    this.notificationSound.load();
    this.notificationSound.loop = true; // Hacer que el sonido se repita

    const functionCountNotifications = (init: boolean = false) => {
      Promise.all([
        this.notificationsService.countNotifications({ type: 'default' }),
        this.notificationsService.countNotifications({ type: 'message' })
      ]).then(([notificationsCount, messagesCount]) => {
        this.notificationsCount = notificationsCount;
        this.messagesCount = messagesCount
        if (init && (this.notificationsCount > 0 || this.messagesCount > 0)) {
          this.listNotificationsUnRead();
        }
      });
    };

    functionCountNotifications(true);
    functionNotificationSidebar();

    this.eventsService.channels.notifications.subscribe(notification => {
      functionNotificationSidebar();
      functionCountNotifications();
      if (notification.action == 'created') {
        if (!this.notificationsUnRead.find(not => not.id == notification.id)) {
          this.notificationsUnRead.unshift(notification);
          if (!this.notificationsUnReadDesplay.find(not => not.id == notification.id)) {
            this.notificationsUnReadDesplay.unshift(notification);
          }
        }
      } else if (notification.action == 'deleted-all') {
        this.notificationsUnReadDesplay = [];
        this.notificationsUnRead = [];
      } else {
        const index = this.notificationsUnReadDesplay.findIndex(not => not.id === notification.id);
        if (index > -1) {

          if (notification.read) {
            // Si el notification.read es true, elimina el registro
            this.notificationsUnReadDesplay.splice(index, 1);
          } else {
            // Si no, actualiza el registro
            this.notificationsUnReadDesplay[index] = notification;
          }
        } else {

        }
      }
    });
  }

  private listNotificationsUnRead() {
    this.notificationsService.listNotificationsUnread()
      .then(notificationsUnRead => {
        this.notificationsUnRead = notificationsUnRead;
        this.notificationsUnReadDesplay = notificationsUnRead;
      });
  }

  private __autoPlaySound() {
    if (this.soundEnabled) {
      if (this.notification.some(n =>
        n.activeSound && n.count > 0
      )) {
        if (!this.isPlaying) {
          this.notificationSound.play()
            .then(() => {
              this.isPlaying = true;
            }).catch(error => {
              console.error("Error al reproducir el sonido:", error);
              this.isPlaying = false;
              setTimeout(() => {
                this.__autoPlaySound();
              }, 1000);
              // Acción alternativa si el sonido no puede reproducirse
            });
        }
      } else {
        this.stopNotificationAuditive();
      }
    } else {
      this.stopNotificationAuditive();
    }
  }

  private stopNotificationAuditive() {
    this.notificationSound.pause();
    this.notificationSound.currentTime = 0;
    this.isPlaying = false;
  }

  toggleSound() {
    this.soundEnabled = !this.soundEnabled;
    if (!this.soundEnabled && this.isPlaying) {
      this.notificationSound.pause();
      this.notificationSound.currentTime = 0;
      this.isPlaying = false;
    }
  }

  ngOnDestroy() {
    this.notificationSound.pause();
    clearInterval(this.intervalSimulator);
    this.intervalSimulator = undefined;
  }

  showSidebar() {
    if (this.showMenu == true) {
      this.outputShowSidebar.emit(false);
      this.showMenu = false;
    } else {
      this.outputShowSidebar.emit(true);
      this.showMenu = true;
    }
  }

  changeLanguage(lang): void {
    this.myLanguage = {
      lang: lang,
      name: lang == 'es' ? 'Spanish' : 'English'
    };
    localStorage.setItem("lang", JSON.stringify(this.myLanguage));
    this.translate.use(lang);
  }

  sendCommunicationsReport() {
    this.topService.sendCommunicationsReport()
  }

  onActionEvent(event) {
    if (event.action == 'destroy') {
      this.notificationsUnReadDesplay = this.notificationsUnReadDesplay.filter(n =>
        n.id != event.id
      );
    } else if (event.action == 'open-modal') {
      //this.readNotification(event);
      this.itemSelected = {
        id: event.requestId,
        type: event.module,
        tab: event.type === 'message' ? 'comments' : 'detail'
      };
      this.viewPreview = event.modeOpen;
      this.viewOpenPreview = event.module;
      this.showNotifications = false;
      this.showMessages = false;
    } else {
      console.error("Error not spesified", event)
      // this.readNotification(event)
      //   .then(() => {
      //     console.info(event);
      //   });
    }
  }
}
