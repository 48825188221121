<div class="col s12 padding0 content-detail scrolling-gavete" #scrollMe>
  <p class="col s12 m12 xl12"></p>
  <div class="col s12 l12 xl12 white padding-base padding0">
    <div class="col s12 m6 xl6 l6 padding0 modal-padding-leftright">
      <div class="col s12 l12 m12 xl12 padding0">
        <span class="color-base-two font-12 semiblod">{{'Status' | translate |
          uppercase}} </span>
      </div>

      <div class="col s5 l10 xl10 m10 padding0" *ngIf="request.otherStatuses.length > 0">
        <a class="btn btn-block btn-small-s btn-flat-ant pointer col s12
                    btn-f-b-flat {{ request?.statusColorHex }} z-depth-2" [matMenuTriggerFor]="menu"
          style="margin-top:8px;margin-bottom: 8px;">

          <span id="pending" class="semiblod ">
            {{ request.statusName | translate }}
          </span>

          <i class="material-icons">edit</i>
        </a>
      </div>

      <a class="btn btn-block btn-small-s btn-flat-ant {{ request?.statusColorHex }} pointer col s12 l10 xl10 m10 btn-f-b-flat noPointer padding0 margin-bottom10"
        *ngIf="request.otherStatuses.length === 0">
        <span> {{ request.statusName | translate}}</span>
      </a>

      <mat-menu #menu="matMenu">
        <a *ngFor="let act of request.otherStatuses"
          class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ act?.colorHex }} menuOption"
          (click)="update(act)">
          <span id="{{act.colorHex}}" class="semiblodBtn ">
            {{ act.name | translate}}
          </span>
        </a>
      </mat-menu>
      <div class="col s12 m10 l10 xl10 padding0"
        *ngIf="request?.statusCodename == 'new' || request?.statusCodename == 'accepted' || request?.statusCodename == 'cancelled' && request?.endDate">
        <app-outlets-timer class="position5" [startDate]="request?.startDate" [endDate]="request?.endDate"
          [percentageWarning]="'25'" [percentageDanger]="'10'" [countSumate]="countSumate"
          (state)="updateStateTime($event)">
        </app-outlets-timer>

      </div>
      <div class="col s12 l12 m12">
        <br />
      </div>
    </div>


    <div class="col s12 padding0">
      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-when">
          <i class="material-icons">query_builder</i>
        </div>

        <div class="margin0">
          <b class="semiblod-color-base font-12 context-option">
            {{'When?' | translate | uppercase}}</b><br />
          <div class="divintercf">

            <span class="relative-text  font-16 regular context-option">
              {{ request.executionDate | date:'d MMM yyyy':'TZ' : langug  }}
              <span class="semiblod-color-base">
                {{ request.executionDate | date:'h:mm':'TZ' : langug  }}
              </span>
            </span>
          </div>

        </div>

      </div>
      <div class="col s12"><br></div>

      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-where">
          <i class="material-icons">location_on</i>
        </div>
        <div class="margin0">

          <b class="semiblod-color-base font-12 context-option">
            {{'Where?' | translate | uppercase}}</b><br />
          <div class="divintercf">
            <div class="" *ngIf="!editRoom;else editRoomElse;">
              <span *ngIf="request.publicArea" class="relative-text color-base font-16 regular context-option">
                {{ request.publicArea }}
                <span class="etiqueta where-btn">
                  {{'Public area' | translate}}
                </span>
              </span>

              <span *ngIf="!request.publicArea" class="relative-text color-base font-16 regular context-option">
                {{ request.roomNumber }}
                <span class="etiqueta where-btn">
                  {{'Room' | translate}}
                </span>

              </span>
              <span class="edit pointer normal" (click)="editRoom=true;">

                <i class="material-icons">edit</i>
                {{'Edit' | translate}}
              </span>
            </div>
            <ng-template #editRoomElse>
              <div class="col s8">
                <input maxlength="50"
                  (keyup.enter)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  (focusout)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  [value]="request[request.publicArea ? 'publicArea' : 'roomNumber']" id="first_name" type="text"
                  class="validate">
                <span class="edit-text">{{"Press 'enter' to save" | translate}}</span>
              </div>

            </ng-template>
          </div>

        </div>

      </div>

      <div class="col s12"><br></div>
      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left icon-container  ">
          <i class="material-icons feedback__neutral "
            *ngIf="!request.feedbacks[0]?.statusCodename">{{!request?.isInternal ? "sentiment_satisfied" : "home"}}</i>
          <i class="material-icons feedback__green"
            *ngIf="request.feedbacks[0]?.statusCodename == 'positive'">{{!request?.isInternal ? "mood" : "home"}}</i>
          <i class="material-icons feedback__red"
            *ngIf="request.feedbacks[0]?.statusCodename == 'negative'">{{!request?.isInternal ? "mood_bad" :
            "home"}}</i>
          <!-- <i class="material-icons">{{!request?.isInternal ? "person" : "home"}}</i> -->
          <i class="material-icons badge-icon" *ngIf="request.reservationVip">star</i>
        </div>
        <!-- <div class="left circle-who">
          <i class="material-icons">person</i>
        </div> -->
        <div class="margin0">
          <b class="semiblod-color-base font-12 context-option">
            {{'Who?' | translate | uppercase}}</b><br />
          <div class="divintercf">

            <span class="relative-text semiblod-color-base font-16 regular context-option">
              {{request.guestName | slice:0:20}}
              <span *ngIf="request.roomNumber" class="semiblod-color-base font-16">
                , {{'Room' | translate}} {{request.roomNumber}}

              </span>

              <span class="etiqueta normal">
                {{ request.isInternal ? 'Internal' : 'Guest' | translate }}
              </span>

            </span>

          </div>
        </div>
        <div class="textFeedbackNegative" *ngIf="request.feedbacks[0]?.statusCodename == 'negative'">
          <span class="textFeedback">
            {{(request.feedbacks[0].commentSurvey.length > 0 ? request.feedbacks[0].commentSurvey : "I didn't like") |
            translate }}
          </span>
        </div>
        <div class="textFeedbackPositive" *ngIf="request.feedbacks[0]?.statusCodename == 'positive'">
          <span class="textFeedback">{{'Like it' | translate}} </span>
        </div>
        <div class="col s12 l12 xl12 m12 border__top__4line padding0" style="margin-top: 31px; margin-bottom: -30px;">
          <br>
        </div>
      </div>
    </div>

  </div>
  <div class="col s12 l12 xl12 m12"><br></div>
  <div class="col s12 l12 m12 padding0">
    <div class="col s12 m12 l12">
      <br />
    </div>
    <div class="col s12 padding0 modal-padding-leftright" style="margin-bottom: 4px;">
      <span class="title-menu padding0 blod">
        {{ request.requestText | titlecase }}
      </span>

    </div>
    <div class="col s12 l12 m12">
      <br>
    </div>
    <div *ngFor="let menu of request.menus" class="modal-padding-leftright">
      <h3 class="txt__menu__name"> {{ menu.name }} </h3>
      <div *ngFor="let item of menu.items" style="margin-bottom: 32px;">

        <strong style="display: block; padding-top: 8px;">
          <img *ngIf="item.photo" [src]="item.photo" alt="" class="img-service">
          <span class="font-16 color-base-regular back__amount__orden"
            [ngClass]="{'img-service-active':item.photo, 'txt__no__img': !item.photo}"> {{
            item.cant }}
          </span>
          <span class="semiblod-color-base font-16 txt__cut__order"
            [ngClass]="{'txt__active':item.photo, 'txt__no__active': !item.photo}"> {{ item.name
            }}
          </span>
          <span style="float: right; margin-top: 19px;" class="semiblod-color-base font-16"
            [ngClass]="{'txt__money__noimg': !item.photo}">
            {{ menu.typeMoney }} {{ item.price | number : '1.2-2' }}
          </span>
          <span style="clear: both"></span>

        </strong>

        <div *ngFor="let modifier of item.modifiers" class="modifier">
          <span class="font-12 color-base-two padding-bottom12 semiblod">
            {{ modifier.name | uppercase}}
          </span>
          <ul style="margin-top: -4px;">
            <li *ngFor="let option of modifier.options"
              style="list-style-type: disc; margin-left: 20px;padding-top:10px;color: #686C6D;">
              <span class=" font-16 color-base-two regular">
                {{ option.value }}
              </span>
              <span style="float: right" *ngIf="option.price" class="font-16 txt__money__modifier">
                {{ menu.typeMoney }} {{ option.price | number : '1.2-2'}}
              </span>
              <span style="clear: both"></span>
            </li>
          </ul>
        </div>
        <div class="" *ngIf="item.note && item.note != ''">
          <span class="col s12 m12 xl12 font-12 color-base-two padding-left-ringth0 padding-top5 semiblod">
            {{'Notes' |translate | uppercase }}
          </span>
          <span
            class="col s12 l12 m12 padding-left-ringth0  padding-top5 padding-bottom12 format-text txt__note__order font-16 "
            [ngClass]="{'cont__no__notes': item.note}">
            {{ item.note }}

          </span>
        </div>

      </div>

      <div class="col s12 border__top__1line border-line " *ngIf="menu.note"><br /></div>

      <div class="" *ngIf="menu.note && menu.note != ''">
        <span class="col s12 m12 xl12 font-12 color-base-two padding-left-ringth0 semiblod">
          {{'Order notes' |translate | uppercase }}
        </span>
        <span
          class="col s12 l12 m12 padding-left-ringth0  padding-top5 format-text txt__note__order font-16 padd__txt__order ">
          {{ menu.note }}

        </span>
      </div>

    </div>
  </div>

  <div class="col s12 l12 xl12 white padding0 modal-padding-leftright">

    <div class="col s12 border__top__subtotalline border-line "><br /></div>

    <div class="col s12 m12"></div>
    <div class="float-left-total txt__total__money">{{ 'SubTotal' | translate | uppercase}}</div>
    <div class="float-right-total num__total__money">
      {{ request.typeMoney }}
      {{ request.priceTotal | number : '1.2-2' }}
    </div>

    <div class="col s12 border__top__4line border-line "><br /></div>

  </div>
  <div class="col s12 padding0 modal-padding-leftright" style="margin-top:-15px ;">
    <br />
    <div class="col s12 m6 xl7 l7 padding0">
      <span class="color-base-two font-12 semiblod">{{'Request type' | translate | uppercase }} </span><br />
      <i class="material-icons icon-hotel">local_hotel</i>
      <span class="two color-base font-16 regular poss__txt__service">
        {{ request.roomNumber ? 'Internal guest' : 'External guest' | translate }}
      </span>
    </div>


    <div class="col s12 m6 xl5 l5 padding0">
      <span class="color-base-two font-12 semiblod">{{ 'Pending status time' | translate | uppercase }}
      </span><br />
      <span class="two color-base font-16 color-base font-16 regular">
        00:{{ request.newStatusTimeMinutes < 10 ? '0' + request.newStatusTimeMinutes : request.newStatusTimeMinutes }}
          min </span>
    </div>
    <div class="col s12 l12 m12 xl12">
      <br />
    </div>

    <div class="col s12 m6 xl7 l7 padding0">
      <span class="color-base-two font-12 semiblod">{{ 'Due Time' | translate | uppercase }} </span><br />
      <span class="two color-base font-16 regular">
        {{ modificationDateTranslate(request.dueDate) }}
      </span>
    </div>


    <div class="col s12 m6 xl5 l5 padding0">
      <span class="color-base-two font-12 semiblod">{{ 'Creation Time' | translate | uppercase }} </span><br />
      <span class="two color-base font-16 regular">
        {{ modificationDateTranslate(request.executionDate) }}
      </span>
    </div>

    <div class="col s12 l12 m12 xl12">
      <br />
    </div>
  </div>
</div>