<div class="col s12 padding0 content-detail scrolling-gavete" #scrollMe>
  <p class="col s12 m12 xl12"></p>
  <div class="col s12 l12 xl12 white padding-base padding0">
    <div style="margin-left: 1.8rem; margin-bottom: 1rem;" class="col s12 l12 m12 xl12 1 ">
      <span class="color-base font-14 subtext-preview " style="margin-bottom: 1rem;">
        <strong> {{ request?.department }} </strong> <ng-container *ngIf="request.categoryId"> / {{ request?.category
          }}</ng-container> / {{ request?.subcategory }}
      </span>
    </div>
    <div class="col s12 m6 xl6 l6 padding0 modal-padding-leftright">
      <div class="col s12 l12 m12 xl12 padding0">

        <span class="color-base-two font-12 semiblod">
          {{'Status' | translate | uppercase}}
        </span>
      </div>

      <div class="col s5 l10 xl10 m10 padding0" *ngIf="request.otherStatuses.length > 0">
        <a class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ request?.statusColorHex }} z-depth-2"
          [matMenuTriggerFor]="menu" style="margin-top:8px;margin-bottom: 8px;">
          <span id="pending" class="semiblod ">
            {{ request.statusName | translate }}
          </span>
          <i class="material-icons">edit</i>
        </a>
      </div>

      <a class="btn btn-block btn-small-s btn-flat-ant {{ request?.statusColorHex }} pointer col s12 l10 xl10 m10 btn-f-b-flat noPointer padding0 margin-bottom10"
        *ngIf="request.otherStatuses.length === 0">
        <span> {{ request.statusName | translate}}</span>
      </a>

      <mat-menu #menu="matMenu">
        <a *ngFor="let act of request.otherStatuses"
          class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ act?.colorHex }} menuOption"
          (click)="updateStatus(act)">
          <span id="{{act.colorHex}}" class="semiblodBtn ">
            {{ act.name | translate}}
          </span>
        </a>
      </mat-menu>
      <div class="col s12 m10 l10 xl10 padding0"
        *ngIf="request.state == 'active' && request.statusCodename != 'onhold' && request.endDate">
        <app-tasks-manager-timer class="position5" [startDate]="request?.startDate" [endDate]="request?.endDate"
          [percentageWarning]="'25'" [percentageDanger]="'10'" [countSumate]="countSumate"
          (state)="updateStateTime($event)">
        </app-tasks-manager-timer>

      </div>
      <div class="col s12 l12 m12">
        <br />
      </div>
    </div>


    <div class="col s12 padding0">
      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-who">
          <i class="material-icons">person</i>
        </div>
        <div class="margin0">
          <b class="semiblod-color-base font-12 context-option">
            {{'assigned' | translate | uppercase}}</b><br />
          <div class="divintercf">

            <span class="relative-text semiblod-color-base font-16 regular context-option">
              {{request.assigneeName | slice:0:20}}
              <span *ngIf="request.assignedType == 'department'" class="etiqueta where-btn">
                {{"Department" | translate}}
              </span>
              <span *ngIf="request.assignedType == 'user'" class="etiqueta where-btn">
                {{"User" | translate}}
              </span>
            </span>

          </div>
        </div>
        <!-- <div class="col s12 l12 xl12 m12 border__top__4line padding0" style="margin-top: 31px; margin-bottom: -30px;">
          <br>
        </div> -->
      </div>


      <div class="col s12"><br></div>

      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-where">
          <i class="material-icons">location_on</i>
        </div>
        <div class="margin0">

          <b class="semiblod-color-base font-12 context-option">
            {{'Where?' | translate | uppercase}}</b><br />
          <div class="divintercf">
            <div style="display: flex;" class="" *ngIf="!editRoom;else editRoomElse;">
              <span class="relative-text color-base font-16 regular context-option">
                {{ request.whereName }}
                <span class="etiqueta where-btn" *ngIf="request.whereType == 'publicArea'">
                  {{'Public area' | translate}}
                </span>
                <span class="etiqueta where-btn" *ngIf="request.whereType == 'room'">
                  {{'Room' | translate}}
                </span>
              </span>
              <!-- <div *ngIf="auth.userHasScopes(['tasks:update']) && request.statusCodename !== 'finished'"
                style="margin-left: 0.5rem;" class="input__message__button" (click)="editRoom=true;">
                <i class="material-icons input__message__button__icon">edit</i>
              </div> -->
            </div>
            <ng-template #editRoomElse>
              <div class="col s8">
                <input maxlength="50"
                  (keyup.enter)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  (focusout)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  [value]="request.whereName" id="first_name" type="text" class="validate">
                <span class="edit-text">{{"Press 'enter' to save" | translate}}</span>
              </div>

            </ng-template>
          </div>

        </div>

      </div>

      <div class="col s12"><br></div>

      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-when">
          <i class="material-icons">query_builder</i>
        </div>

        <div class="margin0">
          <b class="semiblod-color-base font-12 context-option">
            {{'When?' | translate | uppercase}}</b><br />
          <div class="divintercf">
            <span class="relative-text  font-16 regular context-option">
              {{ request.executionDate | date:'d MMM yyyy':'TZ' : langug }}
              <span class="semiblod-color-base">
                {{ request.executionDate | date:'h:mm a':'TZ' : langug }}
              </span>
            </span>
          </div>
          <div style="display: flex; margin-top: 8px; margin-left: 43px;">
            <div [ngSwitch]="request.customizeType" class="col s10">
              <span class="etiqueta" style="margin-left: 0px; display: block; ">
                <ng-containe *ngSwitchCase="'no-repeat'">
                  {{ 'This task is not repeat' | translate }}
                </ng-containe>
                <ng-containe *ngSwitchCase="'day'">
                  <ng-container *ngIf="request.repeatCount == 1">
                    {{ 'Every day' | translate }}
                  </ng-container>
                  <ng-container *ngIf="request.repeatCount > 1">
                    {{ 'Each' | translate }} {{ request.repeatCount }} {{ 'days' | translate }}
                  </ng-container>
                </ng-containe>
                <ng-containe *ngSwitchCase="'week'">
                  <ng-container *ngIf="request.repeatCount == 1">
                    {{ 'Every week on' | translate }}
                  </ng-container>
                  <ng-container *ngIf="request.repeatCount > 1">
                    {{ 'Each' | translate }} {{ request.repeatCount }} {{ 'weeks on' | translate }}
                  </ng-container>
                  {{ programmingRepeatService.allDaysRepeat(request) }}
                </ng-containe>
                <ng-containe *ngSwitchCase="'month'">
                  <ng-container *ngIf="request.repeatCount == 1">
                    {{ 'Every month' | translate }}
                  </ng-container>
                  <ng-container *ngIf="request.repeatCount > 1">
                    {{ 'Each' | translate }} {{ request.repeatCount }} {{ 'months' | translate }}
                  </ng-container>
                  {{ 'the day' | translate }} {{ request.repeatDay }}
                </ng-containe>
                <ng-containe *ngSwitchCase="'year'">
                  <ng-container *ngIf="request.repeatCount == 1">
                    {{ 'Every year' | translate }}
                  </ng-container>
                  <ng-container *ngIf="request.repeatCount > 1">
                    {{ 'Each' | translate }} {{ request.repeatCount }} {{ 'years' | translate }}
                  </ng-container>
                  {{ 'the day' | translate }} {{ request.repeatDay }}
                  {{ programmingRepeatService.getMonthDetail(request) }}
                </ng-containe>
                <ng-containe *ngIf="request.customizeType != 'no-repeat'">
                  {{ 'starting on' | translate }} {{ request.startingDate | date:'dd MMM yyyy':'TZ' :
                  langug}}
                  <ng-containe *ngIf="request.finishedDate">
                    {{ 'ending the' | translate }} {{ request.finishedDate | date:'dd MMM yyyy':'TZ'
                    :langug}}
                  </ng-containe>

                </ng-containe>
              </span>
            </div>

            <div class="col s3" style="margin-left: -1rem;">
              <div *ngIf="auth.userHasScopes(['tasks:update']) && request.statusCodename !== 'finished'"
                class="input__message__button" (click)="otherActionEvent.emit('update:when')">
                <i class="material-icons input__message__button__icon">edit</i>
              </div>
            </div>
          </div>
          <div style="display: flex; margin-top: 8px; margin-left: 54px;">
            <span class="etiqueta" style="margin-left: 0px; display: block; ">
              {{(request.accumulate ? 'Build-up' : 'Not build-up') | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col s12 l12 xl12 m12"><br></div>

  <div class="col s12 l12 xl12 white padding0 modal-padding-leftright">

    <div class="col s12 border__top__4line border-line "><br /></div>

  </div>
  <div class="col s12 padding0 modal-padding-leftright" style="margin-top:-15px ;">
    <br />

    <div class="row">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">{{ 'Creation Date' | translate | uppercase }} </span><br />
        <span class="two color-base font-16 regular">
          {{ request.executionDate | date:'d MMM yyyy':'TZ' : langug }} {{ request.executionDate | date:'h:mm a':'TZ' :
          langug }}
        </span>
      </div>
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">{{ 'Due Time' | translate | uppercase }} </span><br />
        <span class="two color-base font-16 regular">
          {{ request.dueDate | date:'d MMM yyyy':'TZ' : langug }} {{ request.executionDate | date:'h:mm a':'TZ' : langug
          }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Maximum “New” Status Time' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 color-base font-16 regular">
          00:{{ request.newStatusTimeMinutes < 10 ? '0' + request.newStatusTimeMinutes : request.newStatusTimeMinutes }}
            min </span>
      </div>
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Maximum “Accepted” Status Time' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 color-base font-16 regular">
          00:{{ request.timeExecutionMinutes < 10 ? '0' + request.timeExecutionMinutes : request.timeExecutionMinutes }}
            min </span>
      </div>
    </div>
    <div class="row" *ngIf="request.subcategoryFollowUp">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">{{ 'Maximum “Verification” Time' | translate |
          uppercase
          }}
        </span><br>
        <span class="two font-16">
          {{ request.followUpTimeHours < 10 ? '0' + request.followUpTimeHours : request.followUpTimeHours }}h {{
            request.followUpTimeMinutes < 10 ? '0' + request.followUpTimeMinutes : request.followUpTimeMinutes }}m
            </span>
      </div>
      <div class="col s12 m6 padding0">

      </div>
    </div>

    <div class="col s12 l12 m12 xl12">
      <br />
    </div>
  </div>
</div>