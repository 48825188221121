import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-guesthub-modal-take-picture-img',
    templateUrl: 'template.html',
    styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit, OnDestroy {
    @Output() close = new EventEmitter()
    @Output() onCapture = new EventEmitter();
    @ViewChild("video") public video: ElementRef;
    @ViewChild("canvas") public canvas: ElementRef;
    stream: any;
    captures: string[] = [];
    error: any;
    isCaptured: boolean;

    public ngOnInit() { }

    public async ngAfterViewInit() {
        await this.setupDevices();
    }

    ngOnDestroy() {
        this.stream.getTracks().forEach((track) => track.stop());
    }

    async setupDevices() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { ideal: 1280 },
                        height: { ideal: 720 }
                    },
                    audio: false
                });
                if (this.stream) {
                    this.video.nativeElement.srcObject = this.stream;
                    this.video.nativeElement.play();
                    this.error = null;
                } else {
                    this.error = "You have no output video device";
                }
            } catch (e) {
                this.error = e;
            }
        }
    }

    capture() {
        this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        const capture: string = this.canvas.nativeElement.toDataURL("image/png")

        this.captures.push(capture);
        this.isCaptured = true;
        this.onCapture.emit(capture.replace('data:image/png;base64,', ''))
    }
}