import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { OutletsListRecyclearViewItemPreviewComponent } from "./component";
import { OutletsDetailService } from "./detail/service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MaterialModule } from "../../material.module";

import { OutletsDetailModule } from "./detail/module";
import { OutletsHistoryModule } from "./history/module";
import { OutletsCommentsModule } from "./comments/module";
import { FormsModule } from '@angular/forms';

import { ComponentModalConfirmedModule } from "../../components/modal-confirmed/module";
import { RequestsPreviewUpdatedFinishedReturnedModule } from "../../requests-updated/preview/finished-returned/module";

@NgModule({
  declarations: [
    OutletsListRecyclearViewItemPreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    OutletsCommentsModule,
    OutletsHistoryModule,
    OutletsDetailModule,
    ComponentModalConfirmedModule,
    RequestsPreviewUpdatedFinishedReturnedModule
  ],
  exports: [
    OutletsListRecyclearViewItemPreviewComponent
  ],
  providers: [OutletsDetailService],
})
export class OutletsListRecyclearViewItemPreviewModule { }
