import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavigationComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Ng5BreadcrumbModule } from 'ng5-breadcrumb';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { GearIconModule } from './gear-icon/module';
import { NotificationsModule } from './notifications/module';
import { NotificationsService } from "./notifications/service";

import { MyProfileModule } from "../../my-profile/module";
import { ComponentModalDisconnectModule } from '../../components/modal-disconnect/module';

import { NotificationDetailModule } from "./notifications/detail/module";

import { RequestsManagerPreviewModule } from "../../requests-updated/preview/module";
import { SideService } from "../side/service";
import { OutletsListRecyclearViewItemPreviewModule } from '../../outlets/preview/module';
import { TasksManagerPreviewModule } from '../../tasks-manager/preview/module';
import { ComponentModalAboutModule } from './modal-about/module';
import { CheckInPreviewModule } from '../../guests/check-in/preview/module';

@NgModule({
    declarations: [
        TopNavigationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TooltipModule,
        MaterialModule,
        Ng5BreadcrumbModule,
        RouterModule,
        BsDropdownModule,
        GearIconModule,
        NotificationsModule,
        MyProfileModule,
        ComponentModalDisconnectModule,
        NotificationDetailModule,
        OutletsListRecyclearViewItemPreviewModule,
        CheckInPreviewModule,
        TasksManagerPreviewModule,
        ComponentModalAboutModule,
        RequestsManagerPreviewModule
    ],
    exports: [
        TopNavigationComponent
    ],
    providers: [NotificationsService, SideService],
})
export class TopNavigationModule { }
