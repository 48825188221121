import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MyProfileService } from "./service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-my-profile',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Output() output = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  changePassword: boolean = false;
  loading: boolean = true;
  disabledButton: boolean = false;
  modalChangeCover: boolean = false;
  modalChangeCoverVisible: boolean = true;
  user: any;
  languages: any[] = [];
  validPhone: boolean = false
  validOfficePhone: boolean = false
  focus: boolean = false
  dirty: boolean = false
  focuspPhone: boolean = false
  dirtyPhone: boolean = false

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router,
    private service: MyProfileService
  ) {
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    this.loading = true;
    Promise.all([
      this.service.getUserInformation(),
      this.service.languages()
    ]).then(([user, languages]) => {
      this.languages = languages;
      this.user = user;
      this._autoValidate();
      this.loading = false;
    });
  }

  _autoValidate() {
    this.validPhone = this.user.phone && this.user.phone.toString().trim().length > 0 ? (!/^\+([0-9]+)$/.test(this.user.phone.toString().trim())) : false;
    this.validOfficePhone = this.user.officePhone && this.user.officePhone.toString().trim().length > 0 ? (!/^\+([0-9]+)$/.test(this.user.officePhone.toString().trim())) : false;
    this.disabledButton = this.user.firstName.toString().trim().length === 0
      || this.user.lastName.toString().trim().length === 0
      || this.validOfficePhone
      || this.validPhone;

  }


  onComplete(event) {
    const backendOff = false;
    if (backendOff) {
      // Backend off, infinity loading
    } else {
      this.user.picture = event.urlToPreview;
      this.user.pictureLoading = null;
      this.modalChangeCoverVisible = true;
      this.modalChangeCover = false;
    }
  }

  onError(event) {
    this.modalChangeCoverVisible = true;
    this.modalChangeCover = false;
    this.user.picture = null;
    this.user.pictureLoading = null;
    this.openSnackBar(this.translate.instant("Uploaded File Exceeds Max Size"), "✖");
  }

  loadingImg(event) {
    if (event) {
      this.modalChangeCoverVisible = false;
      this.user.picture = null;
      this.user.pictureLoading = event;
    }
  }

  cancel() {
    this.close.emit(null);
  }

  changeLanguage(language: string) {
    this.user.language = language;
    this._autoValidate();
  }

  save() {
    this.disabledButton = true;
    this.service.update({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phone: this.user.phone,
      officePhone: this.user.officePhone,
      language: this.user.language,
      picture: this.user.picture
    })
      .then(response => {

        localStorage.setItem("lang", JSON.stringify({ lang: this.user.language }));

        this.translate.use(this.user.language);

        this.output.emit(response);
      }).catch(err => {
        console.error(err);
        this.disabledButton = false;
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
