import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { differenceInSeconds, isFuture, isPast } from "date-fns";

@Component({
  selector: "app-outlets-timer",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class OutletsTimerComponent implements OnInit, OnChanges {
  @Output() state = new EventEmitter<string>();
  @Input() startDate: number;
  @Input() endDate: number;
  @Input() percentageWarning: number = 25;
  @Input() percentageDanger: number = 10;
  @Input() preview: boolean;
  @Input() countSumate: number;

  percentage: number;
  timeLeft: {
    negative: boolean;
    year: string;
    month: string;
    week: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
  } = {
    negative: false,
    year: "00",
    month: "00",
    week: "00",
    day: "00",
    hour: "00",
    minute: "00",
    second: "00",
  };
  stateLocal: string = "successful";

  constructor(public translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.initComponent();
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    let total = differenceInSeconds(
      new Date(this.endDate),
      new Date(this.startDate)
    );
    if (!isFuture(new Date(this.startDate))) {
      // Si el start date ya comenzo
      let current = differenceInSeconds(new Date(this.endDate), new Date());
      this.percentage = (current * 100) / total;
      this.timeLeft = this.secondsToString(current.toString());
      if (!this.timeLeft.negative) {
        if (this.percentage <= this.percentageWarning) {
          this.stateLocal = "warning";
        } else if (this.percentage <= this.percentageDanger) {
          this.stateLocal = "danger";
        } else {
          this.stateLocal = "successful";
        }
      } else {
        this.stateLocal = "warning";
      }
      this.state.emit(this.stateLocal);
    } else {
      // Si el start date esta en el futuro no ha comenzado.
      this.percentage = 100;
      this.timeLeft = this.secondsToString(total.toString());
      this.stateLocal = this.timeLeft.negative ? "danger" : "successful";
      this.state.emit(this.stateLocal);
    }
  }

  secondsToString(seconds: string) {
    const negative = parseInt(seconds) < 0;
    seconds = seconds.toString().replace("-", "");
    let year: string = Math.floor(parseInt(seconds) / 3.154e7).toString();
    year = parseInt(year) < 10 ? "0" + year : year;
    let month: string = Math.floor(parseInt(seconds) / 2.628e6).toString();
    month = parseInt(month) < 10 ? "0" + month : month;
    let week: string = Math.floor(parseInt(seconds) / 604800).toString();
    week = parseInt(week) < 10 ? "0" + week : week;
    let day: string = Math.floor(parseInt(seconds) / 86400).toString();
    day = parseInt(day) < 10 ? "0" + day : day;
    let hour: string = Math.floor(parseInt(seconds) / 3600).toString();
    hour = parseInt(hour) < 10 ? "0" + hour : hour;
    let minute: string = Math.floor((parseInt(seconds) / 60) % 60).toString();
    minute = parseInt(minute) < 10 ? "0" + minute : minute;
    let second: string = (parseInt(seconds) % 60).toString();
    second = parseInt(second) < 10 ? "0" + second : second;
    return { negative, year, month, week, day, hour, minute, second };
  }
  calculate(time) {
    const year = Number(time.year);
    const month = Number(time.month);
    const week = Number(time.week);
    const day = Number(time.day);
    const hour = Number(time.hour);
    const minute = Number(time.minute);
    const second = Number(time.second);
    if (year > 0) {
      return `${year}y ${month > 11 ? month - year * 12 : month}m`;
    } else if (month > 0 && year < 0) {
      return `${month}m ${week > 3 ? week - month * 4 : week}w`;
    } else if (week > 0 && month <= 0) {
      return `${week}w ${day > 6 ? day - week * 7 : day}d`;
    } else if (day > 0 && week <=0) {
      return `${day}d ${hour > 23 ? hour - day * 24 : hour}h`;
    } else if (hour > 0 && day <=0) {
      return `${hour}h : ${minute}m`;
    } else if (minute > 0 && hour <= 0) {
      return `${minute}m : ${second}s`;
    }else if(second > 0 && minute <= 0){
      return `${second}s`
    }
  }
}
