<p class="col s12 m12 xl12"></p>
<div class="modal-padding-leftright">
  <div
    class="left padding0"
    *ngIf="!searchOpen"
    style="margin-bottom: 21px; margin-top: -11px"
  >
    <ul class="list-message margin-top0 margin0">
      <li
        class="center pointer btnTabMessages"
        (click)="filterComments('guest');"
        *ngIf="!this.isInternal"
        [ngClass]="{'active_btn': typeComments=='guest'}"
      >
        <i class="material-icons icon-message reative-top-2">person</i>
        {{'Guest' | translate}}
        <span
          class="right notification-red"
          *ngIf="countComments('guest') > 0"
        ></span>
      </li>
      <li
        class="center pointer btnTabMessages"
        (click)="filterComments('internal');"
        [ngClass]="{'active_btn': typeComments=='internal'}"
      >
        <i class="material-icons icon-message reative-top-2">home</i>
        {{'Internal' | translate}}
        <span
          class="right notification-red"
          *ngIf="countComments('internal') > 0"
        ></span>
      </li>
    </ul>
  </div>
  <div class="right" *ngIf="!searchOpen">
    <i
      class="material-icons color-base-two icons-search pointer"
      (click)="searchOpen = !searchOpen;"
      >search</i
    >
  </div>
  <div class="clearfix" *ngIf="!searchOpen"></div>
  <div
    class="input-field col s12 padding0 border-input sear__top"
    *ngIf="searchOpen"
  >
    <i
      class="material-icons prefix color-base-two icons-search pointer"
      (click)="searchClose(); searchInComments('');"
      >keyboard_arrow_left</i
    >
    <input
      id="searchComments"
      placeholder="{{'Search' | translate}}"
      type="text"
      class="input-search input-search-message"
      [(ngModel)]="searchTxt"
      (keyup)="searchInComments($event.target.value)"
    />
    <i
      class="material-icons subprefix color-base-two icons-search i__delet pointer"
      (click)="searchClear(); searchInComments('');"
      >clear</i
    >
  </div>

  <div *ngIf="searchTxt.length > 0 && commentsFilter.length === 0 ">
    <div style="text-align: center">
      <i
        style="color: #686c6d"
        class="material-icons icon-message reative-top-2"
        >cancel</i
      >
      {{'No results' | translate}}
    </div>
  </div>
</div>

<ul
  class="modal-padding-leftright content-messages scrolling-gavete"
  #scrollMe
  [scrollTop]="scrollMe.scrollHeight"
>
  <li
    *ngFor="let comment of commentsFilter | filter:searchTxt ; let i = index"
    class="padding0 {{ auth.userProfile.id != comment.authorId ? 'my-message-left' : '' }}"
  >
    <div class="col s9">
      <div
        class="message-header {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }}"
      >
        <strong> {{ comment.authorName }} </strong>
        <span class="color-base-two font14">
          {{ comment.creationDate | date: "MMM" | translate }}
        </span>
        <span class="color-base-two font14">
          {{ comment.creationDate | date: "dd yyyy h:mm a" }}
        </span>
      </div>
      <div
        class="message-body {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }} font-content font-message"
        [innerHtml]="comment.body"
      ></div>

      <div
        *ngIf="comment.images.length === 1"
        class="message-body pointer {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }} img-big"
      >
        <img
          [src]="comment.images[0].url"
          [alt]="comment.images[0].id"
          (click)="onImgClick.emit({ imgList: comment.images, currentImg: comment.images[0].url})"
        />
      </div>
      <div *ngIf="comment.images.length > 1">
        <div
          *ngFor="let image of comment.images"
          class="message-body {{ auth.userProfile.id == comment.authorId ? 'my-message' : '' }} img-list"
        >
          <img
            [src]="image.url"
            [alt]="image.id"
            (click)="onImgClick.emit({ imgList: comment.images, currentImg: image.url})"
          />
        </div>
      </div>

      <div class="clearfix" style="margin-bottom: 40px"></div>
    </div>
    <div class="col s2">
      <img
        class="imagen-avatare"
        *ngIf="comment.authorPicture && auth.userProfile.id == comment.authorId"
        src="{{ comment.authorPicture }}"
        width="100%"
      />
    </div>
    <div class="col s1">
      <div
        *ngIf="auth.userProfile.id == comment.authorId"
        style="position: relative"
      >
        <div
          class="pointer"
          (click)="comment.showMoreOptions = !comment.showMoreOptions"
          data-target="dropdown1"
        >
          <i
            class="material-icons button-three-point__icon top03"
            style="color: #686c6d"
            >more_vert</i
          >
          <div class="clearfix"></div>
        </div>
        <div
          class="hidden-backgound"
          *ngIf="comment.showMoreOptions"
          (click)="comment.showMoreOptions = false;"
        ></div>
        <ul
          id="dropdown1"
          class="dropdown-content drop-cont padding"
          [ngClass]="{'inDropDown':comment.showMoreOptions}"
        >
          <li class="cont-li">
            <a
              (click)="comment.showMoreOptions = false;eventDeleteComment.emit(comment);"
              class="color-txt"
            >
              <i class="material-icons">delete</i>
              {{ 'Delete' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="clearfix"></div>
  </li>
</ul>

<div class="col s12 xl12 send-message">
  <div *ngIf="images.length > 0 || loadingImg" class="upload-preview-area">
    <div *ngFor="let image of images" class="img-area">
      <a (click)="deleteImg(image)" class="right close-btn">
        <i class="material-icons">close</i>
      </a>
      <img [src]="image.url" />
    </div>
    <div *ngIf="loadingImg" class="img-area">
      <mat-spinner class="loading-img" diameter="35"></mat-spinner>
    </div>
  </div>
  <!-- onclick uploadOption = true -->
  <div class="col s2 padding0 cont__send__img" (click)="uploader.click()">
    <input
      hidden
      type="file"
      #uploader
      (change)="uploadFile($event)"
      accept="image/*"
      multiple
    />
    <!--multiple-->
    <i
      class="material-icons white-text pointer buttonCamare"
      [ngClass]="{'disabled_button': disabledButtonUpload}"
    >
      photo_camera
    </i>
  </div>
  <div class="input-field col s8 padding0 margin0 input-sms txt__inp__cont">
    <textarea
      *ngIf="typeComments=='internal'"
      [mention]="usersFiltered"
      [mentionListTemplate]="mentionListTemplate"
      [mentionConfig]="{labelKey:'name',allowSpace:true}"
      (itemSelected)="onTagSelected($event)"
      (keypress)="onKeyPress($event)"
      (keyup)="onKeyUp($event)"
      (itemSelected)="onItemSelected($event)"
      id="message"
      class="validate input-search-message-sms newMessage"
      #textAreaChat
      placeholder="{{'Enter new message'| translate}}"
      maxlength="2000"
      [(ngModel)]="newComent"
      autocomplete="off"
      autofocus
      appTextareaAutoresize
    ></textarea>

    <textarea
      *ngIf="typeComments=='guest'"
      id="message"
      class="validate input-search-message-sms newMessage"
      #textAreaChat
      placeholder="{{'Enter new message'| translate}}"
      maxlength="2000"
      [(ngModel)]="newComent"
      autocomplete="off"
      autofocus
      appTextareaAutoresize
    ></textarea>
  </div>
  <div class="col s2 send__cont">
    <button
      class="pointer"
      (click)="addComments()"
      [disabled]="disabledButton || loadingImg"
      class="float-send-btn padding0 right"
      [ngClass]="{'inactive-btn': disabledButton || loadingImg}"
    >
      <i class="material-icons white-text"> send </i>
    </button>
  </div>
</div>

<ng-template #mentionListTemplate let-item="item">
  <img
    class="circle responsive-img"
    [src]="item.picture"
    alt=""
    width="32"
    height="32"
  />
  <span
    styl="
  color: var(--gh-color-dark-grey, #686C6D);
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;"
    >{{item.name}}</span
  >
  <div style="margin-left: auto">
    <span
      style="
        display: flex;
        padding: 0.25rem 0.5rem;
        align-items: center;
        gap: 0.25rem;
        border-radius: 0.5rem;
        background: var(--gh-color-light-grey-2, #e3e3e3);
        color: var(--gh-color-dark-grey, #686c6d);
        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      "
      >{{item.department}}
    </span>
  </div>
</ng-template>
