import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "./services/url-builder.service";
import { Auth0Service } from "./auth0.service";
import { EventsService } from "./events.service";

@Injectable({
  providedIn: "root",
})
export class HttpEventSourceEventsService implements EventsService{
  channels: {
    request: Subject<any>;
    foodAndBeverage: Subject<any>;
    notifications: Subject<any>;
    outlets: Subject<any>;
    outletSetting: Subject<any>;
    checkin: Subject<any>;
    guestItemsLostFound: Subject<any>;
    guestItemsPackages: Subject<any>;
    locations: Subject<any>;
    tasksManager: Subject<any>;
  } = {
      request: new Subject<any>(),
      foodAndBeverage: new Subject<any>(),
      notifications: new Subject<any>(),
      outlets: new Subject<any>(),
      outletSetting: new Subject<any>(),
      checkin: new Subject<any>(),
      guestItemsLostFound: new Subject<any>(),
      guestItemsPackages: new Subject<any>(),
      locations: new Subject<any>(),
      tasksManager: new Subject<any>(),
    };
  eventSource;
  loading = false;
  eventSourceNew;
  loadingNew = false;

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    protected auth: Auth0Service
  ) { }

  public initNew(propertyId: string) {
    this.eventSourceNew = new window["EventSource"](
      `${environment.events.api}/${propertyId}`
    );
    this.subscribeHandlersNew(this.eventSourceNew);
    this.eventSourceNew.onerror = function (event) {
      if (!this.loadingNew) {
        this.eventSourceNew.close();
        this.eventSourceNew = undefined;
        setTimeout(
          function () {
            this.initNew(propertyId);
          }.bind(this),
          2 * 5000
        );
        this.loadingNew = true;
      }
    }.bind(this);
    this.loadingNew = false;
  }

  private subscribeHandlersNew(eventSource) {
    eventSource.addEventListener("requests:locations:list", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.locations.next(Object.assign({}, ev));
    });

    /* Requests */
    eventSource.addEventListener("request:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getRequestById({ id: ev.id })
        .then(response => {
          this.channels.request.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("request:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getRequestById({ id: ev.id })
        .then(response => {
          this.channels.request.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });

    /* Outlet */
    eventSource.addEventListener("guesthub:outlets:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getOutletById(ev)
        .then(response => {
          this.channels.outlets.next(Object.assign({}, response, {
            action: 'updated',
            stateTime: 'successful'
          }));
        });
    });
    eventSource.addEventListener("guesthub:outlets:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getOutletById(ev)
        .then(response => {
          this.channels.outlets.next(Object.assign({}, response, {
            action: 'created',
            stateTime: 'successful'
          }));
        });
    });

    /* Checkin */
    eventSource.addEventListener("guesthub:checkin:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getCheckInById(ev)
        .then(response => {
          this.channels.checkin.next(Object.assign({}, response, {
            action: 'updated',
            stateTime: 'successful'
          }));
        });
    });
    eventSource.addEventListener("guesthub:checkin:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getCheckInById(ev)
        .then(response => {
          this.channels.checkin.next(Object.assign({}, response, {
            action: 'created',
            stateTime: 'successful'
          }));
        });
    });

    /* Tasks Manager */
    eventSource.addEventListener("guesthub:tasks-manager:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getTaskById({ id: ev.id })
        .then(response => {
          this.channels.tasksManager.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:tasks-manager:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getTaskById({ id: ev.id })
        .then(response => {
          this.channels.tasksManager.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });
    eventSource.addEventListener("guesthub:tasks-manager:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.tasksManager.next(Object.assign({}, { id: ev.id }, {
        action: 'deleted'
      }));
    });

    /* Guest Items Lost & Found */
    eventSource.addEventListener("guesthub:guestItemsLostFound:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getGuestItemById({ type: 'lost-found', id: ev.id })
        .then(response => {
          this.channels.guestItemsLostFound.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:guestItemsLostFound:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getGuestItemById({ type: 'lost-found', id: ev.id })
        .then(response => {
          this.channels.guestItemsLostFound.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });

    /* Guest Items Packages */
    eventSource.addEventListener("guesthub:guestItemsPackages:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getGuestItemById({ type: 'packages', id: ev.id })
        .then(response => {
          this.channels.guestItemsPackages.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:guestItemsPackages:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getGuestItemById({ type: 'packages', id: ev.id })
        .then(response => {
          this.channels.guestItemsPackages.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });

    /* Notifications */
    eventSource.addEventListener("guesthub:notifications:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      Promise.all(ev.ids.filter(id => id.userId.toString() == this.auth.userProfile.id.toString())
        .map(id =>
          this.getNotificationById({ id: id.id })
            .then(response => {
              this.channels.notifications.next(Object.assign({}, response, {
                action: 'updated'
              }));
            })
        ));
    });
    eventSource.addEventListener("guesthub:notifications:created", (event: any) => {
      const ev = JSON.parse(event.data);
      if (ev.ids && ev.ids.length > 0) {
        Promise.all(ev.ids.filter(id => id.userId.toString() == this.auth.userProfile.id.toString())
          .map(id =>
            this.getNotificationById({ id: id.id })
              .then(response => {
                this.channels.notifications.next(Object.assign({}, response, {
                  action: 'created'
                }));
              })
          ));
      } else {
        if (ev.userId.toString() == this.auth.userProfile.id.toString()) {
          this.getNotificationById({ id: ev.id })
            .then(response => {
              this.channels.notifications.next(Object.assign({}, response, {
                action: 'created'
              }));
            });
        }
      }
    });
    eventSource.addEventListener("guesthub:notifications:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.notifications.next(Object.assign({}, {
        action: 'deleted',
        id: ev.id
      }));
    });
    eventSource.addEventListener("guesthub:notifications:deleted-all", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.notifications.next(Object.assign({}, {
        action: 'deleted-all'
      }));
    });

    /* Notifications */
    eventSource.addEventListener("guesthub:users:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      if (ev.propertyId.toString() == this.auth.getChosenProperty.toString()
        && ev.userId.toString() == this.auth.userProfile.id.toString()) {
        this.auth.logout();
      }
    });
    eventSource.addEventListener("guesthub:users:session", (event: any) => {
      const ev = JSON.parse(event.data);
      if (ev.propertyId.toString() == this.auth.getChosenProperty.toString()
        && ev.userId.toString() == this.auth.userProfile.id.toString()) {
        window.location.reload();
      }
    });
  }

  private getCheckInById(input) {
    return this.http.get<any>(`${this.urlbuilder.checkInApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  private getOutletById(input) {
    return this.http.get<any>(`${this.urlbuilder.outletsApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.type)
      .concat("/monitor/")
      .concat(input.id)
      .concat(input.outletId ? `?outletId=${input.outletId}` : '')}`).toPromise();
  }

  private getTaskById(input) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  private getGuestItemById(input) {
    return this.http.get<any>(`${this.urlbuilder.guestItemsApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat(`/${input.type}/`)
      .concat(input.id)}`).toPromise();
  }

  private getRequestById(input) {
    return this.http.get<any>(`${this.urlbuilder.requestsApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  private getNotificationById(input) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/notification")
      .concat("/")
      .concat(input.id)}`).toPromise();
  }
}
