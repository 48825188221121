

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { Router } from '@angular/router';
import { TasksManagerService } from "../../service";
import { ProgrammingRepeatService } from '../../../components/programing-repeat/service';

@Component({
  selector: 'app-tasks-preview-update',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})

export class MyComponent implements OnInit, OnChanges {
  @Output() otherActionEvent = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() modalViewCustomization = new EventEmitter<any>()
  @ViewChild("onFocusInputWhere") onFocusInputWhere: ElementRef<HTMLInputElement>;
  @ViewChild("onFocusInputWho") onFocusInputWho: ElementRef<HTMLInputElement>;
  @Input() request: any;
  @Input() customProgramming: any;

  disabledButton = false;
  selectedWere: any = null;
  selectedWho: any = null;
  list = {
    rooms: [],
    publicAreas: [],
    users: [],
    departments: []
  };
  loading = false;
  data: any = {};
  searchTxt = "";
  dirtyWhere: boolean;
  focusWhere: boolean;
  dirty: boolean;
  focus: boolean;
  focusWhen: boolean;
  langug = 'en-EN';
  minDate = new Date();
  modalConfirm: boolean;
  loadingWhere: boolean;
  loadingWho: boolean;

  constructor(
    public translate: TranslateService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    public router: Router,
    private _OwlDateTimeIntl: OwlDateTimeIntl,
    private service: TasksManagerService,
    protected programmingRepeatService: ProgrammingRepeatService
  ) {
    this.langug = JSON.parse(localStorage.getItem("lang")).lang == 'es' ? 'es-US' : 'en-EN';
    this.dateTimeAdapter.setLocale(this.langug);
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang == 'es' ? 'es-US' : 'en-EN';
      this.dateTimeAdapter.setLocale(this.langug);
    });
    this.getDatepickerLang(this.langug)
  }

  getDatepickerLang(lang) {
    this.translate.get("Save").subscribe((res: string) =>
      this._OwlDateTimeIntl.setBtnLabel = res
    );
    this.translate.get("Cancel").subscribe((res: string) =>
      this._OwlDateTimeIntl.cancelBtnLabel = res
    );
  }

  ngOnInit() {
    this.minDate = new Date();
    this.data.executionDate = new Date();
    this.data.startingDate = new Date();
    this.onKeySearch();
    this.initComponent()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.customProgramming) this.saveCustomizationRepeat(this.customProgramming)
  }

  onKeySearch() {
    this.data.startingDate = this.data.executionDate;
  }

  initComponent() {
    this.loading = true;
    Promise.all([
      this.searchResultWho({ key: '', target: { value: '' } }),
      this.searchResultWhere({ key: '', target: { value: '' } }),
    ]).then(() => {
      this.selectedWere = {
        name: this.request.whereName,
        type: this.request.whereType,
        value: true
      }
      this.selectedWho = {
        id: this.request.assignedId,
        picture: this.request.assigneePicture,
        type: this.request.assignedType
      }
      this.data = this.initialData();
      this.changeContent();
      this.loading = false;
    })
  }

  initialData() {
    return {
      assignedType: this.request.assignedType,
      assignedId: this.request.assignedId,
      assignedName: this.request.assigneeName,
      whereName: this.request.whereName,
      whereType: this.request.whereType,
      executionDate: this.request.executionDate,
      startingDate: this.request.startingDate,
      finishedDate: this.request.finishedDate,
      repeatCount: this.request.repeatCount,
      repeatType: this.request.repeatType,
      repeatDay: this.request.repeatDay,
      repeatDayOfWeek: this.request.repeatDayOfWeek,
      customizeType: this.request.customizeType,
      accumulate: this.request.accumulate,
      repeatMonth: this.request.repeatMonth,
      assigneePicture: this.request.assigneePicture
    }
  }

  cancel() {
    this.cancelEvent.emit(null);
  }

  openDialogConfirm() {
    this.disabledButton = true;
    const final = {
      ...(this.data.assignedName !== this.initialData().assignedName ? {
        assignedName: this.data.assignedName,
        assignedType: this.data.assignedType,
        assignedId: this.data.assignedId,
        assigneePicture: this.data.assigneePicture
      } : {}),
      ...(this.data.whereName !== this.initialData().whereName ? {
        whereName: this.data.whereName,
        whereType: this.data.whereType
      } : {}),
      ...(
        Object.keys(this.data).filter(k => ['finishedDate', 'startingDate', 'customizeType', 'repeatType', 'repeatCount', 'repeatDayOfWeek', 'repeatDay', 'repeatMonth'].includes(k))
          .map(key => this.initialData()[key] !== this.data[key]).filter(r => r === true).length > 0 ?
          {
            executionDate: this.data.executionDate,
            startingDate: this.data.startingDate,
            finishedDate: this.data.finishedDate,
            repeatCount: this.data.repeatCount,
            repeatType: this.data.repeatType,
            repeatDay: this.data.repeatDay,
            repeatDayOfWeek: this.data.repeatDayOfWeek,
            customizeType: this.data.customizeType,
            accumulate: this.data.accumulate,
            repeatMonth: this.data.repeatMonth
          } : {})
    }

    this.otherActionEvent.emit({
      type: 'all-update',
      value: final
    });
  }

  searchResultWhere(event) {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(event.key)) return
    this.loadingWhere = true;
    this.list = Object.assign({}, this.list, {
      rooms: [],
      publicAreas: []
    });
    this.searchTxt = event.target.value;
    this.data.whereType = null;
    this.data.whereName = null;
    this.selectedWere = null;
    this.service.searchWhere(this.searchTxt)
      .then(response => {
        this.list = {
          ...this.list,
          ...response,
          publicAreas: response.publicAreas.map(publicArea => ({
            ...publicArea,
            name: publicArea.names.find(name => (name.language == 2 ? 'es' : 'en') === JSON.parse(localStorage.getItem("lang")).lang)?.name || publicArea.name
          }))
        };
        this.loadingWhere = false;
      });
    this.changeContent();
  }

  changeContent() {
    this.disabledButton = !this.data.executionDate || !this.selectedWere || !this.selectedWho ||
      (JSON.stringify(this.data) === JSON.stringify(this.initialData()));
  }

  searchResultWho(event) {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(event.key)) return
    this.loadingWho = true;
    this.list = Object.assign({}, this.list, {
      users: [],
      departments: []
    });
    this.searchTxt = event.target.value;
    this.data.assignedName = null;
    this.data.assignedType = null;
    this.data.assigneePicture = null;
    this.data.assignedId = null;
    this.selectedWho = null;
    this.service.searchWho(this.searchTxt)
      .then(response => {
        this.list = Object.assign({}, this.list, {
          users: response.users.sort((a, b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 0).map(d => Object.assign({}, d, {
            type: "user"
          })),
          departments: response.departments.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0).map(d => Object.assign({}, d, {
            type: "departament"
          })).map(dep => ({
            ...dep,
            name: dep.names.find(name => name.language.code.toLowerCase() === JSON.parse(localStorage.getItem("lang")).lang)?.name || dep.name
          })),
        });
        this.loadingWho = false;
      });
    this.changeContent();
  }

  setWhere(event) {
    this.data.whereType = event.option.value.type;
    if (event.option.value.type == 'publicArea') {
      this.data.whereName = event.option.value.names || [];
    } else {
      this.data.whereName = event.option.value.name;
    }
    this.selectedWere = event.option.value;
    this.searchTxt = `${event.option.value.name}`;
    setTimeout(() => this.onFocusInputWhere.nativeElement.value = event.option.value.name, 10);
    document.getElementById("where").blur();
    this.changeContent();
  }

  setWho(event) {
    this.data.assignedType = event.option.value.type;
    this.data.assignedId = event.option.value.id;
    this.selectedWho = event.option.value;
    this.data.assigneePicture = event.option.value.picture
    this.searchTxt = event.option.value.type == "user"
      ? `${event.option.value.fristName} ${event.option.value.lastName}`
      : `${event.option.value.name}`;
    this.onFocusInputWho.nativeElement.value = event.option.value.type == 'user' ? event.option.value.firstName + " " + event.option.value.lastName : event.option.value.name;
    this.data.assignedName = this.onFocusInputWho.nativeElement.value;
    document.getElementById("who").blur();
    this.changeContent();
  }

  clearWhere() {
    this.list = {
      ...this.list,
      rooms: [],
      publicAreas: [],
    };
    this.searchTxt = "";
    this.data.whereType = null;
    this.data.whereName = null;
    this.selectedWere = null;
    this.onFocusInputWhere.nativeElement.value = this.searchTxt;
    this.changeContent();
  }

  clearWho() {
    this.list = {
      ...this.list,
      users: [],
      departments: []
    };
    this.searchTxt = "";
    this.data.assignedType = null;
    this.data.assignedId = null;
    this.selectedWho = null;
    this.onFocusInputWho.nativeElement.value = this.searchTxt;
    this.changeContent();
  }

  changeRepeat(e) {
    if (e == 'customized') {
      this.data.customizeType = this.data.customizeType == 'no-repeat' ? 'day' : this.data.customizeType;
      this.modalViewCustomization.emit({ ...this.data, customize: true });
    } else {
      this.data.repeatType = e;
      switch (this.data.repeatType) {
        case 'day':
          this.data.customizeType = 'day';
          this.data.repeatCount = 1;
          this.data.repeatDayOfWeek = undefined;
          this.data.repeatDay = undefined;
          this.data.repeatMonth = undefined;
          break;
        case 'week':
          this.data.customizeType = 'week';
          this.data.repeatCount = 1;
          this.data.repeatDayOfWeek = [new Date().getDay()];
          this.data.repeatDay = undefined;
          this.data.repeatMonth = undefined;
          break;
        case 'month':
          this.data.customizeType = 'month';
          this.data.repeatCount = 1;
          this.data.repeatDayOfWeek = undefined;
          this.data.repeatDay = new Date().getDate();
          this.data.repeatMonth = undefined;
          break;
        case 'year':
          this.data.customizeType = 'year';
          this.data.repeatCount = 1;
          this.data.repeatDayOfWeek = undefined;
          this.data.repeatDay = new Date().getDate();
          this.data.repeatMonth = new Date().getMonth() + 1;
          break;

        default: //Not repeat is a default
          this.data.customizeType = 'no-repeat';
          this.data.repeatCount = 1;
          this.data.repeatDayOfWeek = undefined;
          this.data.repeatDay = undefined;
          this.data.repeatMonth = undefined;
          break;
      }
      this.data.finishedDate = null;
    }
    this.changeContent();
  }

  saveCustomizationRepeat(event) {
    this.data = Object.assign({}, this.data, event);
    this.modalViewCustomization.emit({ ...this.data, customize: false });
    this.changeContent();
  }

  clearWhen() {
    this.changeRepeat("no-repeat")
    this.changeContent();
  }
}