import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../services/url-builder.service";
import { Auth0Service } from "../auth0.service";
import { environment } from "../../environments/environment";

@Injectable()
export class TasksManagerService {
  getFiltersDefaults: any = {
    searching: false,
    searchInComments: true,
    contentImg: false,
    searchTxt: "",
    order: "executionDate-desc",
    state: "active",
    statuses: [],
    date: [null, null],
    who: "",
    where: "",
    departmentId: null,
    categoryId: null,
    subcategoryId: null
  };

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  listAllUsers() {
    return this.http.get<any>(this.urlbuilder.getAllUsers()).toPromise();
  }

  getAllRooms() {
    return this.http.get<any>(`${this.urlbuilder.subscriptionApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/rooms")}`).toPromise();
  }

  getById(input) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  updateStatus(input, data) {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise();
  }

  updateStatusCancelled(input, data) {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/cancelled")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise();
  }

  updateWhen(input, data) {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/when")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise();
  }

  updateWhere(input, data) {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/where")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise();
  }

  listAllStatuses() {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/statuses")
      }`).toPromise();
  }

  uploadImgs(data) {
    data.append('userId', this.auth.userProfile.id);
    return this.http.post<any>(`${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${this.auth.getChosenProperty}`, data, {
      reportProgress: true,
      observe: 'events'
    }).toPromise();
  }

  setGetUserPreferences(userId, data) {
    return this.http
      .put<any>(
        `${this.urlbuilder.propertyApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat(`/user/${userId}/preferences`)}`,
        data
      )
      .toPromise();
  }

  listAllDepartmentsPlain(term?: string, limit?: number) {
    return this.http
      .get<any>(
        this.urlbuilder.requestConfiguration.departments.listPlain() +
        `?status=active${term && term !== '' ? '&search=' + term : ''}${limit ? '&limit=' + limit : ''}`
      )
      .toPromise();
  }

  listAllDepartments(filter) {
    return this.http
      .get<any>(
        `${this.urlbuilder.propertyApi
          .concat("/")
          .concat(
            this.auth.getChosenProperty.toString()
          )
          .concat("/process-builder/departments-by-monitor-used/task")
          .concat(filter.state ? "?state=" + filter.state : '')
        }`
      ).toPromise();
  }

  exportExcelAll(filter: any) {
    const StrFecha = new Date().toString();
    const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
    const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
    const Horas = (Math.round(HorasLocalesRespectoAGmt));
    const Minutos = (HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt));

    return this.http.get<any>(this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/export-excel/")
      .concat(filter.state)
      .concat('?')
      .concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.date && filter.date[0] && filter.date[1] && filter.date[0] != 'null' && filter.date[1] != 'null'
          ? "&fromDate=" + new Date(filter.date[0]).toISOString() +
          "&toDate=" + new Date(filter.date[1]).toISOString()
          : ""
        }${filter.location
          ? "&location=" + encodeURIComponent(filter.location)
          : ""
        }${filter.company
          ? "&company=" + encodeURIComponent(filter.company)
          : ""
        }${filter.confirmation
          ? "&confirmation=" + encodeURIComponent(filter.confirmation)
          : ""
        }${filter.statuses
          ? "&statuses=" + encodeURIComponent(filter.statuses.toString())
          : ""
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      ).concat(`&gmt=${timeZone}&action=${Horas < 0 ? 'add' : 'subtract'}&hours=${Horas}&minutes=${Math.floor(Math.abs(Minutos * 100))}`),
      { responseType: 'blob' as 'json' })
      .subscribe(
        (response: any) => {
          let filename = "" + Date.now();
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename)
            downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      );
  }

  listFutures(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/futures").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.departmentId && ![1, 2].includes(filter.departmentId)
          ? "&departmentId=" + filter.departmentId
          : ""
        }${filter.assignedId
          ? `&assignedId=${filter.assignedId}&assignedType=${filter.departmentId == 1 ? 'user' : 'department'}`
          : ''
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listToDo(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/to-do").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${"userId=" + this.auth.userProfile.id
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    // if (onlyCount) {
    return this.http.patch<any>(URL, {}).toPromise();
    // } else {
    //   return this.http.get<any>(URL).toPromise();
    // }
  }

  listActives(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/actives").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.departmentId && ![1, 2].includes(filter.departmentId)
          ? "&departmentId=" + filter.departmentId
          : ""
        }${filter.assignedId
          ? `&assignedId=${filter.assignedId}&assignedType=${filter.departmentId == 1 ? 'user' : 'department'}`
          : ''
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listAll(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/all").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.date && filter.date[0] && filter.date[1] && filter.date[0] != 'null' && filter.date[1] != 'null'
          ? "&fromDate=" + new Date(filter.date[0]).toISOString() +
          "&toDate=" + new Date(filter.date[1]).toISOString()
          : ""
        }${filter.who
          ? "&who=" + encodeURIComponent(filter.who)
          : ""
        }${filter.where
          ? "&where=" + encodeURIComponent(filter.where)
          : ""
        }${filter.searchInComments
          ? "&searchInComments=" + filter.searchInComments
          : ""
        }${filter.contentImg
          ? "&contentImg=" + filter.contentImg
          : ""
        }${filter.departmentId && ![1, 2].includes(filter.departmentId)
          ? "&departmentId=" + filter.departmentId
          : ""
        }${filter.categoryId
          ? "&categoryId=" + filter.categoryId
          : ""
        }${filter.subcategoryId
          ? "&subcategoryId=" + filter.subcategoryId
          : ""
        }${filter.statuses
          ? "&statuses=" + encodeURIComponent(filter.statuses.toString())
          : ""
        }${filter.assignedId
          ? `&assignedId=${filter.assignedId}&assignedType=${filter.departmentId == 1 ? 'user' : 'department'}`
          : ''
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  listFinished(filter: any, onlyCount: boolean = false) {
    const URL = `${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/finished").concat(
        `?${filter.page ? "page=" + filter.page : ""
        }${filter.searchTxt
          ? "&searchTxt=" + encodeURIComponent(filter.searchTxt)
          : ""
        }${filter.departmentId && ![1, 2].includes(filter.departmentId)
          ? "&departmentId=" + filter.departmentId
          : ""
        }${filter.assignedId
          ? `&assignedId=${filter.assignedId}&assignedType=${filter.departmentId == 1 ? 'user' : 'department'}`
          : ''
        }${filter.order ? "&order=" + encodeURIComponent(filter.order) : ""}`
      )}`;
    if (onlyCount) {
      return this.http.patch<any>(URL, {}).toPromise();
    } else {
      return this.http.get<any>(URL).toPromise();
    }
  }

  create(data) {
    return this.http.post<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      ).concat('/create-task')}`, Object.assign({}, data, this.auth && this.auth.userProfile && this.auth.userProfile.id ? {
        userId: this.auth.userProfile.id
      } : {})).toPromise();
  }

  commentCreate(input, data) {
    return this.http.post<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/comment")}`, {
      body: data.body,
      userId: this.auth && this.auth.userProfile && this.auth.userProfile.id ? this.auth.userProfile.id : undefined,
      type: data.type,
      images: data.images
    }).toPromise();
  }

  commentMarkToRead(input, type: String = 'internal') {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(input.id)
      .concat("/comment")}`, {
      userId: this.auth && this.auth.userProfile && this.auth.userProfile.id ? this.auth.userProfile.id : undefined,
      type: type
    }).toPromise();
  }

  deleteComment(data) {
    return this.http.delete<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/")
      .concat(data.requestId)
      .concat("/comment/")
      .concat(data.id)
      }`, {}).toPromise();
  }

  searchWhat(e: string) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      ).concat("/search-what").concat(
        `${e
          ? "?q=" + encodeURIComponent(e)
          : ""
        }`
      )}`).toPromise();
  }

  searchWho(e: any) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/search-who").concat(
        `${e
          ? "?q=" + encodeURIComponent(e)
          : ""
        }`
      )}`).toPromise();
  }

  searchWhere(e: any) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/search-where").concat(
        `${e
          ? "?q=" + encodeURIComponent(e)
          : ""
        }`
      )}`).toPromise();
  }

  updateGeneral(id, request) {
    return this.http.put<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      ).concat("/" + id)
      .concat("/general")}`, Object.assign({}, request, { userId: this.auth.userProfile.id })).toPromise();
  }

  searcWhere(e: string) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/search-where").concat(
        `${e
          ? `?q=${encodeURIComponent(e)}`
          : ``
        }`
      )}`).toPromise();
  }
}

