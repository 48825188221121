import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DateTimeAdapter, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { ProgrammingRepeatService } from './service';

@Component({
  selector: 'app-programing-repeat',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Output() exitEvent = new EventEmitter();
  @Output() outputEvent = new EventEmitter();
  @Input() data: any;
  dataObject: any;
  langug = 'en-EN';
  minDate = new Date();

  constructor(
    private translate: TranslateService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    private _OwlDateTimeIntl: OwlDateTimeIntl,
    protected programmingRepeatService: ProgrammingRepeatService
  ) {
    this.langug = this.translate.currentLang == 'es' ? 'es-US' : 'en-EN';
    this.getDatepickerLang(this.langug)
    this.dateTimeAdapter.setLocale(this.langug);
    this.translate.onLangChange.subscribe((change) => {
      this.getDatepickerLang(change.lang == 'es' ? 'es-US' : 'en-EN');
      this.dateTimeAdapter.setLocale(change.lang == 'es' ? 'es-US' : 'en-EN');
    });
  }

  getDatepickerLang(lang) {
    this.translate.get("Save").subscribe((res: string) => {
      this._OwlDateTimeIntl.setBtnLabel = res;
    });
    this.translate.get("Cancel").subscribe((res: string) => {
      this._OwlDateTimeIntl.cancelBtnLabel = res;
    });
  }

  ngOnInit() {
    this.dataObject = JSON.parse(JSON.stringify(this.data));
  }

  onKeySearch() {
    this.dataObject.executionDate = this.dataObject.startingDate;
  }

  changeTypeRepeat(type: string) {
    switch (type) {
      case 'day':
        this.dataObject.customizeType = 'day';
        this.dataObject.repeatCount = 1;
        this.dataObject.repeatDayOfWeek = undefined;
        this.dataObject.repeatDay = undefined;
        this.dataObject.repeatMonth = undefined;
        break;
      case 'week':
        this.dataObject.customizeType = 'week';
        this.dataObject.repeatCount = 1;
        this.dataObject.repeatDayOfWeek = [new Date().getDay()];
        this.dataObject.repeatDay = undefined;
        this.dataObject.repeatMonth = undefined;
        break;
      case 'month':
        this.dataObject.customizeType = 'month';
        this.dataObject.repeatCount = 1;
        this.dataObject.repeatDayOfWeek = undefined;
        this.dataObject.repeatDay = new Date().getDate();
        this.dataObject.repeatMonth = undefined;
        break;
      case 'year':
        this.dataObject.customizeType = 'year';
        this.dataObject.repeatCount = 1;
        this.dataObject.repeatDayOfWeek = undefined;
        this.dataObject.repeatDay = new Date().getDate();
        this.dataObject.repeatMonth = new Date().getMonth() + 1;
        break;

      default: //not repeat default
        this.dataObject.customizeType = 'no-repeat';
        this.dataObject.repeatCount = 1;
        this.dataObject.repeatDayOfWeek = undefined;
        this.dataObject.repeatDay = undefined;
        this.dataObject.repeatMonth = undefined;
    }
  }

  selectedDayOfWeek(day) {
    if (this.dataObject.repeatDayOfWeek.indexOf(day.value) > -1) {
      if (this.dataObject.repeatDayOfWeek.length > 1) {
        const arrayOfNumbers = this.dataObject.repeatDayOfWeek;
        arrayOfNumbers.splice(this.dataObject.repeatDayOfWeek.indexOf(day.value), 1);
        this.dataObject.repeatDayOfWeek = arrayOfNumbers;
      }
    } else {
      this.dataObject.repeatDayOfWeek.push(day.value);
      if (this.dataObject.repeatDayOfWeek.length == 7) {
        // If every day and repeat Every week on, then change to every day.
        this.changeTypeRepeat('day');
      }
      this.dataObject.repeatDayOfWeek = this.dataObject.repeatDayOfWeek.sort();
    }
  }

  isActiveDayOfWeek(day): boolean {
    return this.dataObject.repeatDayOfWeek.indexOf(day.value) > -1;
  }

  save() {
    this.outputEvent.emit(this.dataObject);
  }

  closeModal() {
    this.exitEvent.emit(true);
  }
}
