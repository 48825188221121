import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { OutletsHistoryComponent } from "./component";

@NgModule({
  declarations: [OutletsHistoryComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [OutletsHistoryComponent],
  providers: [],
})
export class OutletsHistoryModule {}
