<app-tasks-preview-update class="modal_right_0 white up_right mygavete" *ngIf="otherAction === 'update'"
  [request]="request" (output)="otherAction = null" [users]="users" (otherActionEvent)="otherActionEvent($event)"
  [disabledButton]="disabledButtonUpdateAll" (cancelEvent)="otherAction = null" [customProgramming]="editTaskDate"
  (modalViewCustomization)="modalViewCustomization = $event"></app-tasks-preview-update>

<div class="modal_right_0 white up_right mygavete" *ngIf="!otherAction">

  <div class="modal-content white" *ngIf="!loading">
    <div class="col s12 modal-margin-top modal-padding-leftright mod__mob">
      <i class="material-icons right pointer color-base-two cancel-icon" (click)="closeModal()">clear</i>
    </div>

    <div class="modal-title-container modal-padding-leftright"
      style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
      <h2 class="color-base font24 blod mod__mob marg-top" style="margin-bottom: 5px; flex-grow: 1;">
        {{ 'task' | translate }} #{{ request.idReference }}
      </h2>
      <div *ngIf="request.state == 'active'" class="modal-dropdown-options" style="flex-shrink: 0;">
        <div class="my-dropdown" style="position: relative;">
          <a class="pointer dropdown-trigger" (click)="showDropdownMoreOptions[i] = !showDropdownMoreOptions[i]"
            data-target='dropdown1'>
            <i class="material-icons icon__edit">more_vert</i>
          </a>

          <div class="hidden-backgound" *ngIf="showDropdownMoreOptions[i]"
            (click)="showDropdownMoreOptions[i] = false;"></div>
          <ul id='dropdown1' class='dropdown-content' [ngClass]="{'inDropDown':showDropdownMoreOptions[i]}">
            <li class="cont-li">
              <a class="txt-dropdown miniMenu" (click)="otherAction = 'update'; showDropdownMoreOptions[i] = false;"
                href="javascript:;">
                <i class="material-icons icon-margin">edit</i>
                {{ 'Edit' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col s12 padding0 modal-padding-leftright mod__mob" style="height: 60px;">
      <ul class="cont__mb__ul">
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='detail'"
          [ngClass]="{'active':tabSelected=='detail'}">
          {{ 'Detail' | translate }}
        </li>
        <li class="tab col s3 pointer comment-he mb__li__cont" (click)="tabSelected='comments'"
          [ngClass]="{'active':tabSelected=='comments'}">
          {{ 'Messages' | translate }}
          <span class="right notification-red" *ngIf="countComments() > 0">{{ countComments() }}</span>
        </li>
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='history'"
          [ngClass]="{'active':tabSelected=='history'}">
          {{ 'History' | translate }}
        </li>
        <!-- <li class="donwload__icon pointer" (click)="exportPDF()">
          <i class="material-icons icon__down">download</i>
        </li>  -->
      </ul>
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="modal-content" [ngSwitch]="tabSelected" style="height: 100%;" *ngIf="!loading">
    <app-tasks-manager-preview-detail *ngSwitchCase="'detail'" [request]="request" [countSumate]="countSumate"
      (otherActionEvent)="otherActionEvent($event)">
    </app-tasks-manager-preview-detail>
    <app-tasks-manager-preview-comments *ngSwitchCase="'comments'" (eventDeleteComment)="modalDeleteComment = $event;"
      [request]="request" [comments]="request.comments" (onCameraClick)="modalTakePicture = true"
      (onImgClick)="modalPreviewImg = $event">
    </app-tasks-manager-preview-comments>
    <app-tasks-manager-preview-history *ngSwitchCase="'history'" [id]="request.id" [history]="request.history">
    </app-tasks-manager-preview-history>
    <div class="clearfix"></div>
  </div>

  <div class="cols12 l12 xl12 m12 center" *ngIf="loading">
    <div class="col s12">
      <br><br>
    </div>
    <div class="loadingdiv">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-overlay pointer ups" (click)="closeModal()"></div>

<app-guesthub-modal-take-picture-img (close)="modalTakePicture = false" (onCapture)="takePicture($event)"
  *ngIf="modalTakePicture">
</app-guesthub-modal-take-picture-img>

<app-guesthub-modal-preview-img *ngIf="modalPreviewImg" [img]="modalPreviewImg"
  (close)="modalPreviewImg = null"></app-guesthub-modal-preview-img>

<app-programing-repeat *ngIf="editDate" [data]="request" (exitEvent)="editDate = false"
  (outputEvent)="onEditWhen($event)"></app-programing-repeat>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmEditWhen" [config]="configModalEditWhenConfirm"
  (confirm)="updateTask()" (cancel)="modalConfirmEditWhen = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalDeleteComment" [config]="configModalDeleteComment"
  (confirm)="deleteComment(modalDeleteComment)"
  (cancel)="modalDeleteComment = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalDeleteTicket" [config]="configModalCanceled"
  (confirm)="cancelTask()" (cancel)="modalDeleteTicket = false"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmEditWhere" [config]="configModalConfirmEditWhere"
  (confirm)="confirmEditWhere(modalConfirmEditWhere)"
  (cancel)="modalConfirmEditWhere = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmUpdateAll" [config]="configModalConfirmUpdateAll"
  (confirm)="checkBeforeUpdate();"
  (cancel)="modalConfirmUpdateAll = false; disabledButtonUpdateAll = false; configModalConfirmUpdateAll.config = undefined"></app-guesthub-component-modal-confirmed>

<app-programing-repeat *ngIf="modalViewCustomization?.customize" [data]="modalViewCustomization"
  (outputEvent)="editTaskDate = $event" (exitEvent)="modalViewCustomization.customize = false;"></app-programing-repeat>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmUpdate" [config]="configModalConfirmEditWhere"
  (confirm)="saveUpdateAll(); modalConfirmUpdate = null"
  (cancel)="modalConfirmUpdate = null"></app-guesthub-component-modal-confirmed>