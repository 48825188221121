<div class="col s12 padding0 content-detail scrolling-gavete" #scrollMe>
  <p *ngIf="!declineTicket" class="col s12 m12 xl12"></p>
  <div class="col s12 l12 xl12 white padding-base padding0">
    <div class="col s12 m6 xl6 l6 padding0 modal-padding-leftright" *ngIf="!declineTicket">
      <div class="col s12 l12 m12 xl12 padding0">
        <span class="color-base-two font-12 semiblod">{{'Status' | translate |
          uppercase}} </span>
      </div>

      <div class="col s5 l10 xl10 m10 padding0" *ngIf="request.otherStatuses.length > 0">
        <a class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ request?.statusColorHex }} z-depth-2"
          [matMenuTriggerFor]="menu" style="margin-top:8px;margin-bottom: 8px;">

          <span id="pending" class="semiblod ">
            {{ request.statusName | translate }}
          </span>

          <i class="material-icons">edit</i>
        </a>
      </div>

      <a class="btn btn-block btn-small-s btn-flat-ant {{ request?.statusColorHex }} pointer col s12 l10 xl10 m10 btn-f-b-flat noPointer padding0 margin-bottom10"
        *ngIf="request.otherStatuses.length === 0">
        <span> {{ request.statusName | translate}}</span>
      </a>

      <mat-menu #menu="matMenu">
        <a *ngFor="let act of request.otherStatuses"
          class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ act?.colorHex }} menuOption"
          (click)="update(act)">
          <span id="{{act.colorHex}}" class="semiblodBtn ">
            {{ act.name | translate}}
          </span>
        </a>
      </mat-menu>
      <div class="col s12 m10 l10 xl10 padding0" *ngIf="request.state == 'validate' && request?.endDate">
        <app-guests-check-in-timer class="position5" [startDate]="request?.startDate" [endDate]="request?.endDate"
          [percentageWarning]="'25'" [percentageDanger]="'10'" [countSumate]="countSumate"
          (state)="updateStateTime($event)">
        </app-guests-check-in-timer>

      </div>
      <div class="col s12 l12 m12">
        <br />
      </div>
    </div>

    <div class="col s12 padding0" *ngIf="!declineTicket">
      <div class="col s12 padding0 modal-padding-leftright" style="margin-bottom: 16px;">
        <span class="title-menu padding0">{{'Guest data' | translate}}</span>
      </div>
    </div>


    <div class="col s12 padding0">
      <div class="col s12 padding0 modal-padding-leftright">
        <div class="col s7 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('firstName', $event.target.checked)"
              (checked)="isDecline('firstName')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'FIRST NAME' | translate }} </span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.firstName}}</span>
        </div>
        <div class="col s5 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('lastName', $event.target.checked)"
              (checked)="isDecline('lastName')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'LAST NAME' | translate }} </span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.lastName}}</span>
        </div>
        <div class="col s7 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('birthDate', $event.target.checked)"
              (checked)="isDecline('birthDate')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'BIRTH DATE' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{request.birthday | date:'d MMM yyyy':'TZ' : langug}}
          </span>

        </div>
        <div class="col s5 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('nationality', $event.target.checked)"
              (checked)="isDecline('nationality')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{'NATIONALITY' | translate}}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{request.nationality?.name}}
          </span>

        </div>
        <div [ngSwitch]="request.documentType" class="col s7 padding0 marg__bot" *ngIf="!declineTicket">
          <span class="title__info">{{ 'ID TYPE' | translate }}</span>
          <span *ngSwitchCase="'passport'" class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ 'Passport' | translate }}
          </span>
          <span *ngSwitchCase="'licence-driver'" class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ 'Drive Licence' | translate }}
          </span>
          <span *ngSwitchCase="'dni'" class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ 'Identity card' | translate }}
          </span>
          <span *ngSwitchCase="'birth-certificate'" class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ 'Birth Certificate' | translate }}
          </span>
          <span *ngSwitchDefault class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ 'No Data' | translate }}
          </span>
        </div>
        <div class="col s5 padding0 marg__bot" [ngClass]="{'cont__cambiante': declineTicket }">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('documentNumber', $event.target.checked)"
              (checked)="isDecline('documentNumber')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info" [ngClass]="{'title__short': declineTicket}">{{ 'ID NUMBER' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.documentNumber}}</span>
        </div>
        <div class="col s7 padding0 marg__bot" [ngClass]="{'cont__change': declineTicket }"
          *ngIf="request.documentType != 'birth-certificate'">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in"
              (change)="selectDecline('documentExpiration', $event.target.checked)"
              (checked)="isDecline('documentExpiration')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'EXPIRATION DATE' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{request.documentExpirationDate | date:'d MMM yyyy':'TZ' : langug}}
          </span>

        </div>
        <div class="col s5 padding0 marg__bot"
          *ngIf="!declineTicket && request.typePerson != 'child' && request.typePerson != 'baby'">
          <!-- <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('profession', $event.target.checked)"
              (checked)="isDecline('profession')">
            <span class="list_menus_check box-check"></span>
          </label> -->
          <span class="title__info">{{ 'PROFESSION' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">
            {{ request.profession }}
          </span>
        </div>

        <div class="col s7 padding0 marg__bot" *ngIf="!declineTicket">
          <!-- <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('phone', $event.target.checked)"
              (checked)="isDecline('phone')">
            <span class="list_menus_check box-check"></span>
          </label> -->
          <span class="title__info">{{ 'PHONE' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.phone }}</span>
        </div>
        <div class="col s5 padding0 marg__bot" *ngIf="!declineTicket">
          <!-- <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('email', $event.target.checked)"
              (checked)="isDecline('email')">
            <span class="list_menus_check box-check"></span>
          </label> -->
          <span class="title__info">{{ 'MAIL' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.email }}</span>

        </div>
        <div class="col s12 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('address', $event.target.checked)"
              (checked)="isDecline('address')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'RESIDENCE ADDRESS' | translate }}</span>
          <span class="sub__title" [ngClass]="{'padd__check': declineTicket }">{{request.address}} </span>
        </div>
        <div class="col s12 padding0 marg__bot">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" (change)="selectDecline('documentFront', $event.target.checked)"
              (checked)="isDecline('documentFront')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'DOCUMENT FRONT PHOTO' | translate }}</span>
          <img style="width: 100%;" [ngClass]="{'marg__top': declineTicket }" [src]="request.documentImagenFront.url">
        </div>
        <div class="col s12 padding0 marg__bot" *ngIf="request.documentType != 'passport'">
          <label *ngIf="declineTicket">
            <input type="checkbox" class="filled-in" style="top: 3px;"
              (change)="selectDecline('documentBack', $event.target.checked)" (checked)="isDecline('documentBack')">
            <span class="list_menus_check box-check"></span>
          </label>
          <span class="title__info">{{ 'DOCUMENT BACK PHOTO' | translate }}</span>
          <img style="width: 100%;" [ngClass]="{'marg__top': declineTicket }" [src]="request.documentImagenBack.url">
        </div>

        <div class="col s12 padding0" *ngIf="!declineTicket">

          <div class="col s12 padding0 marg__bot">
            <span class="title__info">{{ 'GUEST SIGNATURE' | translate }}</span>
            <img style="width: 100%; border: 1px solid #686C6D; border-radius: 6px;" [src]="request.signature">
          </div>

          <div class="col s12 padding0 marg__bot">
            <span *ngFor="let term of request.terms" class="read__click pointer" style="margin-right: 16px;"
              (click)="exportPDF(request.id, term.id)">
              {{term.title}}
            </span>
          </div>

          <div class="col s12 padding0 marg__bot" style="margin-top: 15px;"
            *ngIf="!request.requestFather && request.companions.length > 0">
            <span class="title__info">{{ 'COMPANIONS' | translate }} ({{request.companionsCompleted.length}} of
              {{request.companions.length}})</span>
            <div class="col s12 padding0">
              <ul class="col s12 padding0" style="margin-top: 4px;">
                <li class="col s12 padding0 cont__acomp" *ngFor="let companion of request.companionsCompleted"
                  (click)="viewDetailCompanion(companion)">
                  <div class="col s12">
                    <div class="col s12 padding0">
                      <span class="txt__primary">{{ companion.firstName }} {{ companion.lastName }}</span>
                    </div>
                    <div class="col s5 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                      <span class="second__text">
                        {{ companion.phone }}
                      </span>
                    </div>
                    <div class="col s7 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                      <span class="second__text">{{ companion.email }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col s12 padding0 marg__bot" style="margin-top: 15px;" *ngIf="request.requestFather">
            <span class="title__info">{{ 'REQUEST PRINCIPAL' | translate }}</span>
            <div class="col s12 padding0">
              <ul class="col s12 padding0" style="margin-top: 4px;"
                (click)="viewDetailCompanion(request.requestFather)">
                <li class="col s12 padding0 cont__acomp">
                  <div class="col s12">
                    <div class="col s12 padding0">
                      <span class="txt__primary">{{ request.requestFather.firstName }} {{ request.requestFather.lastName
                        }}</span>
                    </div>
                    <!-- <div class="col s6 padding0">
                      <span class="txt__date">fecha</span>
                    </div> -->
                    <div class="col s5 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                      <span class="second__text">
                        {{ request.requestFather.phone }}
                      </span>
                    </div>
                    <div class="col s7 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                      <span class="second__text">{{ request.requestFather.email }}</span>
                    </div>
                    <!-- <div (click)="companion.showMoreInfo = !companion.showMoreInfo" class="padding0 poss__arrow">
                      <i class="material-icons" *ngIf="!companion.showMoreInfo"
                        style="color: #686C6D;">arrow_drop_up</i>
                      <i class="material-icons" *ngIf="companion.showMoreInfo"
                        style="color: #686C6D;">arrow_drop_down</i>
                    </div>
                    <div class="col s12 padding0 brd__top" *ngIf="!companion.showMoreInfo">
                      <div class="col s6 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                        <span class="second__text">
                          {{ companion.phoneNumber }}
                        </span>
                      </div>
                      <div class="col s6 padding0" style="margin-top: 4px; margin-bottom: 4px;">
                        <span class="second__text">
                          {{ companion.email }}
                        </span>
                      </div>
                    </div>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <div class="col s12 l12 xl12 m12"><br></div>
      <div class="col s12 padding0" *ngIf="!declineTicket">
        <div class="col s12 l12 m12 padding0">
          <div class="col s12 m12 l12">
            <br />
          </div>
          <div class="col s12 padding0 modal-padding-leftright" style="margin-bottom: 4px;">
            <span class="title-menu padding0 blod">
              {{ 'Reservation details' | translate}}
            </span>

          </div>
        </div>

        <div class="col s12 padding0 modal-padding-leftright">
          <br />
          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'Language' | translate | uppercase }} </span>
            <span class="sub__title">
              {{request.guestLanguage | translate }}
            </span>
            <span class="sub__nodata" *ngIf="!request.guestLanguage">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'COMPANY' | translate }} </span>
            <span class="sub__title">
              {{request.companyName}}
            </span>
            <span class="sub__nodata" *ngIf="!request.companyName">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'AFFILIATION NUMBER' | translate }} </span>
            <span class="sub__title">
              {{request.afiliateNumber}}
            </span>
            <span class="sub__nodata" *ngIf="!request.afiliateNumber">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'TYPE OF ROOM' | translate }} </span>
            <span class="sub__title"> {{ request.roomType }}</span>
            <span class="sub__nodata" *ngIf="!request.roomType">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'ROOM NUMBER' | translate }}</span>
            <span class="sub__title"> {{ request.roomNumber }}</span>
            <span class="sub__nodata" *ngIf="!request.roomNumber">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'ARRIVAL DATE' | translate }}</span>
            <span class="sub__title">
              {{request.checkInDate | date:'d MMM yyyy':'UTC' + offset : langug}}
            </span>
            <span class="sub__nodata" *ngIf="!request.checkInDate">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'DEPARTURE DATE' | translate }}</span>
            <span class="sub__title">
              {{request.checkOutDate | date:'d MMM yyyy':'UTC'+ offset : langug}}
            </span>
            <span class="sub__nodata" *ngIf="!request.checkOutDate">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'TITLE' | translate }}</span>
            <span class="sub__title">{{ request.title }}</span>
            <span class="sub__nodata" *ngIf="!request.title">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'VIP CODE' | translate }}</span>
            <span class="sub__title">{{ request.vipCode }}</span>
            <span class="sub__nodata" *ngIf="!request.vipCode">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'VIP DESCRIPTION' | translate }}</span>
            <span class="sub__title">{{ request.vipDescription }}</span>
            <span class="sub__nodata" *ngIf="!request.vipDescription">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'GROUP NAME' | translate }}</span>
            <span class="sub__title">{{ request.groupName }}</span>
            <span class="sub__nodata" *ngIf="!request.groupName">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'GROUP IDENTIFICATION' | translate }}</span>
            <span class="sub__title">{{ request.groupId }}</span>
            <span class="sub__nodata" *ngIf="!request.groupId">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'RATE CODE' | translate }}</span>
            <span class="sub__title">{{ request.rateCode }}</span>
            <span class="sub__nodata" *ngIf="!request.rateCode"> {{ 'No data' | translate }} </span>
          </div>
          <div class="col s6 padding0 marg__bot">
            <span class="title__info">{{ 'CREATION DATE' | translate }}</span>
            <span class="sub__title">{{ request.creationDate | date:'d MMM yyyy' }}</span>
            <span class="sub__nodata" *ngIf="!request.creationDate">
              {{ 'No data' | translate }}
            </span>
          </div>

          <div class="col s12 padding0 cont__tarifa" *ngIf="auth.userHasScopes(['check-in:view:rate'])">
            <span class="amount__tarifa">{{ 'Tariff Amount' | translate }}</span>
            <span class="amount__tarifa" style="float: right;">
              {{ request.rateAmount }}
            </span>
          </div>

        </div>


        <div class="col s12 l12 m12 xl12">
          <br />
        </div>
      </div>
    </div>
  </div>
</div>