import { Component, Input, OnInit, EventEmitter, Output, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Auth0Service } from "../../auth0.service";
import { TranslateService } from "@ngx-translate/core";

import { EventService } from "../../event.service";
import { SideService } from "./service";
import { TopService } from "../top/service";

@Component({
  selector: "app-guesthub-navigation-side",
  styleUrls: ["styles.scss"],
  templateUrl: "template.html",
})
export class SideNavigationComponent implements OnInit {
  @Input() showSide: any;
  @Output() outputShowSidebar: EventEmitter<Object> = new EventEmitter<Object>();

  popupVisible: boolean = false;
  guesthubContextTenats: any[] = [];
  myProperty: any;
  nProperty: any;
  loading: boolean = true;

  configModalChangePropertyConfirm: any = {
    type: 'positive',
    textCancel: 'Cancel',
    textConfirm: 'Continue',
    title: null,
    description: "You are about to change your property, make sure you save all your work before continuing",
    radio: null
  };
  listMenus: any[] = [];
  notificationStatus: any = {};
  notificationSidebar: any[] = [];

  constructor(
    private router: Router,
    public auth: Auth0Service,
    protected eventService: EventService,
    private sideService: SideService,
    public translate: TranslateService,
    protected topService: TopService
  ) { }

  ngOnChanges() { }

  ngOnInit() {
    this.loading = true;
    if (this.auth.isAuthenticated) {
      this.myProperty = this.auth.userProfile.properties.find(
        (p) => p.id === this.auth.getChosenProperty
      );
      this.guesthubContextTenats = this.auth.userProfile.properties.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      this.eventService.channels.outlets.subscribe((request) => {
        this.sideService.sidebarMenuNotifications({})
          .then(sidebarMenu => {
            this.notificationSidebar = sidebarMenu;

            this.translateListOutlets();
          });
      });

      this.eventService.channels.checkin.subscribe((request) => {
        this.sideService.sidebarMenuNotifications({})
          .then(sidebarMenu => {
            this.notificationSidebar = sidebarMenu;

            this.translateListOutlets();
          });
      });

      this.eventService.channels.outletSetting.subscribe((request) => {
        this.sideService.sidebarMenuNotifications({})
          .then(sidebarMenu => {
            this.notificationSidebar = sidebarMenu;

            this.translateListOutlets();
          });
      });

      this.eventService.channels.tasksManager.subscribe((request) => {
        this.sideService.sidebarMenuNotifications({})
          .then(sidebarMenu => {
            this.notificationSidebar = sidebarMenu;

            this.translateListOutlets();
          });
      });

      this.eventService.channels.notifications.subscribe((request) => {
        this.sideService.sidebarMenuNotifications({})
          .then(sidebarMenu => {
            this.notificationSidebar = sidebarMenu;

            this.translateListOutlets();
          });
      });

      this.sideService.sidebarMenuNotifications({})
        .then(sidebarMenu => {
          this.notificationSidebar = sidebarMenu;

          this.translateListOutlets();

          this.translate.onLangChange.subscribe((event) => {
            this.sideService.sidebarMenuNotifications({})
              .then(sidebarMenu => {
                this.notificationSidebar = sidebarMenu;
                this.translateListOutlets();
              });
          });
          this.loading = false;
        });
    }
  }

  selectMenuItem(menu) {
    this.listMenus.map(m => {
      if (menu.codename != m.codename) {
        m.items.map(i => {
          i.active = false;
          i.items.length > 0
            ? i.desplegate = false
            : undefined;
          return i;
        });
        if (m.codename != menu.codename) {
          m.active = false;
          m.items.length > 0
            ? m.desplegate = false
            : undefined;
        }
        return m;


      }
      else return m;
    });
    menu.link ? this.router.navigate([menu.link]) : undefined;
    menu.active = true;
    //if (menu.codename != 'outlet-restaurant' && menu.codename != 'outlet-service') {
    menu.items.length > 0
      ? menu.desplegate = true
      : undefined;
    //}
  }

  selectSubMenuItem(menu, item) {
    this.listMenus.map(m => {
      m.items.map(i => {
        i.active = false;
        i.items.length > 0
          ? i.desplegate = false
          : undefined;
        return i;
      });
      m.items.length > 0
        ? m.desplegate = false
        : undefined;
      m.active = false;
      return m;
    });
    menu.desplegate = true;
    menu.active = true;
    item.link ? this.router.navigate([item.link]).then(() => {
      if (!this.showSide) {
        menu.desplegate = false;
      }
    }) : undefined;
    item.active = true;
    item.items.length > 0
      ? item.desplegate = true
      : undefined;
  }

  translateListOutlets() {
    this.listMenus = [
      {
        icon: 'icon-dashboard_16px icon-menu20',
        codename: 'dashboard',
        name: 'Dashboard',
        placeholder: "Reports and request information",
        link: '/dashboard',
        active: window.location.pathname.includes('/dashboard'),
        count: 0,
        enabled: this.auth.userHasScopes(['dashboard:view']),
        desplegate: false,
        items: [
          {
            icon: null,
            codename: `dashboard-request`,
            placeholder: `Requests`,
            name: `Requests`,
            link: `/dashboard`,
            active: window.location.pathname.includes(`/dashboard`),
            count: 0,
            enabled: this.auth.userHasScopes(['dashboard:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: `dashboard-promotions`,
            placeholder: `Promotions`,
            name: `Promotions`,
            link: `/communications/promotions`,
            active: window.location.pathname.includes(`/communications/promotions`),
            count: 0,
            enabled: this.auth.userHasScopes(['dashboard:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: `dashboard-in-stay-feedback`,
            placeholder: `In Stay Feedback`,
            name: `In Stay Feedback`,
            link: `/communications/in-stay-feedback`,
            active: window.location.pathname.includes(`/communications/in-stay-feedback`),
            count: 0,
            enabled: this.auth.userHasScopes(['dashboard:view']),
            desplegate: false,
            items: []
          },
        ]
      },
      {
        icon: 'icon-tasks_16px icon-menu20',
        codename: 'tasks',
        name: 'Tasks',
        placeholder: "List of tasks",
        link: '/tasks',
        active: window.location.pathname.includes('/tasks'),
        activeSound: this.notificationSidebar
          .find(s => s.codename == 'tasks')
          ? this.notificationSidebar
            .find(s => s.codename == 'tasks')
            .activeSound
          : false,
        count: this.notificationSidebar
          .find(s => s.codename == 'tasks')
          ? this.notificationSidebar
            .find(s => s.codename == 'tasks')
            .count
          : 0,
        enabled: this.auth.userHasScopes(['tasks:view']),
        desplegate: false,
        items: []
      },
      {
        icon: 'icon-requests_16px icon-menu20',
        codename: 'request',
        name: 'Requests',
        placeholder: "List of requests",
        link: '/requests',
        active: window.location.pathname.includes('/requests'),
        activeSound: this.notificationSidebar
          .find(s => s.codename == 'requests')
          ? this.notificationSidebar
            .find(s => s.codename == 'requests')
            .activeSound
          : false,
        count: this.notificationSidebar
          .find(s => s.codename == 'requests')
          ? this.notificationSidebar
            .find(s => s.codename == 'requests')
            .count
          : 0,
        enabled: this.auth.userHasScopes(['requests:view']),
        desplegate: false,
        items: []
      },
      {
        icon: 'icon-foodandbeverage_16px icon-menu20',
        codename: 'outlet-restaurant',
        name: 'Food & Beverage',
        placeholder: "Food & Beverage",
        link: '/outlet/restaurants',
        active: window.location.pathname.includes('/outlet/restaurants') && !window.location.pathname.includes('/outlet/restaurant/'),
        activeSound: this.notificationSidebar
          .find(s => s.codename == 'restaurants')
          ? this.notificationSidebar
            .find(s => s.codename == 'restaurants')
            .activeSound
          : false,
        count: this.notificationSidebar
          .find(s => s.codename == 'restaurants')
          ? this.notificationSidebar
            .find(s => s.codename == 'restaurants')
            .count
          : 0,
        enabled: this.auth.userHasScopes(['restaurants:view']),
        desplegate: (window.location.pathname.includes('/outlet/restaurants') || window.location.pathname.includes('/outlet/restaurant/')
          && !window.location.pathname.includes('/settings/outlet')),
        items: this.notificationSidebar
          .find(s => s.codename == 'restaurants')
          ? this.notificationSidebar
            .find(s => s.codename == 'restaurants').items.map(outlet => ({
              id: outlet.id,
              icon: null,
              codename: `outlet-restaurant-${outlet.id}`,
              placeholder: `${outlet.name}`,
              name: `${outlet.name}` ? `${outlet.name}` : 'Unnamed restaurant' ,
              link: `/outlet/restaurant/${outlet.id}`,
              active: window.location.pathname.includes(`/outlet/restaurant/${outlet.id}`),
              activeSound: outlet.activeSound,
              count: outlet.count,
              enabled: true,
              desplegate: false,
              items: []
            }))
          : []
      },
      {
        icon: 'icon-service_16px icon-menu20',
        codename: 'outlet-service',
        name: 'Services',
        placeholder: "Services",
        link: '/outlet/services',
        active: window.location.pathname.includes('/outlet/services') && !window.location.pathname.includes('/outlet/service/'),
        activeSound: this.notificationSidebar
          .find(s => s.codename == 'services')
          ? this.notificationSidebar
            .find(s => s.codename == 'services')
            .activeSound
          : false,
        count: this.notificationSidebar
          .find(s => s.codename == 'services')
          ? this.notificationSidebar
            .find(s => s.codename == 'services')
            .count
          : 0,
        enabled: this.auth.userHasScopes(['services:view']),
        desplegate: (window.location.pathname.includes('/outlet/services') || window.location.pathname.includes('/outlet/service/')
          && !window.location.pathname.includes('/settings/outlet')),
        items: this.notificationSidebar
          .find(s => s.codename == 'services')
          ? this.notificationSidebar
            .find(s => s.codename == 'services').items.map(outlet => ({
              id: outlet.id,
              icon: null,
              codename: `outlet-service-${outlet.id}`,
              placeholder: `${outlet.name}`,
              name: `${outlet.name}` ? `${outlet.name}` : 'Unnamed service',
              link: `/outlet/service/${outlet.id}`,
              active: window.location.pathname.includes(`/outlet/service/${outlet.id}`),
              activeSound: outlet.activeSound,
              count: outlet.count,
              enabled: true,
              desplegate: false,
              items: []
            }))
          : []
      },
      {
        icon: 'icon-pickup_16px icon-menu20',
        codename: 'pickup',
        name: 'Pickup',
        placeholder: "Reservation status",
        link: '/pickup/monitor',
        active: window.location.pathname.includes('/pickup/monitor'),
        count: 0,
        enabled: this.auth.userHasScopes(['pickup:view']),
        desplegate: false,
        items: []
      },
      {
        icon: 'icon-guest-_item_16px icon-menu20',
        codename: 'guest-items',
        name: 'Guest Items',
        placeholder: "Guest Items",
        link: null,
        active: window.location.pathname.includes('/guest-items') && !window.location.pathname.includes('/guest-items/'),
        count: 0,
        enabled: this.auth.userHasScopes(['guest-items:packages:view'])
          || this.auth.userHasScopes(['guest-items:lost-found:view'])
          || this.auth.userHasScopes(['guest-items:lost-found:view-found']),
        desplegate: window.location.pathname.includes('/guest-items'),
        items: [
          {
            icon: 'icon-lost_16px icon-menu20',
            codename: `guest-items-lost-found`,
            placeholder: `Lost & Found`,
            name: `Lost & Found`,
            link: `/guest-items/lost-found`,
            active: window.location.pathname.includes(`/guest-items/lost-found`),
            count: 0,
            enabled: this.auth.userHasScopes(['guest-items:lost-found:view']) || this.auth.userHasScopes(['guest-items:lost-found:view-found']),
            desplegate: false,
            items: []
          },
          // {
          //   icon: 'icon-package_trackin_16px icon-menu20',
          //   codename: `guest-items-packages-new`,
          //   placeholder: `Package tracking`,
          //   name: `Package new`,
          //   link: `/guest-items/packages-new`,
          //   active: window.location.pathname.includes(`/guest-items/packages-new`),
          //   count: 0,
          //   enabled: window.location.origin.includes('norelian.com'),
          //   desplegate: false,
          //   items: []
          // },
          {
            icon: "icon-package_trackin_16px icon-menu20",
            codename: 'guest-items-package',
            name: 'Package',
            placeholder: "Package tracking",
            link: '/guest-items/packages',
            active: window.location.pathname.includes('/guest-items/packages'),
            count: 0,
            enabled: this.auth.userHasScopes(['guest-items:packages:view']),
            desplegate: false,
            items: []
          },
        ]
      },
      {
        icon: 'icon-guests_16px icon-menu20',
        codename: 'guests',
        name: 'Guests',
        placeholder: "List of guests",
        link: '/guests',
        active: window.location.pathname.includes('/guests'),
        activeSound: this.notificationSidebar
          .find(s => s.codename == 'check-in')
          ? this.notificationSidebar
            .find(s => s.codename == 'check-in')
            .activeSound
          : false,
        count: this.notificationSidebar
          .find(s => s.codename == 'check-in')
          ? this.notificationSidebar
            .find(s => s.codename == 'check-in')
            .count
          : 0,
        enabled: this.auth.userHasScopes(['guest:view']) || this.auth.userHasScopes(['check-in:view']),
        desplegate: window.location.pathname.includes('/guests'),
        items: [
          {
            icon: null,
            codename: `guests-check-in`,
            placeholder: ` Check In`,
            name: ` Check In`,
            link: `/guests/check-in`,
            active: window.location.pathname.includes(`/guests/check-in`),
            activeSound: this.notificationSidebar
              .find(s => s.codename == 'check-in')
              ? this.notificationSidebar
                .find(s => s.codename == 'check-in')
                .activeSound
              : false,
            count: this.notificationSidebar
              .find(s => s.codename == 'check-in')
              ? this.notificationSidebar
                .find(s => s.codename == 'check-in')
                .count
              : 0,
            enabled: this.auth.userHasScopes(['check-in:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: `guests-list`,
            placeholder: `Guests list`,
            name: `List`,
            link: `/guests`,
            active: window.location.pathname.includes(`/guests/check-in`)
              && !window.location.pathname.includes(`/guests/check-in`),
            count: 0,
            enabled: this.auth.userHasScopes(['guest:view']),
            desplegate: false,
            items: []
          }
        ]
      },
      {
        icon: 'icon-communication_16px icon-menu20',
        codename: 'communications',
        name: 'Communications',
        placeholder: "Overall communication",
        link: null,
        active: false,
        count: 0,
        enabled: this.auth.userHasScopes(['communications:email-sms:view'])
          || this.auth.userHasScopes(['communications:survey:view'])
          || this.auth.userHasScopes(['communications:guest-app:view'])
          || this.auth.userHasScopes(['communications:check-in:view']),
        desplegate: window.location.pathname.includes('/settings/communications')
          || window.location.pathname.includes('/settings/guest-app/automatic-emails/statistics')
          || window.location.pathname.includes('/communications/guestapp'),
        items: [
          {
            icon: null,
            codename: 'communications-survey',
            name: 'Survey',
            placeholder: "Overall communication survey",
            link: '/settings/communications/list',
            active: window.location.pathname.includes('/settings/communications/list'),
            count: 0,
            enabled: this.auth.userHasScopes(['communications:survey:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: 'communications-automatic-emails',
            name: 'Emails & SMS',
            placeholder: "Overall communication automatic-emails",
            link: '/settings/communications/automatic-emails/list',
            active: window.location.pathname.includes('/settings/communications/automatic-emails/list'),
            count: 0,
            enabled: this.auth.userHasScopes(['communications:email-sms:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: 'communications-guestapp',
            name: 'GuestApp',
            placeholder: "Invitation email statistics",
            link: '/communications/guestapp',
            active: window.location.pathname.includes('/communications/guestapp'),
            count: 0,
            enabled: this.auth.userHasScopes(['communications:guest-app:view']),
            desplegate: false,
            items: []
          },
          {
            icon: null,
            codename: 'communications-check-in',
            name: 'Check in indicators',
            placeholder: "Invitation email statistics",
            link: '/communications/check-in',
            active: window.location.pathname.includes('/communications/check-in'),
            count: 0,
            enabled: this.auth.userHasScopes(['communications:check-in:view']),
            desplegate: false,
            items: []
          }
        ]
      },
      {
        icon: 'icon-settings_16px icon-menu20',
        codename: 'settings',
        name: 'Settings',
        placeholder: "Settings configuration",
        link: null,
        active: false,
        count: 0,
        enabled: this.auth.userHasScopes(['settings:features:view'])
          || this.auth.userHasScopes(['settings:quick-setup:view']),
        desplegate: window.location.pathname.includes('/settings'),
        items: [
          {
            icon: 'icon-quick_setup_16px icon-menu20',
            codename: 'settings-setup',
            name: 'Setup',
            placeholder: "Quick configuration",
            link: '/settings/quicksetup',
            active: window.location.pathname.includes('/settings/quicksetup'),
            count: 0,
            enabled: this.auth.userHasScopes(['settings:quick-setup:view']),
            desplegate: false,
            items: []
          },
          {
            icon: 'icon-feature_16px icon-menu20',
            codename: 'settings-features',
            name: 'Features',
            placeholder: "List of features",
            link: '/settings/features',
            active: window.location.pathname.includes('/settings/features'),
            count: 0,
            enabled: this.auth.userHasScopes(['settings:features:view']),
            desplegate: false,
            items: []
          }
        ]
      }
    ];
  }

  showCangeTenat() {
    this.outputShowSidebar.emit(true);
  }

  showInfo(property) {
    if (property !== 0 && this.auth.getChosenProperty != property) {
      this.nProperty = property;
      this.popupVisible = true;
    } else {
      this.nProperty = null;
      this.popupVisible = false;
    }
  }
  chooseProperty() {
    if (this.nProperty) {
      this.auth
        .changeProperty(this.auth.userProfile.properties.find(
          (p) => p.id === this.nProperty
        ))
        .then(() => window.location.href = "/");
    }
  }
}
