import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { MaterialModule } from "../../../material.module";
import { TasksManagerTimerModule } from "../../timer/module";
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TasksManagerService } from "../../service";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialModule,
    TasksManagerTimerModule
  ],
  exports: [MyComponent],
  providers: [TasksManagerService]
})
export class TasksManagerPreviewDetailModule { }
