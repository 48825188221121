<div class="modal-content text-inicial white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob" style="margin-bottom: 22px;">
        <i class="material-icons left pointer color-base-two" (click)="goBack()">arrow_back</i>
    </div>

    <div *ngIf="!loading">
        <div class="col s12 m12 l12 xl12 padding40">
            <div class="modal-padding-leftright col s12 l12 xl12 padding0">
                <span class="color-base font24 semiblod">
                    {{ 'Validating guest request' | translate }}
                </span>
            </div>

            <div class="modal-padding-leftright col s12 l12 xl12 padding0 margin-top25">
                <span class="color-base-two font12 semiblod" style="display: block;">
                    {{ 'Guest request' | translate | uppercase}}
                </span>

                <span class="color-base font16" style="display: block;">
                    "{{ request?.requestText }}"
                </span>
            </div>


            <div class="modal-padding-leftright scroll-hei">
                <div class="col s12 l12 xl12 padding0 margin-top25-bottom10">
                    <span style="display: block;">
                        <div class="input-field col s12 padding0 margin0">
                            <label class="left0 font16 color-base-two title__validate
                                    margin-top-25">
                                {{ 'What does the guest need?' | translate }}
                            </label>
                            <input id="whats" type="text" class="validate" autocomplete="off" ngcontrol
                                [(ngModel)]="searchTextReal" (keyup)="onKeySearch($event)"
                                placeholder="{{ request.requestText }}">
                        </div>
                    </span>

                    <div class="loadingdiv col s12 l12 1m2 xl12  center" *ngIf="loadingSearching">
                        <p>
                            <br>
                        </p>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-green-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 100%;" *ngIf="!loadingSearching">

                    <div class="col s10 l10 xl10 padding0"
                        *ngIf="newRequest.requests.length == 0 && whatResults?.requests.length === 0">
                        <ng-container *ngIf="searchTextReal.length > 0">
                            {{ 'Sorry, there are no matches, please suggest a new subcategory.' | translate }}
                        </ng-container>
                        <ng-container class="" *ngIf="searchTextReal.length === 0">
                            {{ 'No matches were found, please enter your request manually.' | translate }}
                        </ng-container>
                    </div>

                    <div class="col s10 l10 xl10 padding0"
                        *ngIf="newRequest.requests.length > 0 || whatResults?.requests.length > 0 || whatResults?.suggestedes.length > 0">
                        <span class="color-base font16 semiblod
                            margin-bottom15 margin-top25 " style="display: block;">
                            {{ 'Select the requests' | translate }}
                        </span>

                        <mat-form-field class="example-full-width col s12 padding0"
                            *ngIf="newRequest?.requests?.length">
                            <mat-chip-list #chipList>
                                <div *ngFor="let req of newRequest?.requests;
                                    let i = index" class="margin-bottom10
                                    margin-rigth10 col s10 l8 xl8 padding0
                                    margin-left0 ">
                                    <mat-chip class="chip-guest-edit" selectable="true" removable="true"
                                        (removed)="removeReq(i)">
                                        <span class="textitemsum color-base
                                            regular cut-text">
                                            {{ req?.department }} /
                                            {{ req?.category ? req?.category + " / " : "" }}
                                            {{ req?.name }}
                                        </span>
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <br />
                                    <i class="material-icons pointer time-icon
                                        margin-rigth10">access_time</i>
                                    <!-- <span class="font16 color-base margin-left8-rigth0"> -->
                                    <!-- {{ "When?" | translate }}: -->
                                    <!-- </span> -->

                                    <span class="font16 color-base
                                        margin-rigth10" *ngIf="req?.time">
                                        {{ req?.time | date:'d MMM, yyyy - h:mm a':'TZ' }}
                                    </span>
                                    <span class="font16 color-base" *ngIf="!req?.time">
                                        {{ "Run now" | translate |titlecase }}
                                    </span>
                                    <span [owlDateTimeTrigger]="dt7"
                                        class="font16 green-name margin-left8-rigth0 pointer">
                                        {{ "Edit" | translate }}
                                    </span>
                                    <input hidden [owlDateTime]="dt7" placeholder="{{ 'Now' | translate }}"
                                        [(ngModel)]="req.time" [min]="minDate" style="margin-top: -10px;">

                                    <owl-date-time [hour12Timer]="'true'" class="position-right"
                                        (afterPickerClosed)="onChangeDate(req.time)" #dt7>
                                    </owl-date-time>
                                </div>
                            </mat-chip-list>
                        </mat-form-field>

                        <mat-form-field class="example-full-width col s12 padding0"
                            *ngIf="whatResults?.requests?.length">
                            <mat-chip-list #chipList>


                                <mat-chip *ngFor="let req of whatResults?.requests; let i = index"
                                    class="itememail mat-chip-custom" selectable="true" removable="false"
                                    style="display: block;" (click)="selectRequest(i,
                                    req);">
                                    <span class="textitemsum green-name regular
                                        cut-text">
                                        {{ req?.department }} /
                                        <span class="textitemsum color-base
                                            cut-text regular">
                                            {{ req?.category }} / {{
                                            req?.name }}
                                        </span>
                                    </span>
                                </mat-chip>

                            </mat-chip-list>
                        </mat-form-field>

                        <mat-form-field class="example-full-width col s12 padding0"
                            *ngIf="whatResults?.suggestedes?.length">
                            <mat-chip-list #chipList>

                                <mat-chip *ngFor="let req of whatResults?.suggestedes; let i = index"
                                    class="itememail mat-chip-custom" selectable="true" removable="false"
                                    style="display: block;" (click)="selectRequestSuggested(i,req);itemSelect = i;">
                                    <span class="textitemsum green-name regular">
                                        {{ req?.department }} /
                                        {{ 'Suggested' | translate }} /
                                        <span class="textitemsum color-base regular">
                                            {{ req?.name }}
                                        </span>
                                    </span>
                                </mat-chip>

                            </mat-chip-list>
                        </mat-form-field>
                    </div>

                    <div class="col s12 l12 xl12 padding0 padding-top15" *ngIf="goSearchInput">
                        <span class="color-base font16" style="display: block;" *ngIf="!activeAddSuggesteed">
                            {{ "Cant find the request?" | translate }}
                        </span>

                        <span class="color-base col s12 xl12 m12 l12 padding0" style="display: block;"
                            *ngIf="!activeAddSuggesteed">
                            <a (click)="activeAddSuggesteed = true" class="font16 green-name">
                                <i class="material-icons  pointer green-name relative-top5">add</i>
                                {{ 'Suggest a request' | translate }}
                            </a>
                        </span>

                        <span class="color-base semiblod font16" style="display: block;" *ngIf="activeAddSuggesteed">
                            {{ 'Suggested request' | translate }}
                        </span>

                        <span style="display: block;" class="padding-top15" *ngIf="activeAddSuggesteed">
                            <div class="input-field col s12 padding0
                                margin-bottom10">
                                <label class="left0 color-base-two font16 margin-top-25 title__validate">
                                    {{ 'What does the guest need?' | translate }}
                                </label>
                                <input id="suggested" type="text" class="validate" autocomplete="off" ngcontrol
                                    [(ngModel)]="suggestedText">
                            </div>
                            <div class="col s12 l12 m12 xl12 padding0">
                                <label class="left0 color-base-two font16 margin-top-10-relative title__validate">
                                    {{ 'Select department' | translate }}
                                </label>


                                <input class="classinput margin0" #eventsInput type="text" matInput
                                    [formControl]="eventsCtrl" [matAutocomplete]="auto"
                                    (keyup)="searchResultDepartments($event)">
                                <mat-autocomplete class="test-class" classlist="test-class"
                                    (optionSelected)="updateDepartmentSuggested($event)" #auto="matAutocomplete">
                                    <mat-option class="loading" *ngIf="loadingDepartmentSuggested">
                                        <mat-spinner style="left: 50%; transform: translateX(-50%);"
                                            diameter="35"></mat-spinner>
                                    </mat-option>
                                    <ng-container *ngIf="!loadingDepartmentSuggested && departments.length > 0">
                                        <mat-option *ngFor="let item of departments" [value]="item">
                                            {{ item.name }}
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngIf="!loadingDepartmentSuggested && departments.length == 0">
                                        <mat-option disabled="true">
                                            {{ 'There are no results for the search criteria used' | translate }}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>

                            <div class="right-align">
                                <a (click)="cancelSuggested()" class=" font16 color-base-two semiblod
                                    btncancel margin-rigth24 btn-search
                                    relative-top3 margin-rigth10" container="body" placement="top">
                                    {{ "Cancel" | translate }}
                                </a>
                                <button (click)="saveSuggested()" class="btn btn-falt-ant btn-small stylebtn
                                    font16 semiblod " container="body" placement="top"
                                    [disabled]="!validButtonAddsuggested()">
                                    {{ "Save suggested" | translate }}
                                </button>
                            </div>
                            <div class="col s12 l12 xl12">
                                <br>
                            </div>
                            <div class="clearfix"></div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col s12 l12" *ngIf="!activeAddSuggesteed &&
                    newRequest?.requests.length == 0"><br></div>

            <div class="nopadding mygavete-buttons">
                <div class="right-align">
                    <button (click)="goBack()" class="btn btn-falt-ant btn-small button-cancel" container="body"
                        placement="top">
                        {{ "Cancel" | translate }}
                    </button>
                    <button (click)="save()" [disabled]="newRequest?.requests.length === 0"
                        class="btn btn-falt-ant btn-small button-save" container="body" placement="top">
                        {{ "Validate" | translate }}
                    </button>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>

    <div class="loadingdiv col s12 m12 xl12 center " *ngIf="loading">
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>