import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { TasksManagerService } from "../service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MaterialModule } from "../../material.module";

import { TasksManagerPreviewDetailModule } from "./detail/module";
import { TasksManagerPreviewHistoryModule } from "./history/module";
import { TasksManagerPreviewCommentsModule } from "./comments/module";
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ProgramingRepeatModule } from "../../components/programing-repeat/module";
import { ComponentModalPreviewImgModule } from "../../components/preview-Img/module";
import { ComponentModalConfirmedModule } from "../../components/modal-confirmed/module";
import { ComponentModalTakePictureModule } from "../../components/take-picture/module";
import { TasksPreviewUpdatedModule } from "./update/module";

@NgModule({
  declarations: [
    MyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    Ng2SearchPipeModule,
    TasksManagerPreviewCommentsModule,
    TasksManagerPreviewHistoryModule,
    TasksManagerPreviewDetailModule,
    ProgramingRepeatModule,
    ComponentModalPreviewImgModule,
    ComponentModalTakePictureModule,
    TasksPreviewUpdatedModule,
    ComponentModalConfirmedModule
  ],
  exports: [
    MyComponent
  ],
  providers: [TasksManagerService],
})
export class TasksManagerPreviewModule { }
