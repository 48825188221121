import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { OutletsCommentsComponent } from "./component";
import { OutletsCommentsService } from "./service";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from "../../../components/directives/module";

@NgModule({
  declarations: [OutletsCommentsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    Ng2SearchPipeModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [OutletsCommentsComponent],
  providers: [OutletsCommentsService],
})
export class OutletsCommentsModule {}
