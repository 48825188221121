import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { Auth0Service } from "../../../auth0.service";

@Injectable()
export class OutletsCommentsService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) {}

  create(input, data) {
    return this.http
      .post<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            input.outleType == "restaurants"
              ? "restaurant"
              : input.outleType == "services"
              ? "service"
              : input.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(input.id)
          .concat("/comment")
          .concat(
            `${
              input.outletId
                ? "?outletId=" + encodeURIComponent(input.outletId)
                : ""
            }`
          )}`,
        {
          body: data.body,
          userId:
            this.auth && this.auth.userProfile && this.auth.userProfile.id
              ? this.auth.userProfile.id
              : undefined,
          guestName: undefined,
          type: data.type,
          hasImage: data.hasImage,
        }
      )
      .toPromise();
  }

  markToRead(input, type: String = "internal") {
    return this.http
      .put<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            input.outleType == "restaurants"
              ? "restaurant"
              : input.outleType == "services"
              ? "service"
              : input.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(input.id)
          .concat("/comment")
          .concat(
            `${
              input.outletId
                ? "?outletId=" + encodeURIComponent(input.outletId)
                : ""
            }`
          )}`,
        {
          userId:
            this.auth && this.auth.userProfile && this.auth.userProfile.id
              ? this.auth.userProfile.id
              : undefined,
          type: type,
        }
      )
      .toPromise();
  }

  uploadImgs(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(
        environment.BACKENDFILES.DOMAIN
      )}/images/${this.auth.getChosenProperty}`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
}
