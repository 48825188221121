const config = {
  production: window.location.origin.includes("guesthub.io") ? true : false,
  envName: "dev",
  auth0: {
    configuration: {
      domain: "auth0.norelian.com",
      clientID: "96YHrGR4uJx5RHaGkt7yPfwOCEMvBVhI",
      audience: "https://guesthub-dev.norelian.com",
      responseType: "token id_token",
      redirectUri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port !== "" ? ":" + window.location.port : ""
      }/callback`,
      scope: "openid profile email app_metadata user_metadata",
     },
  },

  ALGOLIA_APP_NAME: "MEOT7QAEWE",
  ALGOLIA_SEARCH_KEY: "9f8702ec75fc418c00b71773a0f5e6b1",
  ALGOLIA_REQUEST_TYPE_INDEX_NAME: "request_types",
  PROTOCOL: window.location.protocol + "//",
  FRONTENDCHAT: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("guesthub.io")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "chat."
      : "chat.",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:4208"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":4208"
      : window.location.hostname.includes("guesthub.io")
      ? window.location.hostname.replace("app", "chat")
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  FRONTENDGUESTS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("guesthub.io")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "guests."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:4200"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":4200"
      : window.location.hostname.includes("guesthub.io")
      ? window.location.hostname.replace("app", "guests")
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  app: {
    web: {
      url: window.location.origin,
      modules: {
        request: {
          list: {
            url: "/requests",
          },
        },
      },
    },
  },

  FRONTENDRESERVATIONS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? window.location.origin.includes("qa")
        ? "reservations-web."
        : "reservations."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:4210"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":4210"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  FRONTENDSUBSCRIPTION: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "subscription."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:4205"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":4205"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  FRONTENDOAUTH: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "authorization."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:4203"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":4203"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDOSUBSCRIPTION: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "subscription-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3004"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3004",
  },

  BACKENDOCOMMUNICATIONS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "communications-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3010"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3010",
  },

  BACKENDORULESMANAGER: {
    url: window.location.hostname.includes("localhost")
      ? window.location.protocol + "//localhost:3007"
      : window.location.hostname.includes("guesthub.io")
      ? window.location.hostname.includes("staging")
        ? window.location.hostname.replace("app-web", "https://rules-api")
        : window.location.hostname.replace("app", "https://rules-api")
      : window.location.hostname.includes("norelian.com")
      ? "https://rules-api." + window.location.hostname
      : window.location.protocol + "//" + window.location.hostname + ":3007",
  },

  BACKENDORESERVATIONS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "reservations-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3009"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3009"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDOGUESTAPP: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "guestapp-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3052"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3052"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDOUTLETS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "outlets-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3030"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3030"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDCHECKIN: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "check-in-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3050"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3050"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDTASKSMANAGER: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "tasks-manager-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3055"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3055"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDCOMMUNICATIONS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "communications-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3010"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3010"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDGUESTITEMS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "guest-items-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3051"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3051"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDREQUESTS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "requests-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3031"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3031"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDPROPERTY: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "property-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3060"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3060"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDRULES: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "rules-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3007"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3007"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDDASHBOARD: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "dashboard-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3032"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3032"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname,
  },

  BACKENDTASKS: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "tasks-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3001"
      : window.location.hostname.includes(".local")
      ? window.location.hostname + ":3001"
      : window.location.hostname.includes("norelian.com")
      ? "tasks-api." + window.location.hostname
      : window.location.hostname,
  },

  BACKENDOAUTH: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "authorization-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3002"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3002",
  },

  BACKENDAPP: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "app-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3000"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3000",
  },
  BACKENDPICKUP: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "pickup-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3040"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3040",
  },
  BACKENDFILES: {
    HOST: window.location.hostname.includes("localhost")
      ? ""
      : window.location.hostname.includes(".local")
      ? ""
      : window.location.hostname.includes("norelian.com")
      ? "files-api."
      : "",
    DOMAIN: window.location.hostname.includes("localhost")
      ? "localhost:3013"
      : window.location.hostname.includes("norelian.com")
      ? window.location.hostname
      : window.location.hostname + ":3013",
  },
  google: {
    maps: {
      key: "AIzaSyDPPSEh38cY2ReagB0kou-1Lc6xdJeRSko",
    },
  },

  notifications: {
    push: {
      firebase: {
        config: {
          apiKey: "AIzaSyD8fmzJHHLyRdY2nrKTDiXCqCFXGuClZv4",

          authDomain: "guesthub-d9e2e.firebaseapp.com",

          databaseURL: "https://guesthub-d9e2e.firebaseio.com",

          projectId: "guesthub-d9e2e",

          storageBucket: "guesthub-d9e2e.appspot.com",

          messagingSenderId: "888213217166",
        },
      },
    },
  },

  integrations: {
    cloudbeds: {
      api: {
        url: "https://hotels.cloudbeds.com/api/v1.1",
      },

      token: {
        url: "",

        config: {
          client_id: "guesthub_RKnPdVG8rQRCHcj40mpCAeZy",

          response_type: "code",

          scopes: "read:guest+read:reservation+read:user",
        },
      },
    },
  },

  serviceWorker: {
    file: "service-worker.js",
  },

  backend: {
    url:
      window.location.protocol +
      "//".concat(
        window.location.hostname.includes("localhost")
          ? "localhost:3000"
          : window.location.hostname.includes("norelian.com")
          ? "app-api." + window.location.hostname
          : window.location.hostname + ":3000"
      ),
  },
  events: {
    api:
      window.location.protocol +
      "//".concat(
        window.location.hostname.includes("localhost")
          ? "localhost:3020"
          : window.location.hostname.includes("norelian.com")
          ? "events-api." + window.location.hostname
          : window.location.hostname + ":3020"
      ),
  },
};

export const environment = config;
