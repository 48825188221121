import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { RequestsManagerService } from "../service";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../auth0.service";
import { TopService } from "../../navigation/top/service";
import { EventsService } from "../../events.service";

@Component({
  selector: "app-requests-manager-preview",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, OnDestroy {
  @Output() exitOutput = new EventEmitter<boolean>();
  @Input() item: any;
  @Input() actionBeforeOpen: String;
  request: any;
  loading = true;
  @Input() tabSelected = 'detail';
  subscribeRequests: any;
  otherAction: any = null;
  countSumate = 0;
  modalDeleteComment: any = null;
  modalConfirmDeclined: any = null;
  subscribeDetailInterval: NodeJS.Timer;
  originalData: any;
  modalPreviewImg = null;
  users: any[] = [];
  showDropdownMoreOptions: any = [];
  disabledButton: boolean;
  modalConfirmUpdateAll: boolean = false;
  disabledButtonUpdateAll: boolean = true;

  configModalConfirmUpdateAll: any = {
    type: 'positive',
    textCancel: 'Cancel',
    textConfirm: 'Save',
    title: 'Do you want to save the changes?',
    description: "The changes will be reflected in the current application.",
    radio: null,
    list: null,
    checkboxes: null,
    disabledButton: false
  };

  configModalDeleteComment: any = {
    type: 'negative',
    textCancel: 'Cancel',
    textConfirm: 'Delete',
    title: null,
    description: "Are you sure you want to delete this message?",
    radio: null
  };

  configModalConfirmDeclined: any = {
    type: 'negative',
    textCancel: 'Cancel',
    textConfirm: 'Discard',
    title: null,
    description: "¿Estas seguro que deseas descartar esta solicitud?",
    radio: null
  };

  constructor(
    public router: Router,
    private service: RequestsManagerService,
    public translate: TranslateService,
    @Inject("EventsService") private eventsService: EventsService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
    private topService: TopService
  ) { }

  ngOnDestroy() {
    if (this.subscribeRequests) this.subscribeRequests.unsubscribe();
    if (this.subscribeRequests) this.subscribeRequests = undefined;
    clearInterval(this.subscribeDetailInterval);
    this.topService.activeSound$.emit(true);
  }

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.service.getById({ id: this.item.id }),
      this.service.listAllUsers()
    ]).then(([request, users]) => {
      // if (request.state == 'validate') {
      this.topService.activeSound$.emit(false);
      // }

      this.users = users
        ? users.map((user) => ({
          query:
            user.firstName +
            " " +
            user.lastName +
            " " +
            user.email +
            " " +
            user.phone,
          id: user.id,
          name: user.firstName + " " + user.lastName,
          firstName: user.firstName,
          lastName: user.lastName,
          department: user.department.names.find(name => name.language.code.toLowerCase() === JSON.parse(localStorage.getItem('lang')).lang)?.name,
          email: user.email,
          picture: user.picture,
        }))
        : [];
      this.request = request;
      this.originalData = Object.assign({}, request);
      if (this.actionBeforeOpen == 'cancelled') {
        this.modalConfirmDeclined = true;
      } else if (this.actionBeforeOpen == 'returned') {
        this.otherAction = 'finished-returned';
      } else {
        if (this.countComments() > 0) {
          this.tabSelected = 'comments';
        }
      }
      this.loading = false;

      this.subscribeRequests = this.eventsService.channels.request.subscribe(request => {
        if (request.id == this.item.id) {
          if (request.action == 'deleted') {
            this.closeModal();
          } else {
            this.request = request;
            this.originalData = Object.assign({}, request);
          }
        }
      });

      this.subscribeDetailInterval = setInterval(() => {
        ++this.countSumate;
      }, 1000);
    });
  }

  closeModal() {
    this.exitOutput.emit(true);
  }

  countComments(): number {
    return this.request.comments.filter(c => !c.read && c.authorId != this.auth.userProfile.id).length
  }

  saveUpdateAll() {
    this.configModalConfirmUpdateAll.disabledButton = true;
    this.service.updateGeneral(this.request.id, this.configModalConfirmUpdateAll.config)
      .then(() => {
        delete this.configModalConfirmUpdateAll.config;
        this.configModalConfirmUpdateAll.disabledButton = false;
        this.modalConfirmUpdateAll = false;
        this.otherAction = null;

      }).catch(err => {
        console.error(err);
        this.disabledButtonUpdateAll = false;
        this.configModalConfirmUpdateAll.disabledButton = false;
      });
  }

  otherActionEvent(e) {
    if (e.type == 'all-update') {
      this.modalConfirmUpdateAll = true;
      this.configModalConfirmUpdateAll.config = e.value;
    } else if (e.type == 'cancelled') {
      this.modalConfirmDeclined = true;
    } else if (e.type == 'returned') {
      this.otherAction = 'finished-returned';
    } else {
      this.otherAction = e;
    }
  }

  updateStatusDiscard() {
    this.service
      .updateStatus({ id: this.request.id }, {
        codename: 'cancelled'
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Request has been successfully cancelled"),
          "✓"
        );
        this.closeModal();
      });
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  openReturnedConfirm(input) {
    this.service.updateStatus({
      id: this.request.id
    }, {
      codename: input.codename,
      reason: input.comment
    }).then(() => {
      this.openSnackBar(
        this.translate.instant("Request has been successfully finished"),
        "✓"
      );
      this.otherAction = null;
    }).catch(err => {
      console.error(err);
      this.disabledButton = false;
      this.openSnackBar(
        this.translate.instant('UPS: One error ocurred'),
        '✖'
      );
    });
  }

  deleteComment(comment) {
    this.configModalDeleteComment.disabledButton = true;
    this.service
      .deleteComment({
        requestId: this.request.id,
        id: comment._id,
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Comment deleted"),
          "✓"
        );
        this.modalDeleteComment = null;
        this.configModalDeleteComment.disabledButton = false;
      }).catch(err => {
        console.error(err);
        this.configModalDeleteComment.disabledButton = false;
      });
  }

}
