import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { CheckInService } from "../service";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../../auth0.service";
import { TopService } from "../../../navigation/top/service";
import { EventsService } from "../../../events.service";

@Component({
  selector: "app-guests-check-in-preview",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, OnDestroy {
  @Output() backEventIntoRequest = new EventEmitter<boolean>();
  @Output() exitOutput = new EventEmitter<boolean>();
  @Input() item: any;
  @Input() otherDetail = false;
  @Input() actionBeforeOpen: String;
  request: any;
  loading = true;
  @Input() tabSelected = 'detail';
  subscribeRequests: any;
  otherAction: any = null;
  countSumate = 0;
  disabledButtonModal = false;
  modalDeleteComment: any = null;
  subscribeDetailInterval: NodeJS.Timer;
  listDeclineObj: String[] = [];
  selectedRoomNumber: String = null;
  listRooms: any[] = [];
  searchRoomNumber = "";
  companionSelected: any = null;
  configModalDeleteComment = {
    type: 'negative',
    textCancel: 'Cancel',
    textConfirm: 'Delete',
    title: null,
    description: "Are you sure you want to delete this message?",
    radio: null
  };

  constructor(
    public router: Router,
    private service: CheckInService,
    public translate: TranslateService,
    @Inject("EventsService") private eventsService: EventsService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
    private topService: TopService
  ) { }

  ngOnDestroy() {
    if (this.subscribeRequests) this.subscribeRequests.unsubscribe();
    if (this.subscribeRequests) this.subscribeRequests = undefined;
    clearInterval(this.subscribeDetailInterval);
    this.topService.activeSound$.emit(true);
  }

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.service.getById({ id: this.item.id }),
      this.service.getAllRooms()
    ]).then(([request, listRooms]) => {
      if (request.state == 'validate') {
        this.topService.activeSound$.emit(false);
      }

      this.listRooms = listRooms;
      this.request = request;
      if (this.actionBeforeOpen == 'open+confirmed') {
        this.otherAction = 'confirmed';
      } else {
        if (this.countComments() > 0) {
          this.tabSelected = 'comments';
        }
      }
      this.loading = false;

      this.subscribeRequests = this.eventsService.channels.checkin.subscribe(request => {
        if (request.id == this.item.id) {
          this.request = request;
        }
      });

      this.subscribeDetailInterval = setInterval(() => {
        ++this.countSumate;
      }, 1000);
    });
  }

  closeModal() {
    this.exitOutput.emit(true);
  }

  countComments(): number {
    return this.request.comments.filter(c => !c.read && c.authorId != this.auth.userProfile.id).length
  }

  otherActionEvent(e) {
    if (e == 'back') {
      this.backEventIntoRequest.emit(true);
    } else {
      this.otherAction = e;
    }
  }

  declineTicket() {
    if (this.request.otherStatuses.find(s => s.codename === 'declined')) {
      this.request.statusName = this.request.otherStatuses.find(s => s.codename === 'declined').name;
      this.request.statusCodename = this.request.otherStatuses.find(s => s.codename === 'declined').codename;
      this.request.statusColorHex = this.request.otherStatuses.find(s => s.codename === 'declined').colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(this.request.otherStatuses.find(s => s.codename === 'declined').codename), 1);
      this.service.update({
        id: this.request.id
      }, {
        codename: this.request.statusCodename,
        listDeclineObj: this.listDeclineObj
      }).then(() => {
        this.otherAction = null;
        this.openSnackBar(this.translate.instant("The ticket has been rejected"), '✓')
      });
    }
  }

  confirmOrder() {
    if (this.request.otherStatuses.find(s => s.codename === 'confirmed')) {
      this.request.statusName = this.request.otherStatuses.find(s => s.codename === 'confirmed').name;
      this.request.statusCodename = this.request.otherStatuses.find(s => s.codename === 'confirmed').codename;
      this.request.statusColorHex = this.request.otherStatuses.find(s => s.codename === 'confirmed').colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(this.request.otherStatuses.find(s => s.codename === 'confirmed').codename), 1);
      this.service.update({
        id: this.request.id
      }, {
        codename: this.request.statusCodename,
        roomNumber: this.selectedRoomNumber
      }).then(() => {
        this.selectedRoomNumber = null;
        this.searchRoomNumber = "";
        this.otherAction = null;
        this.openSnackBar(this.translate.instant("The ticket has been confirmed"), '✓')
      });
    }
  }

  selectRoomNumber(e) {
    this.selectedRoomNumber = e;
    this.searchRoomNumber = e.room;
    document.getElementById("sender").blur();
  }

  exportPDF() {
    window.open(this.service.exportDetailPDF(this.request), "_blank");
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  deleteComment(comment) {
    this.disabledButtonModal = true;
    this.service
      .deleteComment({
        requestId: this.request.id,
        id: comment._id,
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Comment deleted"),
          "✓"
        );
        this.modalDeleteComment = null;
        this.disabledButtonModal = false;
      }).catch(err => {
        console.error(err);
        this.disabledButtonModal = false;
      });
  }

}
