import { Component, OnInit, OnChanges, Input, SimpleChanges, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { RequestsManagerService } from "../../service";
import { Auth0Service } from "../../../auth0.service";
import { HttpEventType } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser'
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-requests-manager-preview-comments",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('uploader') uploaderFiles: ElementRef;
  @ViewChild('textAreaChat') textAreaChat: ElementRef;
  @Output() eventDeleteComment = new EventEmitter<any>();
  @Output() onImgClick = new EventEmitter();
  @Input() request: any;
  @Input() comments: Array<any> = [];
  @Input() users: Array<any> = [];

  searchOpen = false;
  searchTxt = "";
  newComent = "";
  typeComments = 'guest';
  commentsFilter: any[] = [];
  disabledButtonUpload = false;
  disabledButton = true;
  uploadOption = false;
  images: any[] = [];
  loadingImg: boolean;
  inputLoading: boolean;
  usersFiltered: any
  taggedUsers: Array<any> = [];
  currentTags: Array<string> = [];
  hasJustSelectedItem: boolean = true;
  constructor(
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private service: RequestsManagerService,
    private auth: Auth0Service,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
  }

  ngOnInit() {
    if (this.request.isInternal) {
      this.typeComments = 'internal';
    }

    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        id: this.request.id
      }, this.typeComments);
    }

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);

    if (this.countComments('internal') > 0) {
      this.filterComments('internal');
    }
    if (this.countComments('guest') > 0) {
      this.filterComments('guest');
    }
    this.usersFiltered = this.users.map(user => ({ name: user.name, picture: user.picture, id: user.id, department: user.department }))
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  ngAfterViewInit() {
    this.textAreaChat.nativeElement.focus();
  }

  searchInComments(value: string) {
    if (value.trim().length > 0) {
      this.commentsFilter = this.comments.filter(c => c.type == this.typeComments).filter(c =>
        c.body.toLowerCase().includes(value.toLowerCase())
      ).map(c => Object.assign({}, c, {
        body: this.sanitizer.bypassSecurityTrustHtml(c.body.replace(new RegExp(value, 'ig'), '<b style="color: #1AB394 !important;">' + value + '</b>'))
      }));
    } else {
      this.filterComments(this.typeComments);
    }
  }

  searchClear() {
    this.searchTxt = "";
  }

  filterComments(type) {
    this.typeComments = type;

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        id: this.request.id
      }, this.typeComments);
    }
  }

  onKeyUp($event) {
    if (this.newComent.toString().trim().length > 0) {
      this.disabledButton = false;
      if (!this.hasJustSelectedItem && !$event.shiftKey && $event.keyCode == 13) {
        this.addComments();
      }

      if ($event.key == 'Backspace' || $event.key == "Delete") {
        this.checkForDeletedUserTags($event);
      }

    } else {
      this.disabledButton = true;
    }
  }

  onTagSelected(event) {
    if (!this.taggedUsers.find(user => user.id === (event.id))) {
      this.taggedUsers.push(this.users.find(user => user.id === (event.id)))
    }
    this.newComent = this.newComent.replace(`@${event.name}`, `*@${event.name}*`)
  }

  onItemSelected() {
    this.hasJustSelectedItem = true
    setTimeout(() => this.hasJustSelectedItem = false, 1500)
  }


  private checkForDeletedUserTags($event: any) {
    const currentTags = $event.target.value.match(/@([a-zA-Z0-9]+)/g);
    this.taggedUsers = this.taggedUsers.filter((taggedUser, index) => taggedUser.name.includes(currentTags[index]?.split("@")[1]))
  }

  onKeyPress($event) {
    if ($event.keyCode == 13 && !$event.shiftKey) {
      $event.preventDefault();
    }
  }

  searchClose() {
    this.searchTxt = "";
    this.searchOpen = false;
  }

  addCommentWithImages(comment: string, images: { url: string; id: string; }[]) {
    this.textAreaChat.nativeElement.style.height = "35px";
    this.disabledButton = true;
    this.inputLoading = true;
    this.service.commentCreate({
      id: this.request.id
    }, {
      body: this.newComent.replace(/\n/ig, '<br />'),
      type: this.typeComments,
      images: images
    })
      .then(() => {
        this.newComent = "";
        this.inputLoading = false;
        this.images = [];
      });
  }

  addComments() {
    if (this.images.length > 0) {
      this.addCommentWithImages('', this.images)
    } else {
      if (this.newComent.length > 0) {
        this.textAreaChat.nativeElement.style.height = "35px";
        this.disabledButton = true;
        this.service.commentCreate({
          id: this.request.id
        }, {
          body: this.newComent.replace(/\n/ig, '<br />'),
          type: this.typeComments,
          images: []
        }, { data: this.taggedUsers })
          .then(() => {
            this.newComent = "";
            this.taggedUsers = []
          });
      }
    }

  }

  countComments(type: String = 'guest'): number {
    return this.comments.filter(c => c.type === type && !c.read && c.authorId != this.auth.userProfile.id).length
  }

  deleteImg(img) {
    this.images = this.images.filter(im => im.id !== img.id)
    if (this.images.length === 0 && this.newComent.length === 0) this.disabledButton = true
  }

  uploadFile(event) {
    if (event.target.files.length > 0 && (event.target.files.length + this.images.length) <= 5 && !this.disabledButtonUpload) {
      this.disabledButtonUpload = true;
      this.loadingImg = true;
      addFiles([], this.service).then((urls: any) => {
        this.loadingImg = false;
        this.disabledButtonUpload = false;
        this.disabledButton = false;
        this.uploaderFiles.nativeElement.value = "";
        urls.forEach((url) => this.images.push(url));
      }).catch(() => {
        this.openSnackBar(this.translate.instant("Error uploading image"), '✖')
        this.disabledButtonUpload = false;
        this.loadingImg = false
      });
    } else if ((event.target.files.length + this.images.length) > 5) {
      this.openSnackBar(
        this.translate.instant("Sorry, the limit number of images you can select is 5"),
        '✖'
      );
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    } else {
      this.openSnackBar(this.translate.instant("You must select an image"), '✖');
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    }

    async function addFiles(urls: any[], service) {
      return await new Promise(async (resolve, reject) => {
        for (let i = 0; i < event.target.files.length; ++i) {
          let input = new FormData();
          input.append('web', event.target.files[i], event.target.files[i].name);
          input.append("containerName", "tasks");
          await service.uploadImgs(input)
            .then(res => {
              if (res.type === HttpEventType.UploadProgress) {
                //console.log(Math.round(event.loaded / event.total * 100) + "%");
              } else if (res.type === HttpEventType.Response) {
                urls.push({ url: res.body.urlToPreview, id: res.body.id });
              }
              if (event.target.files.length - 1 === i) return resolve(urls)
            }).catch(reject);
        }
      });
    }
  }
}

import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';
import { noop } from 'rxjs';

@Pipe({
  name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): any {
    const regex = /[*][\w\W]*[*]/gmi;
    return this.sanitize(this.replace(value, regex));
  }

  replace(str, regex) {
    let matched = str.match(regex);
    matched ? matched.forEach(foundString => {
      foundString = foundString.substring(1, foundString.length - 1);
      str = str.replace(regex, `<b>${foundString}</b>`);
    }) : noop;
    console.log(str)
    return str;
  }

  sanitize(str) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}
