import { Component, OnInit, OnChanges, Input, SimpleChanges, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation ,Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { CheckInService } from "../../service";
import { Auth0Service } from "../../../../auth0.service";
import { HttpEventType } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: "app-guests-check-in-preview-comments",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('uploader') uploaderFiles: ElementRef;
  @ViewChild('textAreaChat') textAreaChat: ElementRef;
  @Output() eventDeleteComment = new EventEmitter<any>();
  @Input() request: any;
  @Input() comments: Array<any> = [];
  searchOpen: boolean = false;
  searchTxt: string = "";
  newComent: string = "";
  hasImage: boolean = false;
  typeComments: string = 'guest';
  commentsFilter: any[] = [];
  disabledButtonUpload: boolean = false;
  disabledButton: boolean = true;

  constructor(
    public translate: TranslateService,
    private service: CheckInService,
    private auth: Auth0Service,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
  }

  ngOnInit() {
    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, this.typeComments);
    }

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);

    if (this.countComments('internal') > 0) {
      this.filterComments('internal');
    }
    if (this.countComments('guest') > 0) {
      this.filterComments('guest');
    }
  }

  ngAfterViewInit() {
    this.textAreaChat.nativeElement.focus();
  }

  searchInComments(value: string) {
    if (value.trim().length > 0) {
      this.commentsFilter = this.comments.filter(c => c.type == this.typeComments).filter(c =>
        c.body.toLowerCase().includes(value.toLowerCase())
      ).map(c => Object.assign({}, c, {
        body: this.sanitizer.bypassSecurityTrustHtml(c.body.replace(new RegExp(value, 'ig'), '<b style="color: var(--gh-color-dynamic-1) !important;">' + value + '</b>'))
      }));
    } else {
      this.filterComments(this.typeComments);
    }
  }

  searchClear() {
    this.searchTxt = "";
  }

  filterComments(type) {
    this.typeComments = type;

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, this.typeComments);
    }
  }

  onKeyUp($event) {
    if (this.newComent.toString().trim().length > 0) {
      this.disabledButton = false;
      if ($event.keyCode == 13 && !$event.shiftKey) {
        this.addComments();
      }
    } else {
      this.disabledButton = true;
    }
  }

  onKeyPress($event) {
    if ($event.keyCode == 13 && !$event.shiftKey) {
      $event.preventDefault();
    }
  }

  searchClose() {
    this.searchTxt = "";
    this.searchOpen = false;
  }

  addComments() {
    if (this.newComent.length > 0) {
      this.textAreaChat.nativeElement.style.height = "35px";
      this.disabledButton = true;
      this.service.commentCreate({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, {
        body: this.newComent.replace(/\n/ig, '<br />'),
        type: this.typeComments,
        hasImage: this.newComent.includes('<img') ? true : this.hasImage
      })
        .then(() => {
          this.newComent = "";
          this.hasImage = false;
        });
    }
  }

  splitName(comment): string {
    if (comment.guestName) {
      return comment.guestName.split(' ').length > 1 ? comment.guestName.split(' ')[0][0] + comment.guestName.split(' ')[1][0] : comment.guestName[0][0] + comment.guestName[0][1];
    } else {
      const name = comment.userFirstName && comment.userFirstName[0] ? comment.userFirstName[0] : '';
      const last = comment.userLastName && comment.userLastName[0] ? comment.userLastName[0] : '';
      return name + last;
    }
  }
  cutTextFunc(e) {
    return e = e.length > 18 ? e.substr(0, 17).concat('..') : e;
  }

  countComments(type: String = 'guest'): number {
    return this.comments.filter(c => c.type === type && !c.read && c.authorId != this.auth.userProfile.id).length
  }

  uploadFile(event) {
    if (event.target.files.length > 0 && event.target.files.length <= 11 && !this.disabledButtonUpload) {
      this.disabledButtonUpload = true;
      for (let i = 0; i < event.target.files.length; ++i) {
        let input = new FormData();
        input.append('web', event.target.files[i], event.target.files[i].name);
        input.append("containerName", "check-in");
        this.service.uploadImgs(input)
          .subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
              //console.log(Math.round(event.loaded / event.total * 100) + "%");
            } else if (event.type === HttpEventType.Response) {
              this.disabledButtonUpload = false;
              this.uploaderFiles.nativeElement.value = "";
              this.newComent = `<p><img src="${event.body.urlToPreview}" alt="${event.body.id}" width="70%"></p>`;
              this.addComments();
            } else {
              this.disabledButtonUpload = false;
              this.uploaderFiles.nativeElement.value = "";
            }
          });
      }
    } else if (event.target.files.length > 10) {
      alert("No puedes seleccionar mas de 10 imagenes");
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    } else {
      alert("Debes seleccionar una imagen");
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    }
  }
}
