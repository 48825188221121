import { Component, OnInit, OnChanges, Input, SimpleChanges, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { TasksManagerService } from "../../service";
import { Auth0Service } from "../../../auth0.service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { HttpEventType } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: "app-tasks-manager-preview-comments",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"]
})
export class MyComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('uploader') uploaderFiles: ElementRef;
  @ViewChild('textAreaChat') textAreaChat: ElementRef;
  @Output() eventDeleteComment = new EventEmitter<any>();
  @Output() onImgClick = new EventEmitter();
  @Output() onCameraClick = new EventEmitter();
  @Input() request: any;
  @Input() comments: Array<any> = [];
  searchOpen = false;
  searchTxt = "";
  newComent = "";
  typeComments = 'internal';
  commentsFilter: any[] = [];
  disabledButtonUpload = false;
  disabledButton = true;
  inputLoading: boolean;
  uploadOption = false;
  images: any[] = [];
  loadingImg: boolean;

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private service: TasksManagerService,
    private auth: Auth0Service,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
  }

  ngOnInit() {
    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        id: this.request.id
      }, this.typeComments);
    }

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);

  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  ngAfterViewInit() {
    this.textAreaChat.nativeElement.focus();
  }

  searchInComments(value: string) {
    if (value.trim().length > 0) {
      this.commentsFilter = this.comments.filter(c => c.type == this.typeComments).filter(c =>
        c.body.toLowerCase().includes(value.toLowerCase())
      ).map(c => Object.assign({}, c, {
        body: this.sanitizer.bypassSecurityTrustHtml(c.body.replace(new RegExp(value, 'ig'), '<b style="color: var(--gh-color-dynamic-1) !important;">' + value + '</b>'))
      }));
    } else {
      this.filterComments(this.typeComments);
    }
  }

  searchClear() {
    this.searchTxt = "";
  }

  filterComments(type) {
    this.typeComments = type;

    this.commentsFilter = this.comments.filter(c => c.type == this.typeComments);
    if (this.comments.filter(c => !c.read && c.type == this.typeComments && c.authorId != this.auth.userProfile.id).length > 0) {
      this.service.commentMarkToRead({
        id: this.request.id
      }, this.typeComments);
    }
  }

  onKeyUp($event) {
    if (this.newComent.toString().trim().length > 0) {
      this.disabledButton = false;
      if ($event.keyCode == 13 && !$event.shiftKey) {
        this.addComments();
      }
    } else {
      this.disabledButton = true;
    }
  }

  onKeyPress($event) {
    if ($event.keyCode == 13 && !$event.shiftKey) {
      $event.preventDefault();
    }
  }

  searchClose() {
    this.searchTxt = "";
    this.searchOpen = false;
  }

  addCommentWithImages(comment: string, images: { url: string; id: string; }[]) {
    this.textAreaChat.nativeElement.style.height = "35px";
    this.disabledButton = true;
    this.inputLoading = true;
    this.service.commentCreate({
      id: this.request.id
    }, {
      body: this.newComent.replace(/\n/ig, '<br />'),
      type: this.typeComments,
      images: images
    })
      .then(() => {
        this.newComent = "";
        this.inputLoading = false;
        this.images = [];
      });
  }

  addComments() {
    if (this.images.length > 0) {
      this.addCommentWithImages('', this.images)
    } else {
      if (this.newComent.length > 0) {
        this.textAreaChat.nativeElement.style.height = "35px";
        this.disabledButton = true;
        this.service.commentCreate({
          id: this.request.id
        }, {
          body: this.newComent.replace(/\n/ig, '<br />'),
          type: this.typeComments,
          images: []
        })
          .then(() => {
            this.newComent = "";
          });
      }
    }
  }

  countComments(type: String = 'guest'): number {
    return this.comments.filter(c => c.type === type && !c.read && c.authorId != this.auth.userProfile.id).length
  }

  deleteImg(img) {
    this.images = this.images.filter(im => im.id !== img.id)
    if (this.images.length === 0 && this.newComent.length === 0) this.disabledButton = true
  }

  uploadFile(event) {
    if (event.target.files.length > 0 && (event.target.files.length + this.images.length) <= 5 && !this.disabledButtonUpload) {
      this.disabledButtonUpload = true;
      this.loadingImg = true;
      addFiles([], this.service).then((urls: any) => {
        this.loadingImg = false;
        this.disabledButtonUpload = false;
        this.disabledButton = false;
        this.uploaderFiles.nativeElement.value = "";
        urls.forEach((url) => this.images.push(url));
      }).catch(() => {
        this.openSnackBar(this.translate.instant("Error uploading image"), '✖')
        this.disabledButtonUpload = false;
        this.loadingImg = false
      });
    } else if ((event.target.files.length + this.images.length) > 5) {
      this.openSnackBar(
        this.translate.instant("Sorry, the limit number of images you can select is 5"),
        '✖'
      );
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    } else {
      this.openSnackBar(this.translate.instant("You must select an image"), '✖');
      this.disabledButtonUpload = false;
      this.uploaderFiles.nativeElement.value = "";
    }

    async function addFiles(urls: any[], service) {
      return await new Promise(async (resolve, reject) => {
        for (let i = 0; i < event.target.files.length; ++i) {
          let input = new FormData();
          input.append('web', event.target.files[i], event.target.files[i].name);
          input.append("containerName", "tasks");
          await service.uploadImgs(input)
            .then(res => {
              if (res.type === HttpEventType.UploadProgress) {
                //console.log(Math.round(event.loaded / event.total * 100) + "%");
              } else if (res.type === HttpEventType.Response) {
                urls.push({ url: res.body.urlToPreview, id: res.body.id });
              }
              if (event.target.files.length - 1 === i) return resolve(urls)
            }).catch(reject);
        }
      });
    }
  }

}
