import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ProgrammingRepeatService } from '../../../components/programing-repeat/service';

@Component({
  selector: "app-tasks-manager-preview-history",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() history: Array<any> = [];
  langug: string = "en";
  constructor(
    public translate: TranslateService,
    protected programmingRepeatService: ProgrammingRepeatService
  ) { 
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.history = this.history ? this.history : [];
  }

  splitName(history): string {
    return history.authorName[0];
  }
}
