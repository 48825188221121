import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { OutletsDetailService } from "./detail/service";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from '../../event.service';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { ViewChild } from '@angular/core';
import { Auth0Service } from "../../auth0.service";
import { TopService } from "../../navigation/top/service";

@Component({
  selector: "app-outlets-list-recyclerview-item-preview",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class OutletsListRecyclearViewItemPreviewComponent implements OnInit, OnDestroy {
  @Output() exitOutput = new EventEmitter<boolean>();
  @Input() item: any;
  @Input() actionBeforeOpen: string;
  @ViewChild('uploader') uploaderFiles: ElementRef;
  request: any;
  loading = true;
  tabSelected = 'detail';
  subscribeRequests: any;
  otherAction: any = null;
  disabledButtonModal = false;
  modalDeleteComment: any = null;
  optionCancelSelected: any = null;
  optionsCancel: Array<any> = [
    {
      en: 'Item not available',
      es: 'Item no disponible'
    },
    {
      en: 'Canceled at the request of the guest/client',
      es: 'Cancelado a pedido del huésped/cliente'
    }
  ];
  language: String = (localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).lang : 'en');
  newMessage = '';
  message: string;
  modalProccessed = false;
  disabledButtonUpload = false;
  countSumate = 0;
  disabledButton: boolean;
  subscribeDetailInterval: NodeJS.Timer;
  configModalDeleteComment: any = {
    type: 'negative',
    textCancel: 'Cancel',
    textConfirm: 'Delete',
    title: null,
    description: "Are you sure you want to delete this message?",
    radio: null
  };

  configModalProccessed: any = {
    type: 'positive',
    textCancel: 'No, Back',
    textConfirm: 'Yes, Processed',
    title: "Are you sure you want to processed this order?",
    description: "The order was previously canceled by the guest, if processed the order must be attended and delivered",
    radio: null
  };

  constructor(
    public router: Router,
    private service: OutletsDetailService,
    public translate: TranslateService,
    protected eventService: EventService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
    private topService: TopService
  ) { }

  ngOnDestroy() {
    if (this.subscribeRequests) this.subscribeRequests.unsubscribe();
    if (this.subscribeRequests) this.subscribeRequests = undefined;
    clearInterval(this.subscribeDetailInterval);
    this.topService.activeSound$.emit(true);
  }

  ngOnInit() {
    this.loading = true;
    this.service.getById({ outleType: this.item.type, id: this.item.id, outletId: this.item.outletId })
      .then(request => {
        if (request.state == 'validate') {
          this.topService.activeSound$.emit(false);
        }

        this.request = request;
        this.loading = false;
        if (this.countComments() > 0) {
          this.tabSelected = 'comments';
        }

        this.subscribeRequests = this.eventService.channels.outlets.subscribe(request => {
          if (request.id == this.item.id) {
            this.request = request;
          }
        });
        if (this.actionBeforeOpen == 'returned') {
          this.otherAction = 'finished-returned';
        }
        this.subscribeDetailInterval = setInterval(() => {
          ++this.countSumate;
        }, 1000);
      });
  }

  closeModal() {
    this.exitOutput.emit(true);
  }

  countComments(): number {
    return this.request.comments.filter(c => !c.read && c.authorId != this.auth.userProfile.id).length
  }

  otherActionEvent(e) {
    if (e === 'accepted') {
      this.modalProccessed = true;
    } else if (e === 'returned') {
      this.otherAction = 'finished-returned';
    } else {
      this.otherAction = e;
    }
  }

  goToProcess() {
    this.modalProccessed = false;
    this.request.statusName = this.request.otherStatuses.find(s => s.codename === 'accepted').name;
    this.request.statusCodename = this.request.otherStatuses.find(s => s.codename === 'accepted').codename;
    this.request.statusColorHex = this.request.otherStatuses.find(s => s.codename === 'accepted').colorHex;
    this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(this.request.otherStatuses.find(s => s.codename === 'accepted').codename), 1);
    this.service.update({
      outleType: this.request.type,
      outletId: this.request.outletId,
      id: this.request.id
    }, {
      codename: this.request.statusCodename
    }).then(() => {
      this.optionCancelSelected = null;
      this.otherAction = null;
      this.request.statusCodename == 'accepted'
        ? this.openSnackBar(this.translate.instant("The order has been processed"), '✓')
        : null
    });
  }

  cancelOrder() {
    if (this.request.otherStatuses.find(s => s.codename === 'cancelled')) {
      this.request.statusName = this.request.otherStatuses.find(s => s.codename === 'cancelled').name;
      this.request.statusCodename = this.request.otherStatuses.find(s => s.codename === 'cancelled').codename;
      this.request.statusColorHex = this.request.otherStatuses.find(s => s.codename === 'cancelled').colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(this.request.otherStatuses.find(s => s.codename === 'cancelled').codename), 1);
      let reazon;
      switch (this.language) {
        case 'es':
          reazon = this.optionCancelSelected['es'] + " / " + this.optionCancelSelected['en'];
          break;
        default:
          reazon = this.optionCancelSelected['en'] + " / " + this.optionCancelSelected['es']
      }
      this.service.update({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, {
        codename: this.request.statusCodename,
        reason: reazon
      }).then(() => {
        this.optionCancelSelected = null;
        this.otherAction = null;
        this.request.statusCodename == 'cancelled'
          ? this.openSnackBar(this.translate.instant("The order has been cancelled"), '✓')
          : null
      });
    }
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  sendMessage() {
    this.newMessage = this.message,
      this.message = '';
  }

  deleteComment(comment) {
    this.disabledButtonModal = true;
    this.service
      .deleteComment({
        requestId: this.request.id,
        id: comment._id,
        outleType: this.item.type
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Comment deleted"),
          "✓"
        );
        this.modalDeleteComment = null;
        this.disabledButtonModal = false;
      }).catch(err => {
        console.error(err);
        this.disabledButtonModal = false;
      });
  }

  openReturnedConfirm(input) {
    this.service.update({ ...this.request, outleType: this.item.type }, {
      codename: input.codename,
      reason: input.comment
    }).then(() => {
      this.openSnackBar(
        this.translate.instant("The order has been processed"),
        "✓"
      );
      this.otherAction = null;
    }).catch(err => {
      console.error(err);
      this.disabledButton = false;
      this.openSnackBar(
        this.translate.instant('UPS: One error ocurred'),
        '✖'
      );
    });
  }


}
