import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { Auth0Service } from "../../../auth0.service";

@Injectable()
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  listNotifications(page?: number, type?: string) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications${page || type ? '?' : ''}${page ? ('page=' + page) : ''}${type ? ((page ? '&' : '') + 'type=' + type) : ''}`)
      }`).toPromise();
  }

  listNotificationsUnread(page?: number) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications-unread${page ? ('?page=' + page) : ''}`)
      }`).toPromise();
  }

  countNotifications(query?) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications-count${query ? '?' : ''}${query && query.notificationSound
        ? `notificationSound=${query.notificationSound}`
        : ``}${query && query.notificationSound ? '&' : ''}${query && query.type ? ('type=') + query.type : ''}`)
      }`).toPromise();
  }

  markToRead(id) {
    return this.http.put<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat(`/notification/${id}/update`)
      }`, { read: true }).toPromise();
  }

  deleteAll(type?: string) {
    return this.http.delete<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications`)
      .concat(`${type ? '?type=' + type : ''}`)
      }`, {}).toPromise();
  }
}
