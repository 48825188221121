<div #myDivSimulator class="containerbase col xl12  l12  m12  s12 padding0 ">
  <div class="col xl7  l6  m5  s6 padding-bottom-div">
    <div *ngIf="auth.isAuthenticated" class="divmenu">
      <mat-icon class="iconNavbar pointer" (click)="showSidebar()" tooltip="{{ 'Expand/Collapse menu' | translate }}"
        container="body" placement="bottom">menu
      </mat-icon>
    </div>
    <a *ngIf="auth.isAuthenticated" routerLink="/" class="efecto">
      <img src="assets/GH-dark-bg.svg" class="iconGuest" />
    </a>
  </div>

  <div class="col xl5 l6  m7  s6 padding-bottom-div right padding-left-flot">
    <div *ngIf="auth.isAuthenticated" class="flot margin-right-flot margin-top10" [matMenuTriggerFor]="profile">
      <div class="pictureavtar" tooltip="{{ 'My account' | translate }}" container="body" placement="bottom">
        <img *ngIf="auth && auth.isAuthenticated && auth.userProfile" [src]="auth.userProfile.picture" class="circle"
          width="32">
      </div>
    </div>


    <mat-menu #profile="matMenu" class="menuprofile">
      <a mat-menu-item class="liSubNav color-default" (click)="popupabVisible = true">
        {{ 'About Guesthub' | translate }}
      </a>
      <a mat-menu-item class="liSubNav color-default" (click)="modalOpenMyProfile=true">
        {{ 'My profile' | translate }}
      </a>
      <!-- <a mat-menu-item class="liSubNav color-default" [matMenuTriggerFor]="legal">{{ 'Legal' | translate }}</a> -->
      <a mat-menu-item (click)="goToSubscription()" *ngIf="auth.isAuthenticated && auth.getIsSubscriptionAdmin"
        class="liSubNav color-default">{{ 'Subscription' | translate }}</a>
      <div style="display: flex; flex-direction: row-reverse;"
        class="switch switch_toggle switch switch_toggle ng-tns-c7-0 ng-star-inserted mat-focus-indicator liSubNav color-default mat-menu-item ng-tns-c7-0"
        *ngIf="!isAProd" tooltip="{{ 'Enabled / Disabled' | translate }}" container="body" placement="top">
        <label class="title-style">
          <input type="checkbox" [checked]="managerColorService.modeSelected == 'dark'"
            (change)="enabledDisabledDarkMode($event.target.checked)" />
          <span class="lever right"></span>
        </label>
        Modo dark
      </div>
      <a mat-menu-item class="liSubNav color-default" href="mailto:">{{ 'Contact' | translate }}</a>
      <a mat-menu-item class="liSubNav color-default" [matMenuTriggerFor]="language"
        tooltip="{{ 'Select Language' | translate }}" container="body" placement="bottom">
        {{ myLanguage.name | translate }}
      </a>
      <mat-menu #language="matMenu" class="menulanguage">
        <div *ngFor="let language of languages ; let i = index; ">
          <a mat-menu-item *ngIf="language.code != myLanguage.lang" (click)="changeLanguage(language.code)">
            {{ language.name | translate }}
          </a>
        </div>
      </mat-menu>
      <a mat-menu-item class="logout" (click)="auth.logout()"> <i class="material-icons right">exit_to_app</i>
        <span class="left margin-top-12">
          {{ 'Log Out' | translate }}
        </span>
      </a>
    </mat-menu>

    <!-- <mat-menu #legal="matMenu">
          <a class="liSubNav color-default" mat-menu-item href=" https://www.guesthub.io/terms/">{{"Term & Conditions"
              |
              translate}}</a>
          <a class="liSubNav color-default" mat-menu-item href="http://guesthub.io/privacy-policy">{{"Privacy Policy"
              |
              translate}}</a>
      </mat-menu> -->

    <mat-menu #profile="matMenu" class="menuprofile">
      <a mat-menu-item class="liSubNav color-default" (click)="popupabVisible = true">
        {{ 'About Guesthub' | translate }}
      </a>
      <a mat-menu-item class="liSubNav color-default" (click)="modalOpenMyProfile=true">
        {{ 'My profile' | translate }}
      </a>
      <!-- <a mat-menu-item class="liSubNav color-default" [matMenuTriggerFor]="legal">{{ 'Legal' | translate }}</a> -->
      <a mat-menu-item (click)="goToSubscription()" *ngIf="auth.isAuthenticated && auth.getIsSubscriptionAdmin"
        class="liSubNav color-default">{{ 'Subscription' | translate }}</a>
      <div style="display: flex; flex-direction: row-reverse;"
        class="switch switch_toggle switch switch_toggle ng-tns-c7-0 ng-star-inserted mat-focus-indicator liSubNav color-default mat-menu-item ng-tns-c7-0"
        *ngIf="!isAProd" tooltip="{{ 'Enabled / Disabled' | translate }}" container="body" placement="top">
        <label class="title-style">
          <input type="checkbox" [checked]="managerColorService.modeSelected == 'dark'"
            (change)="enabledDisabledDarkMode($event.target.checked)" />
          <span class="lever right"></span>
        </label>
        Modo dark
      </div>
      <a mat-menu-item class="liSubNav color-default" href="mailto:">{{ 'Contact' | translate }}</a>
      <a mat-menu-item class="liSubNav color-default" [matMenuTriggerFor]="language"
        tooltip="{{ 'Select Language' | translate }}" container="body" placement="bottom">
        {{ myLanguage.name | translate }}
      </a>

      <mat-menu #language="matMenu" class="menulanguage">
        <div *ngFor="let language of languages ; let i = index; ">
          <a mat-menu-item *ngIf="language.code != myLanguage.lang" (click)="changeLanguage(language.code)">
            {{ language.name | translate }}
          </a>
        </div>
      </mat-menu>
      <a mat-menu-item class="logout" (click)="auth.logout()"> <i class="material-icons right">exit_to_app</i>
        <span class="left margin-top-12">
          {{ 'Log Out' | translate }}
        </span> </a>
    </mat-menu>
    <!-- <mat-menu #legal="matMenu">
            <a class="liSubNav color-default" mat-menu-item href=" https://www.guesthub.io/terms/">{{"Term & Conditions"
                |
                translate}}</a>
            <a class="liSubNav color-default" mat-menu-item href="http://guesthub.io/privacy-policy">{{"Privacy Policy"
                |
                translate}}</a>

        </mat-menu> -->
    <div class="flot margin3top ">
      <div style="display: flex;">
        <div class="dropdivcont">
          <div class="pointer" (click)="showNotifications = !showNotifications">
            <i class="icon-notification_16px icon-menu30" [ngClass]="{'back__color': showNotifications}"
              tooltip="{{ 'Notifications' | translate }}" container="body" placement="bottom"></i>
            <div *ngIf="notificationsCount > 0" class="countnot">
              <span class="textcount">
                {{notificationsCount}}
              </span>
            </div>
          </div>
          <app-notifications [module]="'Notifications'" *ngIf="showNotifications" (onAction)="onActionEvent($event)"
            (close)="showNotifications = false;"></app-notifications>
        </div>
        <div class="dropdivcont">
          <div class="pointer" (click)="showMessages = !showMessages">
            <i class="icon-messages_16px icon-menu30" [ngClass]="{'back__color': showMessages}"
              tooltip="{{ 'Messages' | translate }}" container="body" placement="bottom"></i>
            <div *ngIf="messagesCount > 0" class="countnot">
              <span class="textcount">
                {{messagesCount}}
              </span>
            </div>
          </div>
          <app-notifications [module]="'Messages'" *ngIf="showMessages" (onAction)="onActionEvent($event)"
            (close)="showMessages = false;"></app-notifications>
        </div>
        <div>
          <i class="material-icons" (click)="mailMe()"
            style="font-size: 30px; color: var(--gh-color-light-grey-2);margin-right: 15px; margin-left: 8px; margin-top: 6px; cursor: pointer;"
            placement="bottom" tooltip="{{ 'Support' | translate }}">
            support_agent
          </i>
        </div>
        <div *ngIf="url.includes('norelian.com')">
          <i class="material-icons" (click)="sendCommunicationsReport()"
            style="font-size: 30px; color: var(--gh-color-light-grey-2);margin-right: 15px; margin-left: 8px; margin-top: 6px; cursor: pointer;"
            placement="bottom" tooltip="{{ 'Comunnications Report' | translate }}">
            receipt_long
          </i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="notificationsUnRead" *ngIf="notificationsUnReadDesplay.length > 0">
  <div *ngFor="let notification of notificationsUnReadDesplay" class="marg_bot5 anym">
    <app-notification-detail [notification]="notification" [isPopup]="true"
      (onAction)="onActionEvent($event)"></app-notification-detail>
  </div>
</div>

<ng-container [ngSwitch]="viewOpenPreview">
  <app-requests-manager-preview *ngSwitchCase="'requests'" [tabSelected]="itemSelected.tab"
    [actionBeforeOpen]="viewPreview" [item]="itemSelected"
    (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-requests-manager-preview>
  <app-requests-manager-preview *ngSwitchCase="'promotion'" [tabSelected]="itemSelected.tab"
    [actionBeforeOpen]="viewPreview" [item]="itemSelected"
    (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-requests-manager-preview>
  <app-tasks-manager-preview *ngSwitchCase="'tasks'" [tabSelected]="itemSelected.tab" [actionBeforeOpen]="viewPreview"
    [item]="itemSelected" (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-tasks-manager-preview>
  <app-outlets-list-recyclerview-item-preview *ngSwitchCase="'restaurant'" [tabSelected]="itemSelected.tab"
    [actionBeforeOpen]="viewPreview" [item]="itemSelected"
    (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-outlets-list-recyclerview-item-preview>
  <app-outlets-list-recyclerview-item-preview *ngSwitchCase="'service'" [tabSelected]="itemSelected.tab"
    [actionBeforeOpen]="viewPreview" [item]="itemSelected"
    (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-outlets-list-recyclerview-item-preview>
  <app-guests-check-in-preview *ngSwitchCase="'check-in'" [tabSelected]="itemSelected.tab"
    [actionBeforeOpen]="viewPreview" [item]="itemSelected"
    (exitOutput)="viewPreview=undefined;viewOpenPreview=undefined;itemSelected=undefined">
  </app-guests-check-in-preview>
</ng-container>

<app-my-profile *ngIf="modalOpenMyProfile" (output)="modalOpenMyProfile = false"
  (close)="modalOpenMyProfile = false"></app-my-profile>

<app-guesthub-component-modal-disconnect *ngIf="connectOffline"></app-guesthub-component-modal-disconnect>

<app-guesthub-component-modal-about *ngIf="popupabVisible"
  (onClosed)="popupabVisible = false"></app-guesthub-component-modal-about>