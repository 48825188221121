import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./welcome/module").then((m) => m.WelcomeModule),
  },
  {
    path: "design",
    loadChildren: () =>
      import("./design/module").then((m) => m.DesignModule),
  },
  {
    path: "redirectTo",
    loadChildren: () =>
      import("./redirect-to/module").then((m) => m.RedirectToModule),
  },
  {
    path: "outlet",
    loadChildren: () => import("./outlets/module").then((m) => m.OutletsModule),
  },
  {
    path: "requests",
    loadChildren: () =>
      import("./requests-updated/module").then((m) => m.RequestsManagerModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./tasks-manager/module").then((m) => m.TasksManagerModule),
  },
  {
    path: "pickup",
    loadChildren: () => import("./pickup/module").then((m) => m.PickupModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/module").then((m) => m.DashboardModule),
  },

  {
    path: "dashboard-report",
    loadChildren: () =>
      import("./dashboard-report/module").then(
        (m) => m.DashboardReportModule
      ),
  },
  {
    path: "guests",
    loadChildren: () => import("./guests/module").then((m) => m.GuestsModule),
  },
  {
    path: "guest-items",
    loadChildren: () =>
      import("./guest-items-new/module").then((m) => m.GuestItemsModule),
  },
  {
    path: "communications",
    loadChildren: () =>
      import("./communications/module").then((m) => m.CommunicationsModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/module").then((m) => m.SettingsModule),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "",
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
