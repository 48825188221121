import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { UrlBuilderService } from "./services/url-builder.service";
import { HttpClient } from "@angular/common/http";
import { createAuth0Client } from '@auth0/auth0-spa-js';

@Injectable({
  providedIn: "root",
})
export class Auth0Service {
  private token: any;
  auth0: any;
  userProfile: any;
  isAuthenticated: boolean = false;
  private _scopes: Array<string>;
  private _chosen_property: number;
  private _isSubscriptionAdmin: any;
  userPreferences: any = {};

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService
  ) { }

  get scopes(): Array<string> {
    return this._scopes;
  }

  handleAuthentication(): Promise<any> {
    this.isAuthenticated = false;
    return this.connectAuth0Client().then((auth0) =>
      auth0
        .getTokenSilently()
        .then((token) => {
          this.token = token;
          return auth0
            .isAuthenticated()
            .then((isAuthenticated) =>
              isAuthenticated
                ? this.isLoggedin()
                : this.isCallback()
                  ? this.handleCallback(auth0)
                  : this.notLoggedIn(auth0)
            );
        })
        .catch((err) =>
          this.isCallback()
            ? this.handleCallback(auth0)
            : this.notLoggedIn(auth0)
        )
        .then(() => auth0.getUser())
        .then((user) => {
          this.auth0 = auth0;
          this.isAuthenticated = true;
          this.userProfile = user;
          return this.setSession(this.token);
        })
    );
  }

  private setSession(token: string): Promise<any> {
    return fetch(this.urlbuilder.contextByEmail(), {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((response) => {
        if (localStorage.getItem("chosen_property")) {
          if (response.properties.length > 0) {
            if (
              !response.properties.find(
                (p) => p.id == localStorage.getItem("chosen_property")
              )
            ) {
              localStorage.setItem(
                "chosen_property",
                response.properties[0].id
              );
            }
            this._scopes = response.properties.find(
              (p) => p.id == localStorage.getItem("chosen_property")
            ).permissions;
            this._chosen_property = response.properties.find(
              (p) => p.id == localStorage.getItem("chosen_property")
            ).id;
          } else {
            this.logout();
            return;
          }
        } else {
          if (response.properties.length > 0) {
            localStorage.setItem("chosen_property", response.properties[0].id);
            this._scopes = response.properties[0].permissions;
            this._chosen_property = response.properties[0].id;
          } else {
            this.logout();
            return;
          }
        }

        this.http
          .get<any>(
            `${this.urlbuilder.propertyApi
              .concat("/")
              .concat(this._chosen_property.toString())
              .concat(`/user/${response.id}/preferences`)}`
          )
          .toPromise()
          .then((preferences) => {
            this.userPreferences = preferences;
          });

        this.userProfile = Object.assign({}, response, {
          subscriptionName: response.properties.find(
            (p) => p.id == this._chosen_property
          ).subscriptionName,
          subscriptionId: response.properties.find(
            (p) => p.id == this._chosen_property
          ).subscriptionId,
          isSocialLogin:
            this.userProfile.sub && this.userProfile.sub.search("auth0") < 0
              ? true
              : false,
        });
        this._isSubscriptionAdmin = response.properties.find(
          (p) => p.id == this._chosen_property
        ).isAdmin;
        return response;
      });
  }

  private connectAuth0Client(): Promise<any> {
    return createAuth0Client({
      domain: environment.auth0.configuration.domain,
      clientId: environment.auth0.configuration.clientID,
      authorizationParams: {
        audience: environment.auth0.configuration.audience,
        redirect_uri: environment.auth0.configuration.redirectUri,
      },
      cacheLocation: "localstorage",
    });
  }

  private handleCallback(auth0: any) {
    return auth0
      .handleRedirectCallback()
      .then(() => window.history.replaceState({}, document.title, "/"))
      .catch(() => auth0.loginWithRedirect());
  }

  private isCallback() {
    return (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    );
  }

  private isLoggedin() {
    return window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    );
  }

  private notLoggedIn(auth0: any) {
    return auth0.loginWithRedirect();
  }

  getToken(): any {
    return this.token;
  }

  logout() {
    this.userProfile = undefined;
    this.token = undefined;
    this._chosen_property = -1;
    this._scopes = [];
    localStorage.clear();

    this.auth0.logout({
      returnTo: window.location.origin,
    });
  }

  public userHasScopes(scopes: Array<string>, some: boolean = false): boolean {
    return scopes
      ? some
        ? scopes.some((scope) => this._scopes.includes(scope))
        : scopes.every((scope) => this._scopes.includes(scope))
      : false;
  }

  get accessToken(): string {
    return this.token;
  }

  get getChosenProperty(): number {
    return this._chosen_property;
  }

  get getIsSubscriptionAdmin(): boolean {
    return this._isSubscriptionAdmin;
  }

  changeProperty(property): Promise<any> {
    return Promise.resolve().then(() => {
      localStorage.clear();
      localStorage.setItem("chosen_property", property.id);
      return true;
    });
  }
}
