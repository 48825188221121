import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent,BoldTextPipe } from "./component";
import { RequestsManagerService } from "../../service";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from "../../../components/directives/module";
import { MaterialModule } from "../../../material.module";
import { MentionModule } from "../../../components/mentions/mention.module";
@NgModule({
  declarations: [MyComponent, BoldTextPipe],
  imports: [
    CommonModule,
    TranslateModule,
    Ng2SearchPipeModule,
    FormsModule,
    MaterialModule,
    DirectivesModule,
    MentionModule
  ],
  exports: [MyComponent],
  providers: [RequestsManagerService],
})
export class RequestsManagerPreviewCommentsModule { }
