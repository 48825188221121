import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TasksManagerService } from "../../service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../../auth0.service";
import { ProgrammingRepeatService } from "../../../components/programing-repeat/service";

@Component({
  selector: "app-tasks-manager-preview-detail",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit {
  @Output() otherActionEvent = new EventEmitter<any>();
  @Input() request: any;
  @Input() countSumate: number;
  editRoom: boolean;
  langug = "en";
  offset: number;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: TasksManagerService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public auth: Auth0Service,
    protected programmingRepeatService: ProgrammingRepeatService
  ) {
    this.offset = (new Date().getTimezoneOffset());
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnInit() { }

  updateStatus(status) {
    if (status.codename == 'cancelled') {
      this.otherActionEvent.emit('cancelled');
    } else {
      this.request.statusName = status.name;
      this.request.statusCodename = status.codename;
      this.request.statusColorHex = status.colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(status.codename), 1);
      this.service.updateStatus({
        id: this.request.id
      }, {
        codename: this.request.statusCodename
      }).then(() =>
        this.request.statusCodename == 'accepted'
          ? this.openSnackBar(this.translate.instant("The ticket has been processed"), '✓')
          : this.request.statusCodename == 'confirmed'
            ? this.openSnackBar(this.translate.instant("The ticket has been confirmed"), '✓')
            : null
      );
    }
  }
  updateRoom(type, value) {
    if (value && value.toString().trim().length > 0) {
      this.otherActionEvent.emit({ type: 'update:where', value: value.toString().trim() });
      this.editRoom = false;
    } else {
      this.openSnackBar(this.translate.instant("Field where is required"), '✖');
    }
  }

  updateStateTime(state: string) {
    this.request = Object.assign({}, this.request, {
      stateTime: state
    });
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

}
