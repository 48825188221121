import { LangChangeEvent } from "@ngx-translate/core";

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChange,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EditorWebService } from "./service";
import "jquery";
interface JQueryStatic {
  summernote: {
    ui: any; // or some more reasonable type
  };
}
import { MatSnackBar } from "@angular/material/snack-bar";

declare var jQuery: JQueryStatic;
var SelectedName = "Selected";
var newObjectSync = [];

@Component({
  selector: "app-web-editor-component",
  templateUrl: "template.html",
  styleUrls: ["./styles.scss"],
})
export class EditorWebComponent implements OnInit, OnChanges {
  @Input() es: any;
  @Input() en: any;
  @Input() html: any;
  @Input() Id: any = "1_uno";
  @Input() configNew: any;
  @Input() routeSave: string;
  @Output() changeEditor: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputHasImage: EventEmitter<boolean> = new EventEmitter<boolean>();

  translate: any;
  visible = false;

  constructor(
    translate: TranslateService,
    private _snackBar: MatSnackBar,
    private editorWebService: EditorWebService
  ) {
    this.translate = translate;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.visible = true;
      setTimeout(() => {
        this.changeLanguages(this.translate.currentLang);
        this.visible = false;
      }, 200);
    });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes && changes.configNew) {
      this.config.placeholder =
        this.configNew && this.configNew.placeholder
          ? this.configNew.placeholder
          : this.config.placeholder;
      this.config.height =
        this.configNew && this.configNew.height
          ? this.configNew.height
          : this.config.height;
      this.config.tabsize =
        this.configNew && this.configNew.tabsize
          ? this.configNew.tabsize
          : this.config.tabsize;
      this.config.toolbar =
        this.configNew && this.configNew.toolbar
          ? this.configNew.toolbar
          : this.config.toolbar;
      this.config.fontNames =
        this.configNew && this.configNew.fontNames
          ? this.configNew.fontNames
          : this.config.fontNames;
      this.config.imageAttributes =
        this.configNew && this.configNew.imageAttributes
          ? this.configNew.imageAttributes
          : this.config.imageAttributes;
      this.config.lang =
        this.configNew && this.configNew.lang
          ? this.configNew.lang
          : this.config.lang;
      this.config.popover =
        this.configNew && this.configNew.popover
          ? this.configNew.popover
          : this.config.popover;
    }
  }

  customButton(context) {
    var ui = jQuery.summernote.ui;
    var list = "";
    newObjectSync.forEach((element) => {
      list = list + "  " + '<li class="pointer">' + element.name + "</li>";
    });

    return ui
      .buttonGroup([
        ui.button({
          className: "dropdown-toggle",
          contents: `${SelectedName} <i class="note-icon-caret"></i>`,
          tooltip: "Selected",
          data: {
            toggle: "dropdown",
          },
        }),
        ui.dropdown({
          className: "drop-default summernote-list",
          contents: "<ul>" + list + "</ul>",
          callback: function ($dropdown) {
            $dropdown.find("li").each(function () {
              $(this).click(function (e) {
                let exist =
                  $(this) && $(this)[0] && $(this)[0].innerText
                    ? $(this)[0].innerText
                    : "notFound";
                let resp = newObjectSync.find((v) => v.name == exist);
                context.invoke("editor.restoreRange");
                context.invoke("editor.focus");
                context.invoke("editor.insertText", resp.id);
                e.preventDefault();
              });
            });
          },
        }),
      ])
      .render();
  }

  config: any = {
    placeholder: null,
    height: 350,
    tabsize: 2,
    // callbacks: {
    //   onPaste: this.onPaste,
    // },
    toolbar: [
      ["misc", ["codeview", "undo", "redo", "fullscreen"]],
      ["style", ["bold", "italic", "underline", "clear", "fontsizeunit"]],
      ["font", ["bold", "italic", "underline", "strikethrough", "clear"]],
      ["fontsize", ["fontname", "fontsize", "color"]],
      ["para", ["style", "ul", "ol", "paragraph", "height"]],
      [
        "insert",
        [
          "sEmoji",
          "testBtn",
          "table",
          "picture",
          "link",
          "hr",
          "resizedDataImage",
        ],
      ],
    ],
    fontNames: [
      "Helvetica",
      "Arial",
      "Arial Black",
      "Comic Sans MS",
      "Courier New",
      "Roboto",
      "Times",
      "Open Sans",
      "sans-serif",
    ],
    imageAttributes: {
      icon: '<i class="note-icon-pencil"/>',
      figureClass: "figureClass",
      figcaptionClass: "captionClass",
      captionText: "Caption Goes Here.",
      manageAspectRatio: true, // true = Lock the Image Width/Height, Default to true
    },
    lang: "en-Us",
    popover: {
      image: [
        [
          "imagesize",
          ["imageSize125", "imageSize100", "imageSize50", "imageSize25"],
        ],
        ["float", ["floatLeft", "floatRight", "floatCenter", "floatNone"]],
        ["remove", ["removeMedia"]],
        ["custom", ["imageAttributes"]],
      ],
    },
    buttons: {
      testBtn: this.customButton,
    },
    callbacks: {
      onPaste: this.handlePaste,
    },
  };

  // editor-termina
  ngOnInit() {
    this.routeSave =
      this.routeSave && this.routeSave.length
        ? this.routeSave
        : "communications-images";
    this.visible = false;
    this.changeLanguages(this.translate.currentLang);
    this.config.placeholder = null;
    //setTimeout(() => this.intrerceptInsertUrl(), 100)
  }

  handlePaste(e: any) {
    // Intentar obtener el texto pegado del objeto originalEvent.clipboardData
    const clipboardData = e?.originalEvent?.clipboardData;

    if (clipboardData) {
      const pastedHtml = clipboardData.getData("text/html");
      const pastedText = clipboardData.getData("text");

      // Si hay HTML disponible, ajustamos el HTML pegado
      if (pastedHtml.includes("?xml") || pastedHtml.includes("xhtml")) {
        this.openSnackBar(
          this.translate.instant("Not-supported pasted text"),
          "✖"
        );
        e.preventDefault();
      }
      else if (pastedHtml) {
        e.preventDefault();
        const adjustedHtml = pastedHtml
          // Eliminar <meta>, <script>, <style>, <link>, y <head> completos
          .replace(/<\s*(meta|script|style|link|head)[^>]*>.*?<\s*\/\s*\1\s*>/gi, '')

          // Eliminar atributos width, height, class, id, y eventos JavaScript (ej: onclick)
          .replace(/\s*(width|height|class|id|on\w+)="[^"]*"/gi, '')

          // Eliminar etiquetas obsoletas como <font>, <center>, <b>, <i>
          .replace(/<\s*(font|center|b|i)[^>]*>.*?<\s*\/\s*\1\s*>/gi, '')

          // Limpiar atributos problemáticos de <iframe> y <embed>
          .replace(/<\s*(iframe|embed)[^>]*(width|height)="[^"]*"[^>]*>/gi, '')

          // Eliminar comentarios HTML <!-- ... -->
          .replace(/<!--[\s\S]*?-->/g, '')

          // Eliminar cualquier estilo inline que contenga background, background-color, o color
          .replace(/style="[^"]*(background|background-color|color)\s*:[^;"]*;?/gi, '')

          // Eliminar variables CSS dentro de atributos style
          .replace(/style="[^"]*--[^"]*"/gi, '')

          // Opcionalmente eliminar atributos vacíos después de la limpieza
          .replace(/\s*[a-z-]+=""/gi, '');
        document.execCommand("insertHTML", false, adjustedHtml);
      }
      // Si solo hay texto, ajustamos el texto pegado
      else if (pastedText) {
        e.preventDefault();
        const adjustedText = pastedText;
        document.execCommand("insertText", false, adjustedText);
      }
    }
  }

  changeEditorHtml(e) {
    this.html = (e || '');

    this.createImg(this.html);
  }

  createImg(e: string) {
    // Verifica si el contenido tiene etiquetas HTML antes de tratarlo como HTML
    if (/<[a-z][\s\S]*>/i.test(e)) {
      for (const img of $(e).find("img")) {
        if (img.src.includes('base64')) {
          let input = new FormData();
          input.append("web", img.src);
          input.append("containerName", this.routeSave);
          this.editorWebService.uploadImgs(input).toPromise().then(res => {
            e = e.replace(img.src, (res as any).body.urlToPreview)
            this.html = e;
            this.visible = true;
            setTimeout(() => this.visible = false, 10)
            this.changeEditor.emit(e);
          })
        }
      }
    }

    this.changeEditor.emit((e || ''));
  }
  changeLanguages(lang) {
    if (lang == "es") {
      this.config.lang = "es-ES";
      SelectedName = "Campos personalizados";
      newObjectSync = this.es ? this.es : [];
    } else {
      this.config.lang = "en-US";
      SelectedName = "Custom fields";
      newObjectSync = this.en ? this.en : [];
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ["red-snackbar"],
    });
  }

}
