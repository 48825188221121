import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { Auth0Service } from "../../../auth0.service";
import { environment } from "../../../../environments/environment";

@Injectable()
export class OutletsDetailService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) {}

  getById(input) {
    return this.http
      .get<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            input.outleType == "restaurants"
              ? "restaurant"
              : input.outleType == "services"
              ? "service"
              : input.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(input.id)
          .concat(
            `${
              input.outletId
                ? "?outletId=" + encodeURIComponent(input.outletId)
                : ""
            }`
          )}`
      )
      .toPromise();
  }

  update(input, data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http
      .put<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            input.outleType == "restaurants"
              ? "restaurant"
              : input.outleType == "services"
              ? "service"
              : input.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(input.id)
          .concat(
            `${
              input.outletId
                ? "?outletId=" + encodeURIComponent(input.outletId)
                : ""
            }`
          )}`,
        data
      )
      .toPromise();
  }

  updateWhere(input, data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http
      .put<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            input.outleType == "restaurants"
              ? "restaurant"
              : input.outleType == "services"
              ? "service"
              : input.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(input.id)
          .concat("/where")
          .concat(
            `${
              input.outletId
                ? "?outletId=" + encodeURIComponent(input.outletId)
                : ""
            }`
          )}`,
        data
      )
      .toPromise();
  }

  uploadImgs(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(
        environment.BACKENDFILES.DOMAIN
      )}/${this.auth.getChosenProperty}`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  deleteComment(data) {
    return this.http
      .delete<any>(
        `${this.urlbuilder.outletsApi
          .concat("/")
          .concat(this.auth.getChosenProperty.toString())
          .concat("/")
          .concat(
            data.outleType == "restaurants"
              ? "restaurant"
              : data.outleType == "services"
              ? "service"
              : data.outleType
          )
          .concat("/")
          .concat("monitor")
          .concat("/")
          .concat(data.requestId)
          .concat("/comment/")
          .concat(data.id)}`,
        {}
      )
      .toPromise();
  }
}
