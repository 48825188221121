<p class="col s12 m12 xl12"></p>
<div class="modal-padding-leftright content-detail scrolling-gavete" #scrollMe>
    <span class="col s12 padding0 padding-bootom-24px" *ngFor="let his of history; let i = index">
        <div class="col s12 padding0">
            <div class="padding0 col s12 m12 l12 xl12">
                <span *ngIf="his.authorPicture" class="btn btn-default btn-floating hiss btn-small
                btn-initials" style="background: #d8d8d8 !important; margin: 2px!important;">
                    <img class="imagen-avatare" [src]="his.authorPicture" width="100%" />
                </span>
                <span class="font-name font12 blod">
                    {{ his.authorName }}
                </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'d MMM yyyy':'TZ' : langug |
                    uppercase |
                    translate}} </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'h:mm a':'TZ' : langug | uppercase
                    }}</span>
            </div>
            <div class="col s12 his padding-left-48">
                <span>
                    <span>
                        <div class="panel-body justify-text" style="font-size: 16px;margin-top: -10px;">
                            <div *ngIf="his.type == 'comment-deleted'" class="font16">
                                {{ "Deleted comment:" | translate }}
                                <span class="semi-bold">"{{ his.question }}"</span>
                            </div>

                            <div *ngIf="his.type == 'requests-confirmed'" class="font16">
                                {{ "Discad this order" | translate }}
                            </div>

                            <div *ngIf="his.type == 'requests-status'" class="font16">
                                {{ "Changed status from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>
                            <div *ngIf="his.type == 'requests-change-where'" class="font16">
                                {{ "Changed where from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>
                            <div *ngIf="his.type == 'requests-created'" class="font16">
                                {{ "You have created this order" | translate }}
                            </div>
                            <div *ngIf="his.type == 'requests-created-guest'" class="font16">
                                {{ "The guest has created this order" | translate }}
                            </div>
                        </div>
                    </span>
                </span>
            </div>
        </div>
    </span>
</div>