import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TasksManagerService } from "../service";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from '../../event.service';
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../auth0.service";
import { TopService } from "../../navigation/top/service";

@Component({
  selector: "app-tasks-manager-preview",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, OnDestroy {
  @Output() exitOutput = new EventEmitter<boolean>();
  @Input() item: any;
  @Input() actionBeforeOpen: String;
  request: any;
  loading = true;
  tabSelected = 'detail';
  subscribeRequests: any;
  otherAction: any = null;
  countSumate = 0;
  modalPreviewImg = null;
  modalTakePicture = null;
  modalDeleteComment: any = null;
  modalDeleteTicket = false;
  modalConfirmEditWhen: any = null;
  modalConfirmEditWhere: any = null;
  modalConfirmUpdate: any = null;
  subscribeDetailInterval: NodeJS.Timer;
  listDeclineObj: String[] = [];
  selectedRoomNumber: String = null;
  listRooms: any[] = [];
  searchRoomNumber = "";
  companionSelected: any = null;
  editDate = false;
  originalData: any;
  showDropdownMoreOptions: any = [];
  modalViewCustomization: any;
  editTaskDate: any;
  configModalConfirmUpdateAll = {
    type: 'positive',
    textCancel: 'Cancel',
    textConfirm: 'Save',
    title: 'Do you want to save the changes?',
    description: "The changes will be reflected in the current application.",
    radio: null,
    list: null,
    checkboxes: null,
    disabledButton: false,
    config: null
  }

  configModalCanceled: any = {
    type: 'negative',
    textCancel: 'Back',
    textConfirm: 'Cancel',
    title: "What do you want to cancel?",
    description: null,
    radio: [
      {
        value: 'only',
        title: "Cancel only this task",
        checked: true
      },
      {
        value: 'all',
        title: "Cancel this and future tasks",
        checked: false
      }
    ]
  };

  configModalDeleteComment: any = {
    type: 'negative',
    textCancel: 'Cancel',
    textConfirm: 'Delete',
    title: null,
    description: "Are you sure you want to delete this message?",
    radio: null
  };

  configModalEditWhenConfirm: any = {
    type: 'positive',
    textCancel: 'Cancel',
    textConfirm: 'Save',
    title: "Do you want to save the changes?",
    description: "The changes will be reflected in the current task and in future repetitions.",
    radio: null
  };

  configModalConfirmEditWhere: any = {
    type: 'positive',
    textCancel: 'Cancel',
    textConfirm: 'Save',
    title: "What do you want to edit?",
    description: null,
    radio: [
      {
        value: 'only',
        title: "Edit only this task",
        checked: true
      },
      {
        value: 'all',
        title: "Edit this and future tasks",
        checked: false
      }
    ]
  };
  modalConfirmUpdateAll = false;
  disabledButtonUpdateAll = true;

  constructor(
    public router: Router,
    private service: TasksManagerService,
    public translate: TranslateService,
    protected eventService: EventService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
    private topService: TopService
  ) { }

  ngOnDestroy() {
    if (this.subscribeRequests) this.subscribeRequests.unsubscribe();
    if (this.subscribeRequests) this.subscribeRequests = undefined;
    clearInterval(this.subscribeDetailInterval);
    this.topService.activeSound$.emit(true);
  }

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.service.getById({ id: this.item.id }),
      this.service.getAllRooms()
    ]).then(([request, listRooms]) => {
      if (request.state == 'validate') {
        this.topService.activeSound$.emit(false);
      }

      this.listRooms = listRooms;
      this.request = request;
      this.originalData = Object.assign({}, request);
      if (this.actionBeforeOpen == 'cancelled') {
        this.openModalDelete();
      } else {
        if (this.countComments() > 0) {
          this.tabSelected = 'comments';
        }
      }
      this.loading = false;

      this.subscribeRequests = this.eventService.channels.tasksManager.subscribe(request => {
        if (request.id == this.item.id) {
          if (request.action == 'deleted') {
            this.closeModal();
          } else {
            this.request = request;
            this.originalData = Object.assign({}, request);
          }
        }
      });

      this.subscribeDetailInterval = setInterval(() => {
        ++this.countSumate;
      }, 1000);
    });
  }

  openModalDelete() {
    if (this.request.customizeType == 'no-repeat') {
      this.configModalCanceled.radio = null;
      this.configModalCanceled.title = null;
      this.configModalCanceled.description = "Are you sure you want to cancel this task?";
    } else {
      this.configModalCanceled.title = "What do you want to cancel?";
      this.configModalCanceled.description = null;
      this.configModalCanceled.radio = [
        {
          value: 'only',
          title: "Cancel only this task",
          checked: true
        },
        {
          value: 'all',
          title: "Cancel this and future tasks",
          checked: false
        }
      ];
    }
    this.modalDeleteTicket = true;

  }

  closeModal() {
    this.exitOutput.emit(true);
  }

  countComments(): number {
    return this.request.comments.filter(c => !c.read && c.authorId != this.auth.userProfile.id).length
  }

  otherActionEvent(e) {
    if (e.type == 'all-update') {
      this.modalConfirmUpdateAll = true;
      this.configModalConfirmUpdateAll.config = e.value;
    }
    else if (typeof e == 'object' && e.type == 'update:where') {
      if (e.value != this.request.whereName) {
        if (this.request.customizeType == 'no-repeat') {
          this.confirmEditWhere(e.value);
        } else {
          this.modalConfirmEditWhere = e.value;
        }
      }
    } else if (e == 'cancelled') {
      this.openModalDelete();
    } else if (e == 'update:when') {
      this.editDate = true;
    } else {
      this.otherAction = e;
    }
  }

  confirmEditWhere(value: string) {
    this.configModalConfirmEditWhere.disabledButton = true;

    this.service.updateWhere({
      id: this.request.id
    }, {
      changeType: this.request.customizeType == 'no-repeat'
        ? "only"
        : this.configModalConfirmEditWhere.radio.find(r => r.checked).value,
      where: value
    }).then(() => {
      this.openSnackBar(this.translate.instant("The task has been updated"), '✓');
      this.modalConfirmEditWhere = null;
      this.configModalConfirmEditWhere.disabledButton = false;
    }).catch(err => {
      console.error(err);
      this.configModalConfirmEditWhere.disabledButton = false;
    });
  }

  onEditWhen(input) {
    if (input.startingDate != this.originalData.startingDate ||
      input.finishedDate != this.originalData.finishedDate ||
      input.repeatCount != this.originalData.repeatCount ||
      input.repeatDay != this.originalData.repeatDay ||
      input.repeatDayOfWeek != this.originalData.repeatDayOfWeek ||
      input.repeatMonth != this.originalData.repeatMonth ||
      input.customizeType != this.originalData.customizeType) {
      this.modalConfirmEditWhen = Object.assign({}, {
        executionDate: input.startingDate != this.originalData.startingDate
          ? input.startingDate
          : this.originalData.executionDate,
        startingDate: input.startingDate,
        finishedDate: input.finishedDate,
        repeatCount: input.repeatCount,
        repeatDay: input.repeatDay,
        repeatDayOfWeek: input.repeatDayOfWeek,
        repeatMonth: input.repeatMonth,
        customizeType: input.customizeType,
        repeatType: input.customizeType
      });
    } else {
      this.editDate = false;
    }
  }

  updateTask() {
    this.configModalEditWhenConfirm.disabledButton = true;

    this.service.updateWhen({
      id: this.request.id
    }, this.modalConfirmEditWhen)
      .then(() => {
        this.openSnackBar(this.translate.instant("The task has been reprograming"), '✓');
        this.modalConfirmEditWhen = null;
        this.editDate = false;
        this.configModalEditWhenConfirm.disabledButton = false;
      }).catch(err => {
        console.error(err);
        this.configModalEditWhenConfirm.disabledButton = false;
      });
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  deleteComment(comment) {
    this.configModalDeleteComment.disabledButton = true;
    this.service
      .deleteComment({
        requestId: this.request.id,
        id: comment._id,
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Comment deleted"),
          "✓"
        );
        this.modalDeleteComment = null;
        this.configModalDeleteComment.disabledButton = false;
      }).catch(err => {
        console.error(err);
        this.configModalDeleteComment.disabledButton = false;
      });
  }

  cancelTask() {
    this.configModalCanceled.disabledButton = true;

    this.service.updateStatusCancelled({
      id: this.request.id
    }, {
      cancelledType: this.request.customizeType == 'no-repeat'
        ? "only"
        : this.configModalCanceled.radio.find(r => r.checked).value
    }).then(() => {
      if (this.request.customizeType == 'no-repeat') {
        this.openSnackBar(this.translate.instant("The task has been cancelled"), '✓');
      } else {
        if (this.configModalCanceled.radio.find(r => r.checked).value == 'only') {
          this.openSnackBar(this.translate.instant("The ticket has been cancelled"), '✓');
        } else {
          this.openSnackBar(this.translate.instant("The task has been cancelled"), '✓');
        }
        this.configModalCanceled.radio = this.configModalCanceled.radio.map((r, i) => Object.assign({}, r, {
          checked: i == 0 ? true : false
        }));
      }
      this.modalDeleteTicket = false;

      this.configModalCanceled.disabledButton = false;
    }).catch(err => {
      console.error(err);
      this.configModalCanceled.disabledButton = false;
    });
  }

  takePicture(base) {
    let input = new FormData();
    input.append('web', this.b64toBlob(base, 'image/jpg'), 'photo-');
    input.append("containerName", "tasks");
    this.service.uploadImgs(input).then(res => {
      console.warn(res)
    })
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  isRecurrenceChanged() {
    return Object.keys(this.configModalConfirmUpdateAll.config)
      .filter(k => [
        'executionDate', 'customizeType', 'repeatType', 'repeatCount', 'repeatDayOfWeek', 'repeatDayOfWeek',
        'repeatDay', 'repeatMonth', 'startingDate', 'finishedDate', 'repeatCount', 'repeatDay', 'accumulate'
      ].includes(k)).length > 0
  }

  checkBeforeUpdate() {
    this.modalConfirmUpdateAll = false;
    if (this.request.customizeType === 'no-repeat' || this.isRecurrenceChanged()) this.saveUpdateAll()
    else this.modalConfirmUpdate = true;
  }

  saveUpdateAll() {
    this.configModalConfirmUpdateAll.disabledButton = true;

    this.service.updateGeneral(this.request.id, {
      ...this.configModalConfirmUpdateAll.config,
      changeType: this.request.customizeType === 'no-repeat'
        ? "only"
        : this.isRecurrenceChanged() ? 'all' : this.configModalConfirmEditWhere.radio.find(r => r.checked).value,
    })
      .then(() => {
        delete this.configModalConfirmUpdateAll.config;
        this.configModalConfirmUpdateAll.disabledButton = false;
        this.modalConfirmUpdateAll = false;
        this.otherAction = null;
      }).catch(err => {
        console.error(err);
        this.disabledButtonUpdateAll = false;
        this.configModalConfirmUpdateAll.disabledButton = false;
      });
  }

}
