import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { format, isFuture, isPast } from 'date-fns';

@Component({
  selector: "app-notification-type-default",
  templateUrl: "./template.html",
  styleUrls: ["../../styles.scss", "./styles.scss"],
})
export class AppNotificationTypeDefault implements OnInit {
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() notification;
  @Input() isPopup = false;
  loading = true;
  langug = "en";
  timeExceed = false;

  constructor(
    public translate: TranslateService
  ) {
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnInit() {
    this.loading = false;
    if(!this.notification.read && !isFuture(new Date(this.notification.metadata.endDateInStatus))){
      this.timeExceed = true; 
    } else {
      this.timeExceed = false; 
    }
  }

  onActionEvent() {
    this.onAction.emit({
      id: this.notification.id,
      action: 'open-modal',
      module: this.notification.module,
      modeOpen: 'detail',
      requestId: this.notification.requestId
    });
  }

  splitName(fullName) {
    const partes = fullName.replace(/ +(?= )/g, "").split(" ");
    const iniciales = partes.map(parte => parte.charAt(0)).join(". ");
    const nombreAbreviado = partes.length > 1 ? `${iniciales[0]}. ${partes[1]}` : fullName;
    return (nombreAbreviado);
  }

  lastModificationDate(date: Date): string {
    const differentSecounds = (new Date().getTime() / 1000) - (new Date(date).getTime() / 1000);

    if (differentSecounds < 60) {
      return this.translate.instant("a few seconds ago");
    } else if (differentSecounds >= 60 && (differentSecounds / 60) < 60) {
      return parseInt((differentSecounds / 60).toString()) == 1
        ? this.translate.instant("minute ago", { n: parseInt((differentSecounds / 60).toString()) })
        : this.translate.instant("minutes ago", { n: parseInt((differentSecounds / 60).toString()) });
    } else if ((differentSecounds / 60) >= 60 && (differentSecounds / 3600) < 24) {
      return parseInt((differentSecounds / 60 / 24).toString()) == 1
        ? this.translate.instant("hour ago", { n: parseInt((differentSecounds / 3600).toString()) })
        : this.translate.instant("hours ago", { n: parseInt((differentSecounds / 3600).toString()) });
    } else if ((differentSecounds / 3600) >= 24 && (differentSecounds / 86400) < 2) {
      return this.translate.instant("yesterday");
    } else {
      return format(new Date(date), "dd").concat(' ').concat(this.translate.instant(format(new Date(date), "MMM"))).concat(' ').concat(this.translate.instant(format(new Date(date), "yyyy")));
    }
  }
}
