import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { CheckInService } from "../service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MaterialModule } from "../../../material.module";

import { CheckInPreviewDetailModule } from "./detail/module";
import { CheckInPreviewHistoryModule } from "./history/module";
import { CheckInPreviewCommentsModule } from "./comments/module";
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { ComponentModalConfirmedModule } from "../../../components/modal-confirmed/module";

@NgModule({
  declarations: [
    MyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    Ng2SearchPipeModule,
    CheckInPreviewCommentsModule,
    CheckInPreviewHistoryModule,
    CheckInPreviewDetailModule,
    ComponentModalConfirmedModule
  ],
  exports: [
    MyComponent
  ],
  providers: [CheckInService],
})
export class CheckInPreviewModule {}
