import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { differenceInSeconds, isFuture, isPast } from "date-fns";

@Component({
  selector: "app-requests-manager-timer",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit, OnChanges {
  @Output() state = new EventEmitter<string>();
  @Input() startDate: number;
  @Input() endDate: number;
  @Input() percentageWarning: number = 25;
  @Input() percentageDanger: number = 10;
  @Input() preview: boolean;
  @Input() countSumate: number;

  percentage: number;
  timeLeft: {
    negative: boolean;
    year: string;
    month: string;
    week: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
  } = {
      negative: false,
      year: "00",
      month: "00",
      week: "00",
      day: "00",
      hour: "00",
      minute: "00",
      second: "00",
    };
  stateLocal: string = "successful";

  constructor(public translate: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.initComponent();
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    let total = differenceInSeconds(
      new Date(this.endDate),
      new Date(this.startDate)
    );
    if (!isFuture(new Date(this.startDate))) {
      // Si el start date ya comenzo
      let current = differenceInSeconds(new Date(this.endDate), new Date());
      this.percentage = (current * 100) / total;
      this.timeLeft = this.secondsToString(current.toString());
      if (!this.timeLeft.negative) {
        if (this.percentage <= this.percentageWarning) {
          this.stateLocal = "warning";
        } else if (this.percentage <= this.percentageDanger) {
          this.stateLocal = "danger";
        } else {
          this.stateLocal = "successful";
        }
      } else {
        this.stateLocal = "warning";
      }
      this.state.emit(this.stateLocal);
    } else {
      // Si el start date esta en el futuro no ha comenzado.
      this.percentage = 100;
      this.timeLeft = this.secondsToString(total.toString());
      this.stateLocal = this.timeLeft.negative ? "danger" : "successful";
      this.state.emit(this.stateLocal);
    }
  }

  secondsToString(seconds: string) {
    const negative = parseInt(seconds) < 0;
    seconds = Math.abs(parseInt(seconds)).toString(); // Aseguramos que los segundos sean positivos para el cálculo

    // Cálculos corregidos para cada unidad de tiempo y convertidos a string
    const year = Math.floor(parseInt(seconds) / 3.154e7).toString();
    const month = Math.floor((parseInt(seconds) % 3.154e7) / 2.628e6).toString();
    const week = Math.floor((parseInt(seconds) % 2.628e6) / 604800).toString();
    const day = Math.floor((parseInt(seconds) % 604800) / 86400).toString();
    const hour = Math.floor((parseInt(seconds) % 86400) / 3600).toString();
    const minute = Math.floor((parseInt(seconds) % 3600) / 60).toString();
    const second = (parseInt(seconds) % 60).toString();

    return { negative, year, month, week, day, hour, minute, second };
  }

  calculate(time) {
    const units = [
      { label: 'year', value: Number(time.year) },
      { label: 'month', value: Number(time.month) },
      { label: 'week', value: Number(time.week) },
      { label: 'day', value: Number(time.day) },
      { label: 'hour', value: Number(time.hour) },
      { label: 'minute', value: Number(time.minute) },
      { label: 'second', value: Number(time.second) }
    ];

    // Filtrar las unidades con valores mayores a cero
    const relevantUnits = units.filter(unit => unit.value > 0);

    // Seleccionar las dos unidades más altas no cero
    const highestUnits = relevantUnits.slice(0, 2);

    // Construir la cadena de retorno
    return highestUnits
      .map(unit => `${unit.value}${unit.label.charAt(0)}`) // Formatear como 'value + primera letra de la unidad'
      .join(' '); // Unir las unidades para formar el resultado final
  }
}
