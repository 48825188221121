<div class="modal-content text-inicial white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob"
        style="margin-bottom: 5px; margin-top: 8px !important; padding-left: 8px !important;">
        <i class="material-icons left pointer color-base-two" (click)="cancel()">chevron_left</i>
    </div>
    <h2 class="col s12 color-base font24 blod modal-padding-leftright marg-top marg-bot">
        {{ 'Edit Task' | translate }} #{{ request.idReference }}
    </h2>
    <div class="clearfix"></div>
    <div class=" modal-padding-leftright scroll-hei">
        <div class="row card--paddincreate padding0" style="margin-top: 16px;">
            <div class="left circle-icon circle-who">
                <i class="material-icons">person</i>
            </div>
            <div class="col s9" style="padding-right: 0; width: 89%">
                <h3 class="padding0 font12  labels  label" style="margin-top: 2rem; margin: 0px !important;"
                    [ngClass]="{'helperText':!data.assignedId && dirty , 'active': focus}">
                    {{ 'Who should perform this task' | translate | uppercase}}
                </h3>

                <div class="col s12 padding0" style="display: flex;">
                    <input required [ngClass]="{'requeride': !data.assignedId && dirty }"
                        class="validate h-input clr-inp" (blur)="dirty = true ; focus = false" (focus)="focus = true"
                        #onFocusInputWho placeholder="{{ 'Person or department responsible' | translate }}" matInput
                        (keyup)="searchResultWho($event)" [matAutocomplete]="auto" id="who"
                        [value]="data.assignedName" />
                    <a class="cont__remove" href="javascript:;" (click)="clearWho()" *ngIf="searchTxt.length > 0">
                        <i class="material-icons pointer color-base-two cancel-icon icon__remove">clear</i>
                    </a>
                    <mat-autocomplete class="test-class" classlist="test-class" (optionSelected)="setWho($event)"
                        #auto="matAutocomplete">
                        <mat-option class="loading" *ngIf="loadingWho">
                            <mat-spinner style="left: 50%; transform: translateX(-50%);" diameter="35"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!loadingWho && (list.users.length > 0 || list.departments.length > 0)">
                            <mat-optgroup *ngIf="list.users && list.users.length > 0" [label]="'Users' | translate">
                                <mat-option *ngFor="let item of list.users" [value]="item">
                                    {{ item.firstName }} {{ item.lastName }}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup *ngIf="list.departments && list.departments.length > 0"
                                [label]="'Departments' | translate">
                                <mat-option *ngFor="let item of list.departments" [value]="item">
                                    {{ item.name }}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                        <ng-container *ngIf="!loadingWho && list.users.length == 0 && list.departments.length == 0 ">
                            <mat-option disabled="true">
                                {{ 'There are no results for the search criteria used' | translate }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
                <div class="helperText" *ngIf="!data.assignedId && dirty">
                    {{ 'You must select at least one option' | translate }}
                </div>
            </div>
        </div>

        <div class="row card--paddincreate padding0" style="margin-top: 16px;">
            <div class="left circle-icon circle-where">
                <i class="material-icons">location_on</i>
            </div>
            <div class="col s9" style="padding-right: 0; width: 89%">
                <h3 class="padding0 font12 labels-Where" style="margin: 0px !important;"
                    [ngClass]="{'helperText':!data.whereName && dirtyWhere , 'active': focusWhere}">
                    {{ 'Where' | translate | uppercase}}
                </h3>
                <div class="col s12 padding0" style="display: flex;">
                    <input required [ngClass]="{'requeride': !data.whereName && dirty }"
                        class="validate h-input clr-inp" (blur)="dirtyWhere = true ; focusWhere = false"
                        (focus)="focusWhere = true" #onFocusInputWhere (keyup)="searchResultWhere($event)"
                        placeholder="{{ 'Room number or public area' | translate }}" matInput [matAutocomplete]="where"
                        id="where" [value]="data.whereName" />
                    <a class="cont__remove" href="javascript:;" (click)="clearWhere()" *ngIf="searchTxt.length > 0">
                        <i class="material-icons pointer color-base-two cancel-icon icon__remove">clear</i>
                    </a>
                    <mat-autocomplete class="test-class" (optionSelected)="setWhere($event)" classlist="test-class"
                        #where="matAutocomplete">
                        <mat-option class="loading" *ngIf="loadingWhere">
                            <mat-spinner style="left: 50%; transform: translateX(-50%);" diameter="35"></mat-spinner>
                        </mat-option>
                        <ng-container *ngIf="!loadingWhere && (list.rooms.length > 0 || list.publicAreas.length > 0)">
                            <mat-optgroup *ngIf="list.rooms && list.rooms.length > 0" [label]="'Rooms' | translate">
                                <mat-option *ngFor="let item of list.rooms" [value]="item">
                                    {{ item.name }}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup *ngIf="list.publicAreas && list.publicAreas.length > 0"
                                [label]="'Public area' | translate">
                                <mat-option *ngFor="let item of list.publicAreas" [value]="item">
                                    {{ item.name }}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                        <ng-container *ngIf="!loadingWhere && list.rooms.length == 0 && list.publicAreas.length == 0 ">
                            <mat-option disabled="true">
                                {{ 'There are no results for the search criteria used' | translate }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
                <div class="helperText" *ngIf="!data.whereName && dirty">
                    {{ 'You must select at least one option' | translate }}
                </div>
            </div>
        </div>

        <!-- When -->
        <div class="row card--paddincreate padding0" style="margin-top: 16px;">
            <div class="left circle-icon circle-when">
                <i class="material-icons">access_time</i>
            </div>
            <div class="col s9" style="padding: 0px; width: 89%">
                <div class="col s7">
                    <h3 class="padding0 font12 labels" style="margin: 0px !important;"
                        [ngClass]="{'active': focusWhen}">
                        {{ 'When should it be executed' | translate | uppercase}}
                    </h3>
                    <div class="col s12 padding0">
                        <input required [owlDateTime]="dt7" placeholder="{{ 'Now' | translate }}"
                            style="color: var(--gh-color-letter) !important" [owlDateTimeTrigger]="dt7"
                            [(ngModel)]="data.executionDate" [min]="minDate" (blur)="focusWhen = false; changeContent()"
                            (focus)="focusWhen = true;">
                        <owl-date-time [hour12Timer]="'true'" (afterPickerClosed)="onKeySearch(); changeContent()"
                            #dt7></owl-date-time>
                    </div>
                </div>
                <div class="col s5" style="padding: 0px;">
                    <h3 class="padding0 font12 labels" style="margin-top: 2rem; margin: 0px !important;">
                        {{ 'Recurrence' | translate | uppercase}}
                    </h3>
                    <div class="col s11 padding0" style="width: 100%; margin-top: 8px;">
                        <mat-select id="selectRecurrency" class="cellInput"
                            style=" border-bottom: 1px solid #9e9e9e !important; padding-top: 1.5px !important;"
                            [(ngModel)]="data.repeatType" (selectionChange)="changeRepeat($event.value)">
                            <mat-option value="no-repeat" selected>{{ 'Does not repeat' | translate }}</mat-option>
                            <mat-option value="day">{{ 'Diary' | translate }}</mat-option>
                            <mat-option value="week">{{ 'Weekly' | translate }}</mat-option>
                            <mat-option value="month">{{ 'Monthly' | translate }}</mat-option>
                            <mat-option value="year">{{ 'Yearly' | translate }}</mat-option>
                            <mat-option
                                (click)="this.data.repeatType === 'customized' ? changeRepeat('customized') : null"
                                value="customized">
                                {{'Custom' | translate }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div [ngSwitch]="data.customizeType" class="col s12 padding0 itememail">
                    <a class="cont__removeSub" href="javascript:;" (click)="clearWhen()">
                        <i style="font-size: 16px; line-height: 20px; margin-left: 4px;"
                            class="material-icons pointer color-base-two cancel-icon icon__remove">
                            close
                        </i>
                    </a>
                    <p style="text-align: left; margin-left: 0.7rem; margin: initial;">
                        <ng-containe *ngSwitchCase="'no-repeat'">
                            {{ 'This task is not repeat' | translate }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'day'">
                            <ng-container *ngIf="data.repeatCount == 1">
                                {{ 'Every day' | translate }}
                            </ng-container>
                            <ng-container *ngIf="data.repeatCount > 1">
                                {{ 'Each' | translate }} {{ data.repeatCount }} {{ 'days' | translate }}
                            </ng-container>
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'week'">
                            <ng-container *ngIf="data.repeatCount == 1">
                                {{ 'Every week on' | translate }}
                            </ng-container>
                            <ng-container *ngIf="data.repeatCount > 1">
                                {{ 'Each' | translate }} {{ data.repeatCount }} {{ 'weeks on' | translate }}
                            </ng-container>
                            {{ programmingRepeatService.allDaysRepeat(data) }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'month'">
                            <ng-container *ngIf="data.repeatCount == 1">
                                {{ 'Every month' | translate }}
                            </ng-container>
                            <ng-container *ngIf="data.repeatCount > 1">
                                {{ 'Each' | translate }} {{ data.repeatCount }} {{ 'months' | translate }}
                            </ng-container>
                            {{ 'the day' | translate }} {{ data.repeatDay }}
                        </ng-containe>
                        <ng-containe *ngSwitchCase="'year'">
                            <ng-container *ngIf="data.repeatCount == 1">
                                {{ 'Every year' | translate }}
                            </ng-container>
                            <ng-container *ngIf="data.repeatCount > 1">
                                {{ 'Each' | translate }} {{ data.repeatCount }} {{ 'years' | translate }}
                            </ng-container>
                            {{ 'the day' | translate }} {{ data.repeatDay }}
                            {{ programmingRepeatService.getMonthDetail(data) }}
                        </ng-containe>
                        <ng-containe *ngIf="data.customizeType != 'no-repeat'">
                            {{ 'starting on' | translate }} {{ data.startingDate | date:'dd MMM yyyy':'TZ' :
                            langug}}
                            <ng-containe *ngIf="data.finishedDate">
                                {{ 'ending the' | translate }} {{ data.finishedDate | date:'dd MMM yyyy':'TZ'
                                :langug}}
                            </ng-containe>
                        </ng-containe>
                    </p>
                </div>

                <div style="margin-left: 0.5rem;" *ngIf="data.customizeType != 'no-repeat'"
                    class="col s6 l12 m12 xl12 padding0">
                    <label>
                        <input type="checkbox" class="filled-in" [checked]="data.accumulate"
                            [(ngModel)]="data.accumulate" />
                        <span
                            style="line-height: 20px !important; margin-top: 0.5rem; padding-left: 25px !important; color: #686C6D;"
                            class="textapplysugget"> {{ 'Repeated tasks accumulate on the monitor' | translate }}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="nopadding mygavete-buttons">
        <div class="right-align">
            <button (click)="cancel()" class="btn btn-falt-ant btn-small button-cancel" container="body"
                placement="top">
                {{ "Cancel" | translate }}
            </button>
            <button (click)="openDialogConfirm()" [disabled]="disabledButton"
                class="btn btn-falt-ant btn-small button-save" container="body" placement="top">
                {{ "Save" | translate }}
            </button>
        </div>
    </div>
</div>