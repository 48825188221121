<div class="modal_right_0 white up_right mygavete" *ngIf="otherAction === 'cancelled'">
  <div class="modal-content white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob" style="margin-bottom: 22px;">
      <i class="material-icons left pointer color-base-two"
        (click)="otherAction = null; optionCancelSelected = null">arrow_back</i>
    </div>

    <h2 class="col s12 color-base font24 blod modal-padding-leftright mod__mob marg-top">
      {{ 'Why do you want to cancel the order?' | translate }}
    </h2>
    <div class="col s12 modal-padding-leftright mod__mob">
      <span class="color-base font16">
        {{ 'Select from the list the option for which you cancel the order:' | translate}}
      </span>
    </div>

    <div class="collection options-cancelorders modal-padding-leftright mod__mob">
      <a class="collection-item semiblod" *ngFor="let option of optionsCancel" (click)="optionCancelSelected = option"
        [ngClass]="{'active': optionCancelSelected == option}">
        {{ option[language] }}
      </a>
    </div>

    <div class="modal-footer right-align modal-padding-leftright mod__mob">
      <button (click)="otherAction = null; optionCancelSelected = null" class="btn btn-large button-cancel"
        container="body" placement="top">
        {{ "Back" | translate }}
      </button>
      <button style="height: auto; line-height: 35px;" (click)="cancelOrder()" class="btn btn-large button-save"
        [disabled]="!optionCancelSelected" container="body" placement="top">
        {{ "Cancel Order" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="modal_right_0 white up_right mygavete" *ngIf="!otherAction">
  <div class="modal-content white" *ngIf="!loading">
    <div class="col s12 modal-margin-top modal-padding-leftright mod__mob">
      <i class="material-icons right pointer color-base-two cancel-icon" (click)="closeModal()">clear</i>
    </div>

    <div class="modal-title-container modal-padding-leftright"
      style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
      <h2 class="color-base font24 blod mod__mob marg-top" style="margin-bottom: 5px; flex-grow: 1;">
        {{ 'Order' | translate }} #{{ request.idReference }}
      </h2>
    </div>

    <div class="col s12 padding0 modal-padding-leftright mod__mob" style="height: 60px;">
      <ul class="cont__mb__ul">
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='detail'"
          [ngClass]="{'active':tabSelected=='detail'}">
          {{ 'Detail' | translate }}
        </li>
        <li class="tab col s3 pointer comment-he mb__li__cont" (click)="tabSelected='comments'"
          [ngClass]="{'active':tabSelected=='comments'}">
          {{ 'Messages' | translate }}
          <span class="right notification-red" *ngIf="countComments() > 0">{{ countComments() }}</span>
        </li>
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='history'"
          [ngClass]="{'active':tabSelected=='history'}">
          {{ 'History' | translate }}
        </li>
      </ul>
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="modal-content modal-body" [ngSwitch]="tabSelected" *ngIf="!loading">
    <app-outlets-detail *ngSwitchCase="'detail'" [request]="request" [countSumate]="countSumate"
      (otherActionEvent)="otherActionEvent($event)">
    </app-outlets-detail>
    <app-outlets-comments *ngSwitchCase="'comments'" [request]="request" [comments]="request.comments"
      (eventDeleteComment)="modalDeleteComment = $event;">
    </app-outlets-comments>
    <app-outlets-history *ngSwitchCase="'history'" [id]="request.id" [history]="request.history">
    </app-outlets-history>
    <div class="clearfix"></div>
  </div>

  <div class="cols12 l12 xl12 m12 center" *ngIf="loading">
    <div class="col s12">
      <br><br>
    </div>
    <div class="loadingdiv">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-overlay pointer ups" (click)="closeModal()"></div>

<app-requests-preview-update-finished-returned class="modal_right_0 white up_right mygavete"
  *ngIf="otherAction && otherAction == 'finished-returned'" [request]="request" (output)="openReturnedConfirm($event)"
  (otherActionEvent)="otherActionEvent($event)" [title]="'Finish returned order'" [disabledButton]="disabledButton"
  (cancelEvent)="otherAction = null"></app-requests-preview-update-finished-returned>

<app-guesthub-component-modal-confirmed *ngIf="modalProccessed" [config]="configModalProccessed"
  (confirm)="goToProcess()" (cancel)="modalProccessed = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalDeleteComment" [config]="configModalDeleteComment"
  (confirm)="deleteComment(modalDeleteComment)"
  (cancel)="modalDeleteComment = null"></app-guesthub-component-modal-confirmed>