<div class="modal_right_0 white up_right mygavete" *ngIf="otherAction === 'view-other-detail'">
  <div class="modal-content white">
    <div class="col s12 modal-margin-top modal-padding-leftright mod__mob">
      <i class="material-icons left pointer color-base-two" (click)="otherAction = null;">arrow_back</i>
    </div>

    <app-guests-check-in-preview [actionBeforeOpen]="'open'" [item]="companionSelected" [otherDetail]="true"
      (exitOutput)="viewPreview=undefined;itemSelected=undefined" (backEventIntoRequest)="otherAction = null">
    </app-guests-check-in-preview>
  </div>
</div>
<div class="modal_right_0 white up_right mygavete" *ngIf="otherAction === 'declined'">
  <div class="modal-content white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob" style="margin-bottom: 22px;">
      <i class="material-icons left pointer color-base-two" (click)="otherAction = null;">arrow_back</i>
    </div>

    <h2 class="col s12 color-base font24 blod modal-padding-leftright mod__mob marg-top">
      {{ 'What data must be corrected?' | translate }}
    </h2>

    <span class="col s12 modal-padding-leftright cont__sub__padd">{{'Select the data that the guest must correct' |
      translate}}</span>

    <app-guests-check-in-preview-detail [declineTicket]="true" [request]="request" [countSumate]="countSumate"
      (otherActionEvent)="otherActionEvent($event)" (declineListEvent)="listDeclineObj = $event">
    </app-guests-check-in-preview-detail>

    <div class="modal-footer right-align modal-padding-leftright modal__cont__footer mod__mob">
      <button (click)="otherAction = null;" class="btn btn-small button-cancel" container="body" placement="top">
        <span class=" font__cancel">{{ "Cancel" | translate }}</span>
      </button>
      <button (click)="declineTicket()" class="btn btn-small button-save" container="body" placement="top"
        [disabled]="listDeclineObj.length == 0">
        <span class="font__butt">{{ "Save" | translate }}</span>
      </button>
    </div>
  </div>
</div>

<div class="modal_right_0 white up_right mygavete" *ngIf="otherAction === 'confirmed'">
  <div class="modal-content white" *ngIf="!loading">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob" style="margin-bottom: 22px;">
      <i class="material-icons left pointer color-base-two" (click)="otherAction = null;">arrow_back</i>
    </div>

    <h2 class="col s12 color-base font24 blod modal-padding-leftright mod__mob marg-top">
      {{ 'Enter the room number' | translate }}
    </h2>

    <span class="col s12 modal-padding-leftright cont__sub__padd">{{'To confirm check in enter the assigned room number'
      | translate}}</span>

    <div class=" row card--paddincreate padding0 scroll-hei modal-padding-leftright">

      <div class="col s12 l12 xl12 padding0">
        <span class="col s12 m12 xl12 l12 padding0 font12 regular padding-top14"
          [ngClass]="{'green-text':searchRoomNumber ,'color-base-two':!searchRoomNumber }">
          {{'ROOM NUMBER'| translate | uppercase}}
        </span>

        <input class="classinput margin0 pointer"
          (keyup)="searchRoomNumber = $event.target.value; selectedRoomNumber = null;" type="text"
          [(value)]="searchRoomNumber" placeholder="{{ 'Select room' | translate }}" matInput [matAutocomplete]="auto"
          id="sender" />
        <mat-autocomplete class="test-class" classlist="test-class" #auto="matAutocomplete">
          <mat-option *ngFor="let room of listRooms | filter: searchRoomNumber" [value]="selectedRoomNumber"
            (click)="selectRoomNumber(room)">
            <span class="color-base-two">
              {{ room.room }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>


    <div class="modal-footer right-align modal-padding-leftright modal__cont__footer mod__mob">
      <button (click)="otherAction = null;" class="btn btn-small button-cancel" container="body" placement="top">
        <span class="font__cancel">{{ "Cancel" | translate }}</span>
      </button>
      <button (click)="confirmOrder()" class="btn btn-small button-save" container="body" placement="top">
        <span class="font__butt">{{ "Save" | translate }}</span>
      </button>
    </div>
  </div>
</div>
<div class="{{!otherDetail ? 'modal_right_0 white up_right mygavete': ''}}" *ngIf="!otherAction">
  <ng-container *ngTemplateOutlet="previewDetail">
  </ng-container>
</div>
<div class="modal-overlay pointer ups" *ngIf="!otherDetail" (click)="closeModal()"></div>

<ng-template #previewDetail>
  <div class="{{!otherDetail ? 'modal-content white' : ''}}" *ngIf="!loading">
    <div class="col s12 modal-margin-top modal-padding-leftright mod__mob" *ngIf="!otherDetail">
      <i class="material-icons right pointer color-base-two cancel-icon" (click)="closeModal()">clear</i>
    </div>

    <h2 class="col s12 color-base font24 blod modal-padding-leftright mod__mob marg-top" style="margin-bottom: 5px;">
      {{ 'Nº Confirmation' | translate }} #{{ request.reservationId ? request.reservationId : request.idReference }}
    </h2>

    <div class="col s12 padding0 modal-padding-leftright mod__mob" style="height: 60px;">
      <ul class="cont__mb__ul">
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='detail'"
          [ngClass]="{'active':tabSelected=='detail'}">
          {{ 'Detail' | translate }}
        </li>
        <li class="tab col s3 pointer comment-he mb__li__cont" (click)="tabSelected='comments'"
          [ngClass]="{'active':tabSelected=='comments'}">
          {{ 'Messages' | translate }}
          <span class="right notification-red" *ngIf="countComments() > 0">{{ countComments() }}</span>
        </li>
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='history'"
          [ngClass]="{'active':tabSelected=='history'}">
          {{ 'History' | translate }}
        </li>
        <!-- <li class="donwload__icon pointer" (click)="exportPDF()">
          <i class="material-icons icon__down">download</i>
        </li> -->
      </ul>
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="{{!otherDetail ? 'modal-content' : '' }}" [ngSwitch]="tabSelected" style="height: 100%;" *ngIf="!loading">
    <app-guests-check-in-preview-detail *ngSwitchCase="'detail'" [request]="request" [countSumate]="countSumate"
      [otherDetail]="otherDetail" (otherActionEvent)="otherActionEvent($event)"
      (companionSelectedEvent)="companionSelected = $event">
    </app-guests-check-in-preview-detail>
    <app-guests-check-in-preview-comments *ngSwitchCase="'comments'" (eventDeleteComment)="modalDeleteComment = $event;"
      [request]="request" [comments]="request.comments">
    </app-guests-check-in-preview-comments>
    <app-guests-check-in-preview-history *ngSwitchCase="'history'" [id]="request.id" [history]="request.history">
    </app-guests-check-in-preview-history>
    <div class="clearfix"></div>
  </div>

  <div class="cols12 l12 xl12 m12 center" *ngIf="loading">
    <div class="col s12">
      <br><br>
    </div>
    <div class="loadingdiv">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-guesthub-component-modal-confirmed *ngIf="modalDeleteComment" [config]="configModalDeleteComment"
  (confirm)="deleteComment(modalDeleteComment)"
  (cancel)="modalDeleteComment = null"></app-guesthub-component-modal-confirmed>