import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { CheckInService } from "../../service";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from "../../../../components/directives/module";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    Ng2SearchPipeModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [MyComponent],
  providers: [CheckInService],
})
export class CheckInPreviewCommentsModule {}
