<div class="row card__notifi  pointer margin0" (click)="onActionEvent()"
    [ngClass]="{'noview': !notification.read, 'pop__up': isPopup}">
    <div class="title-area" [ngClass]="{'time__exceed': timeExceed}">
        {{ splitName(notification.metadata.authorName || '') }} <span class="txt__normal" [ngClass]="{'time__exceed': timeExceed}"> {{ notification.metadata.action | translate }} - </span>
        {{ notification.metadata.what }}
    </div>
    <div>
        <span class="circle">
            <i *ngIf="notification.icon" class="material-icons">{{notification.icon}}</i>
        </span>
        <span class="asigneName">{{splitName(notification.metadata.who || '')}},</span>
        <span class="placeName">{{notification.metadata.where}}</span>
    </div>
    <div>
        <span class="dateName" [ngClass]="{'time__exceed': timeExceed}">
            <i class="material-icons icon__war" *ngIf="timeExceed">warning</i>
            {{ lastModificationDate(notification.creationDate)}}
        </span>
        <span class="txt__id" [ngClass]="{'txt__id__view': notification.read}">
            {{notification.idReference}}
        </span>

    </div>