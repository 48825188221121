import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OutletsDetailService } from "./service";
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Auth0Service } from "../../../auth0.service";
import { format } from 'date-fns';
@Component({
  selector: "app-outlets-detail",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class OutletsDetailDetailComponent implements OnInit {
  @Output() otherActionEvent = new EventEmitter<String>();
  @Input() request: any;
  @Input() countSumate: number;
  editRoom: boolean;
  info: any;
  langug: string = "en";
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: OutletsDetailService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
  ) {
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnInit() {
    this.info = this.auth.userProfile;

  }

  update(status) {
    if (this.request.state == 'validate' && this.request.statusCodename == 'validate' && status.codename == 'cancelled') {
      this.otherActionEvent.emit('cancelled');
    } else if (this.request.state == 'validate' && this.request.statusCodename == 'cancelled' && status.codename == 'processed') {
      this.otherActionEvent.emit('processed');
    } else if (status.codename == 'delivered' && this.request.statusCodename == 'returned') {
      this.otherActionEvent.emit("returned");
    } else {
      this.request.statusName = status.name;
      this.request.statusCodename = status.codename;
      this.request.statusColorHex = status.colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(status.codename), 1);
      this.service.update({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, {
        codename: this.request.statusCodename
      }).then(() =>
        this.request.statusCodename == 'processed'
          ? this.openSnackBar(this.translate.instant("The order has been processed"), '✓')
          : this.request.statusCodename == 'delivered'
            ? this.openSnackBar(this.translate.instant("The order has been delivered"), '✓')
            : null
      );
    }
  }
  updateRoom(type, value) {
    if (value && value.toString().trim().length > 0) {
      this.editRoom = false;
      this.service.updateWhere({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, { where: value.toString().trim() })
        .then(() =>
          this.openSnackBar(this.translate.instant("Updated order"), '✓')
        );
    } else {
      this.openSnackBar(this.translate.instant("Field where is required"), '✖');
    }
  }

  updateStateTime(state: string) {
    this.request = Object.assign({}, this.request, {
      stateTime: state
    });
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  modificationDateTranslate(date: Date, noHours?: boolean): string {
    return `${this.translate.instant(format(new Date(date), "MMM"))} ${format(new Date(date), "dd")} ${format(new Date(date), "yyyy")}${!noHours ? ' ' + format(new Date(date), "h:mm a") : ''}`
  }
}
