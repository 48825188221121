import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CheckInService } from "../../service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../../../auth0.service";

@Component({
  selector: "app-guests-check-in-preview-detail",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit {
  @Output() companionSelectedEvent = new EventEmitter<String[]>();
  @Output() declineListEvent = new EventEmitter<String[]>();
  @Output() otherActionEvent = new EventEmitter<String>();
  @Input() declineTicket: boolean = false;
  @Input() otherDetail: boolean;
  @Input() request: any;
  @Input() countSumate: number;
  editRoom: boolean;
  info: any;
  editionCheck: any = [];
  otherAction: any = null;
  listDeclineObj: String[] = [];
  langug: string = "en";
  offset: number;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: CheckInService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private auth: Auth0Service,
  ) {
    this.offset = (new Date().getTimezoneOffset());
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  selectDecline(obj: String, checked: boolean = true) {
    if (checked) {
      this.listDeclineObj.push(obj);
    } else {
      this.listDeclineObj = this.listDeclineObj.reduce((a, c) => {
        if (c != obj) {
          a.push(c);
        }
        return a;
      }, []);
    }
    this.declineListEvent.emit(this.listDeclineObj);
  }

  viewDetailCompanion(companion) {
    if (this.otherDetail && this.request.requestFather) {
      this.otherActionEvent.emit('back');
    } else {
      this.companionSelectedEvent.emit(companion);
      this.otherActionEvent.emit('view-other-detail');
    }
  }

  isDecline(obj: String): boolean {
    return this.listDeclineObj.find(s => s == obj) ? true : false;
  }

  ngOnInit() {
    this.info = this.auth.userProfile;

  }

  update(status) {
    if (status.codename == 'confirmed') {
      this.otherActionEvent.emit('confirmed');
    } else if
      (status.codename == 'declined') {
      this.otherActionEvent.emit('declined');
    } else {
      this.request.statusName = status.name;
      this.request.statusCodename = status.codename;
      this.request.statusColorHex = status.colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(status.codename), 1);
      this.service.update({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, {
        codename: this.request.statusCodename
      }).then(() =>
        this.request.statusCodename == 'accepted'
          ? this.openSnackBar(this.translate.instant("The ticket has been processed"), '✓')
          : this.request.statusCodename == 'confirmed'
            ? this.openSnackBar(this.translate.instant("The ticket has been confirmed"), '✓')
            : null
      );
    }
  }
  updateRoom(type, value) {
    if (value && value.toString().trim().length > 0) {
      this.editRoom = false;
      this.service.updateWhere({
        outleType: this.request.type,
        outletId: this.request.outletId,
        id: this.request.id
      }, { where: value.toString().trim() })
        .then(() =>
          this.openSnackBar(this.translate.instant("Updated ticket"), '✓')
        );
    } else {
      this.openSnackBar(this.translate.instant("Field where is required"), '✖');
    }
  }

  updateStateTime(state: string) {
    this.request = Object.assign({}, this.request, {
      stateTime: state
    });
  }

  exportPDF(requestId: string, termId: string) {
    window.open(this.service.exportTermsAndConditionsPDF({
      requestId, termId
    }), "_blank");
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
