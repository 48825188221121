import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationHeaderTitleService } from "./service";
import { WelcomeService } from "../../../welcome/service";

@Component({
  selector: "app-navigation-header-title",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit {
  params;
  goBackDefault: string;
  isVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: NavigationHeaderTitleService,
    private welcomeService: WelcomeService
  ) { }

  ngOnInit() {
    this.params = this.service.params;
    this.router.events.subscribe((val) => {
      this.revalidVisibleTitle();
    });
    this.service.subscribe((options) => this.splitUriAndQuerys(options));

    this.welcomeService.list().then((modules) => {
      this.goBackDefault =
        modules.some((m) => m.firstLogin) ||
          !modules.find((m) => m.default && m.enabled)
          ? "settings/quicksetup"
          : modules.find((m) => m.default && m.enabled).urlLink;
    });
    this.revalidVisibleTitle();
  }

  isVisibleGoBackButton(): boolean {
    return this.params
      ? this.params.goback
        ? this.params.goback.value
          ? true
          : this.goBackDefault
            ? this.goBackDefault &&
              "/"
                .concat(this.goBackDefault)
                .toLowerCase()
                .search(this.router.url.toLowerCase()) > -1
              ? false
              : true
            : false
        : false
      : false;
  }

  goBackUrl() {
    this.router.navigate(
      [
        this.params
          ? this.params.goback
            ? this.params.goback.value
              ? this.params.goback.value
              : this.goBackDefault
            : this.goBackDefault
          : this.goBackDefault,
      ],
      { queryParams: this.params.goback.params }
    );
  }

  splitUriAndQuerys(options: any) {
    if (options) {
      this.params = options;
      if (
        options.goback &&
        options.goback.value &&
        options.goback.value.split("?").length > 1
      ) {
        this.params.goback.params = JSON.parse(
          '{"' +
          decodeURI(options.goback.value.split("?")[1])
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
        );
        this.params.goback.value = options.goback.value.split("?")[0];
      } else if (
        this.params.goback &&
        this.params.goback.value &&
        this.params.goback.params
      ) {
        this.params.goback.value =
          options.goback && options.goback.value ? options.goback.value : null;
        this.params.goback.params = options.goback.params;
      }
      if (this.params.progressBar && this.params.progressBar.sleep) {
        this.params.progressBar = Object.assign({}, this.params.progressBar, {
          percentage:
            this.params.progressBar && this.params.progressBar.sleep
              ? (this.params.progressBar.sleep.current * 100) /
              this.params.progressBar.sleep.all +
              "%"
              : this.params.progressBar.percentage + "%",
        });
      }
    } else {
      this.params = this.service.params;
    }
    this.revalidVisibleTitle();
  }

  revalidVisibleTitle() {
    this.isVisible = !window.location.pathname.includes("/outlet/")
      && !window.location.pathname.includes("/settings/quicksetup")
      && !window.location.pathname.includes("/settings/features")
      && (window.location.pathname.includes('settings/guest-app') && window.location.pathname.includes('settings/guest-app/automatic-emails')
        ? true
        : !window.location.pathname.includes('settings/guest-app')
      )
      && !window.location.pathname.includes("/settings/check-in")
      && !window.location.pathname.includes("/settings/rooms")
      && !window.location.pathname.includes("/settings/property-information")
      && !window.location.pathname.includes("/guests/check-in")
      && !window.location.pathname.includes("/guest-items/lost-found")
      && !window.location.pathname.includes("/guest-items/packages-new")
      && !window.location.pathname.includes("/communications/guestapp")
      && !window.location.pathname.includes("/communications/check-in")
      && (window.location.pathname.includes("/requests/")
        ? true
        : !window.location.pathname.includes("/requests"))
      && !window.location.pathname.includes("/tasks")
      && !window.location.pathname.includes("/settings/communications/notifications")
      && !window.location.pathname.includes("/dashboard")
      && !window.location.pathname.includes("/settings/communications/ai-configuration")
      && !window.location.pathname.includes("/settings/workflow")
      && !window.location.pathname.includes("/communications/workflow")
      && !window.location.pathname.includes("/communications/promotions")
      && !window.location.pathname.includes("/communications/in-stay-feedback")
      && !window.location.pathname.includes("/settings/public-areas")
      && !window.location.pathname.includes("/settings/guest-app/in-stay-feedback")
      && (window.location.pathname.includes("/settings/rules/create")
        ? true
        : !window.location.pathname.includes("/settings/rules"));
  }
}
