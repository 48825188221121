<div *ngIf="notification.metadata?.action !== 'comments-tagged'"  class="row card__notifi  pointer margin0" (click)="onActionEvent()"
    [ngClass]="{'noview': !notification.read, 'pop__up': isPopup}">
    <div class="title-area" [ngClass]="{'time__exceed': timeExceed}">
        {{ splitName(notification.metadata.authorName || '') }}
        <span class="txt__normal" [ngClass]="{'time__exceed': timeExceed}">
            {{ (notification.metadata.action) | translate }}
            {{ notification.module != 'pickup' && (notification.metadata.authorName || '').lenght > 0 ? " - " : "" }}
        </span>
        {{ notification.metadata.what }}
    </div>
    <div>
        <span class="circle" *ngIf="notification.icon"
            [ngClass]="{'feedback-red': notification.icon ==='mood_bad', 'feedback-green' : notification.icon ==='mood' }">
            <i class="material-icons icon">{{notification.icon}}</i>
            <i class="material-icons badge-icon" *ngIf="notification.metadata.reservationVip">star</i>
        </span>
        <span *ngIf="notification.module != 'pickup'" class="asigneName">
            {{splitName(notification.metadata.who || '')}},
        </span>
        <span *ngIf="notification.module == 'pickup'" class="asigneName">
            {{(notification.metadata.where ? notification.metadata.who + "," || '' : notification.metadata.who)}}
        </span>
        <span class="placeName">{{notification.metadata.where}}</span>
    </div>
    <div>
        <span class="dateName" [ngClass]="{'time__exceed': timeExceed}">
            <i class="material-icons icon__war" *ngIf="timeExceed">warning</i>
            {{ lastModificationDate(notification.creationDate)}}
        </span>
        <span class="txt__id" [ngClass]="{'txt__id__view': notification.read}">
            {{notification.metadata?.idReference}}
        </span>

    </div>
</div>
<div *ngIf="notification.metadata?.action === 'comments-tagged'" class="row card__notifi  pointer margin0" (click)="onActionEvent()"
    [ngClass]="{'noview': !notification.read, 'pop__up': isPopup}">
    <div class="title-area">
        <span  *ngIf="notification.metadata.authorPicture">
            <img [src]="notification.metadata.authorPicture" class="circle responsive-img material-icons icon" />
        </span>
        {{ splitName(notification.metadata.authorName || '') }}
        <span class="txt__normal">
            {{ (notification.metadata.action) | translate }}
        </span>
     </div>
    <div>
        <span class="asigneName">
            {{(notification.metadata.who || '')}}
        </span>  
    </div>
   <div>
    <span class="dateName" [ngClass]="{'time__exceed': timeExceed}">
        <i class="material-icons icon__war" *ngIf="timeExceed">warning</i>
        {{ lastModificationDate(notification.creationDate)}}
    </span>
   </div>
</div>