import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef,HostListener  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-requests-preview-update-finished-returned',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @ViewChild("inputAutofocus") inputAutofocus: ElementRef;
  @Output() otherActionEvent = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Input() request: any;
  @Input() title: string;
  @ViewChild("onFocusInput") onFocusInput: ElementRef<HTMLInputElement>;
  @Input() disabledButton = false;
  loading = false;
  changeDetected = false;
  showClearButton = false;
  hasExecuted = false;
  comment = '';
  focusComment: boolean = true;
  langug = "en";
  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router,
  ) {
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.inputAutofocus) {
        this.inputAutofocus.nativeElement.focus();
      }
    }, 200);
    this.initComponent();
  }

  initComponent() {
    this.loading = true;
    Promise.all([])
      .then(() => {
        this.changeContent()
        this.loading = false;
      });
  }

  parseListItems(): string {
    return this.request.menus.reduce((result, current) => {
      current.items.map(i => result.push(i.name));
      return result;
    }, []).join(", ")
  }

  changeContent() {
    this.disabledButton = !(this.comment.length > 0);
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === 'Enter' && !this.hasExecuted) {
          this.openDialogConfirm();
          this.hasExecuted = true;
      }
  }
  
  openDialogConfirm() {
      if (this.comment.trim() === '') {
          this.focusComment = false;
          return;
      }
      if (!this.disabledButton) {
          this.output.emit({
              comment: this.comment,
              codename: 'finished'
          });
          this.disabledButton = true;
      }
  }


  cancel() {
    this.cancelEvent.emit(null);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  limitField(ev, regex?) {
    if (ev.target.value === ' ') ev.target.value = ev.target.value.replace(' ', '')
    if (ev.target.value.includes('  ')) ev.target.value = ev.target.value.replace('  ', ' ')
    if (regex && ev.target.value.match(regex)) ev.target.value = ev.target.value.replace(regex, "");
  }
}
