<div class="container-fluid" *ngIf="!loading" (resized)="onResize($event)">
    <div class="row margin0 printHidden" *ngIf="!onlyDashboard">
        <div class="col s12 padding0">
            <app-guesthub-navigation-top (outputShowSidebar)="sidebar($event)" [state]="state">
            </app-guesthub-navigation-top>
        </div>
    </div>
    <div class="row">
        <div class="col s12 padding0">
            <div class="col  padding0 printHidden" *ngIf="!movil"
                [ngClass]="{'l1_0_5 xl_0_5':!showMenu && !movil ,'xl_1 l1_1':showMenu && !movil}">
                <div id="menuSide" class="nav" [ngClass]="{'nav':showMenu,'wihtOutnav':!showMenu ,'wihtOutnav2':movil}"
                    [ngStyle]="{'height': heightDisplayMenu}" style="overflow-y:auto; border-right: solid 1px #DDDDDD;">
                    <app-guesthub-navigation-side [showSide]="showMenu"></app-guesthub-navigation-side>
                </div>
                <div class="padding-left-ringth15 "
                    style="height: 30px!important; border-right: 1px solid rgb(221, 221, 221);" *ngIf=" showMenu">
                    <a class="floatFooterName" href="http://norelian.com/"> {{ getFullYear }} © Norelian LLC</a>
                </div>
            </div>
            <div class="padding0 printHidden" *ngIf="movil && showMenuMovil"
                [ngClass]="{'menuMovilNewResize':!movilIn,'menuMovilNew':movilIn}">
                <div class="nav" [ngStyle]="{'height': heightDisplayMenu}">
                    <app-guesthub-navigation-side [showSide]="'true'" (outputShowSidebar)="outResizeMenuMovil()">
                    </app-guesthub-navigation-side>
                </div>
                <div class="padding-left-ringth15" style="height: 20px!important;" *ngIf=" showMenu">
                    <a class="floatFooterName" href="http://norelian.com/"> {{ getFullYear }} © Norelian LLC</a>
                </div>
            </div>
            <div class="col padding0 " [class.overflowy]="params &&  !params.fixed" id="containerRouter"
                [class.padding32LeftRight]="params && !params.minimal" *ngIf="showMenu || movil || !showMenu || !movil"
                [ngClass]="{'l11_0_5  xl11_0_5':!showMenu && !movil , 'xl11_1 l11_1':showMenu && !movil,'s12':movil }">
                <div *ngIf="!params.hidden && !onlyDashboard"
                    class="subheader col xl12 col l12 col m12 col s12 padding0  printHidden ">
                    <app-navigation-header-title></app-navigation-header-title>
                </div>
                <div id="routeroutlet" class="col s12 padding0 classrelative  printImp" *ngIf="auth.isAuthenticated"
                    (click)="outMenu()">
                    <!-- Si da mucho peo volver a poner esta propiedad al div routeroutlet [ngStyle]="{'height': heightDisplay}" -->
                    <router-outlet (activate)="onActivate($event)"></router-outlet>
                    <div class="clearfix"></div>
                    <div style="height: 200px;"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading">
    <div class="center" style="text-align: center;margin-top:15%">
        <img src="assets/images/logo-guesthub.png" alt="" style="width: 150px;">
        <h3 style="margin-top: 0px; height: 15px;">
            <strong style="font-size: 12px;">
                <br>
            </strong>
        </h3>
        <div class="lds-ring"></div>
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>