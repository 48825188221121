<p class="col s12 m12 xl12"></p>
<div class="modal-padding-leftright content-detail scrolling-gavete" #scrollMe>
    <span class="col s12 padding0 padding-bootom-24px" *ngFor="let his of history; let i = index">
        <div class="col s12 padding0">
            <div class="padding0 col s12 m12 l12 xl12">
                <span *ngIf="his.authorPicture" class="btn btn-default btn-floating hiss btn-small
                btn-initials" style="background: #d8d8d8 !important; margin: 2px!important;">
                    <img class="imagen-avatare" [src]="his.authorPicture" width="100%" />
                </span>
                <span class="font-name font12 blod">
                    {{ his.authorName }}
                </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'d MMM yyyy':'TZ' : langug |
                    uppercase |
                    translate}} </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'h:mm a':'TZ' : langug | uppercase
                    }}</span>
            </div>
            <div class="col s12 his padding-left-48">
                <span>
                    <span>
                        <div class="panel-body justify-text" style="font-size: 16px;margin-top: -10px;">
                            <div class="font16" *ngIf="his.type == 'requests-reprograming'">
                                {{ "Has edited the" | translate }}
                                <strong [ngSwitch]="his.from.customizeType">
                                    <ng-container *ngSwitchCase="'no-repeat'">
                                        {{ 'This task is not repeat' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'day'">
                                        <ng-container *ngIf="his.from.repeatCount == 1">
                                            {{ 'Every day' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.from.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.from.repeatCount }} {{ 'days' | translate }}
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'week'">
                                        <ng-container *ngIf="his.from.repeatCount == 1">
                                            {{ 'Every week on' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.from.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.from.repeatCount }} {{ 'weeks on' |
                                            translate }}
                                        </ng-container>
                                        {{ programmingRepeatService.allDaysRepeat(his.from) }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'month'">
                                        <ng-container *ngIf="his.from.repeatCount == 1">
                                            {{ 'Every month' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.from.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.from.repeatCount }} {{ 'months' | translate
                                            }}
                                        </ng-container>
                                        {{ 'the day' | translate }} {{ his.from.repeatDay }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'year'">
                                        <ng-container *ngIf="his.from.repeatCount == 1">
                                            {{ 'Every year' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.from.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.from.repeatCount }} {{ 'years' | translate
                                            }}
                                        </ng-container>
                                        {{ 'the day' | translate }} {{ his.from.repeatDay }}
                                        {{ programmingRepeatService.getMonthDetail(his.from) }}
                                    </ng-container>
                                    <ng-container *ngIf="his.from.customizeType != 'no-repeat'">
                                        {{ 'starting on' | translate }} {{ his.from.startingDate | date:'dd MMM
                                        yyyy':'TZ' :
                                        langug}}
                                        <ng-container *ngIf="his.from.finishedDate">
                                            {{ 'ending the' | translate }} {{ his.from.finishedDate | date:'dd MMM
                                            yyyy':'TZ'
                                            :langug}}
                                        </ng-container>

                                    </ng-container>
                                </strong>
                                {{ 'to' | translate }}
                                <strong [ngSwitch]="his.to.customizeType">
                                    <ng-container *ngSwitchCase="'no-repeat'">
                                        {{ 'This task is not repeat' | translate }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'day'">
                                        <ng-container *ngIf="his.to.repeatCount == 1">
                                            {{ 'Every day' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.to.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.to.repeatCount }} {{ 'days' | translate }}
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'week'">
                                        <ng-container *ngIf="his.to.repeatCount == 1">
                                            {{ 'Every week on' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.to.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.to.repeatCount }}
                                            {{ 'weeks on' | translate }}
                                        </ng-container>
                                        {{ programmingRepeatService.allDaysRepeat(his.to) }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'month'">
                                        <ng-container *ngIf="his.to.repeatCount == 1">
                                            {{ 'Every month' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.to.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.to.repeatCount }} {{ 'months' | translate
                                            }}
                                        </ng-container>
                                        {{ 'the day' | translate }} {{ his.to.repeatDay }}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'year'">
                                        <ng-container *ngIf="his.to.repeatCount == 1">
                                            {{ 'Every year' | translate }}
                                        </ng-container>
                                        <ng-container *ngIf="his.to.repeatCount > 1">
                                            {{ 'Each' | translate }} {{ his.to.repeatCount }} {{ 'years' | translate }}
                                        </ng-container>
                                        {{ 'the day' | translate }} {{ his.to.repeatDay }}
                                        {{ programmingRepeatService.getMonthDetail(his.to) }}
                                    </ng-container>
                                    <ng-container *ngIf="his.to.customizeType != 'no-repeat'">
                                        {{ 'starting on' | translate }} {{ his.to.startingDate | date:'dd MMM
                                        yyyy':'TZ' :
                                        langug}}
                                        <ng-container *ngIf="his.to.finishedDate">
                                            {{ 'ending the' | translate }} {{ his.to.finishedDate | date:'dd MMM
                                            yyyy':'TZ'
                                            :langug}}
                                        </ng-container>

                                    </ng-container>
                                </strong>
                            </div>

                            <div *ngIf="his.type == 'requests-assignee'">
                                <ng-container *ngIf="his.from || his.to">
                                    {{ "Changed assignee" | translate }}
                                    <ng-container *ngIf="his.from">
                                        <span> {{ "from" | translate }}</span>
                                        <b> {{ his.from }}</b>
                                    </ng-container>
                                    <span> {{ "to" | translate }} </span>
                                    <b *ngIf=" !his.to"> {{ "No data" | translate }}</b>
                                    <b *ngIf=" his.to">{{ his.to }} </b>
                                </ng-container>
                            </div>

                            <div class=" font16" *ngIf="his.type == 'comment-deleted'">
                                {{ "Deleted comment:" | translate }}
                                <b> "{{ his.question }}"</b>
                            </div>

                            <div class=" font16" *ngIf="his.type == 'requests-status'">
                                {{ "Changed status from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                                <ng-container *ngIf="his.cancelTask">
                                    {{ 'and future repeats' | translate }}
                                </ng-container>
                            </div>
                            <div class=" font16" *ngIf="his.type == 'requests-change-where'">
                                {{ "Changed where from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>
                            <div class=" font16" *ngIf="his.type == 'task-created'">
                                {{ "You have created this task" | translate }}
                            </div>
                        </div>
                    </span>
                </span>
            </div>
        </div>
    </span>
</div>