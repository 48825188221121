import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-guesthub-component-modal-confirmed',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Output() cancel = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<boolean>();
  @Input() config = {
    type: 'negative',
    textCancel: 'Back',
    textConfirm: 'Cancel',
    title: null,
    description: "Are you sure you want to delete this task?",
    radio: null,
    list: null,
    checkboxes: null,
    disabledButton: false,
    chips: null,
  };

  viewMore = false;

  constructor() { }

  ngOnInit() {
  }

  changeRadio(radio) {
    this.config.radio = this.config.radio.map(r => Object.assign({}, r, {
      checked: r.value == radio.value ? true : false
    }));
    this.config.disabledButton = false;
  }

  changeCheckbox(checkbox, checked: boolean) {
    this.config.checkboxes = this.config.checkboxes.map(r => Object.assign({}, r, {
      checked: r.value == checkbox.value ? checked : r.checked
    }));
    this.config.disabledButton = false;
  }
}