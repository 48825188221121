import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RequestsManagerService } from "../../service";

import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-requests-preview-update',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Output() otherActionEvent = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Input() request: any;
  @Input() users: any;
  @ViewChild("onFocusInputWhere") onFocusInputWhere: ElementRef<HTMLInputElement>;
  @ViewChild("onFocusInputWhat") onFocusInputWhat: ElementRef<HTMLInputElement>;
  setAtype = "";
  @Input() disabledButton = false;
  loading = false;
  whatList = [];
  changeDetected = false;
  showClearButton = false;
  category: any;
  priorities = [
    {
      name: "Highest",
      severity: 1,
      codename: "highest",
      colorHex: "priority-highest",
      icon: "arrow_upward"
    },
    {
      name: "High",
      severity: 2,
      codename: "high",
      colorHex: "priority-high",
      icon: "arrow_upward"
    },
    {
      name: "Medium",
      severity: 3,
      codename: "medium",
      colorHex: "priority-medium",
      icon: "remove"
    },
    {
      name: "Low",
      severity: 4,
      codename: "low",
      colorHex: "priority-low",
      icon: "arrow_downward"
    },
    {
      name: "Lowest",
      severity: 5,
      codename: "lowest",
      colorHex: "priority-lowest",
      icon: "arrow_downward"
    }
  ];
  list = {
    rooms: [],
    publicAreas: []
  };
  searcAssignee: string;
  data: any = {};
  dirty: boolean;
  focusWhat: boolean;
  focus: boolean;
  focusFaq = [];
  searchTxt = "";
  selectedWere: any = null;
  selectedWhat: any = null;
  loadingWhat: boolean;
  loadingWhere: boolean;
  naturalText: string;
  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router,
    private service: RequestsManagerService
  ) {
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    this.loading = true;
    Promise.all([
      this.searchResultWhat({ key: '', target: { value: '' } }),
      this.searchResultWhere({ key: '', target: { value: '' } }),
    ]).then(() => {
      this.selectedWhat = this.initialWhat()
      this.selectedWere = {
        name: this.request.roomNumber || this.request.publicArea,
        value: true
      }
      this.data = this.initialData();
      this.changeContent()
      this.loading = false;
    });
  }

  initialWhat() {
    return {
      categoryId: this.request.categoryId,
      categoryName: this.request.category,
      departmentId: this.request.departmentId,
      departmentName: this.request.department,
      id: this.request.subcategoryId,
      name: this.request.category
    }
  }

  initialData() {
    return {
      followUpQuestions: JSON.parse(JSON.stringify(this.request.followUpQuestions)),
      priorityName: this.request.priorityName,
      priorityColorHex: this.request.priorityColorHex,
      prioritySeverity: this.request.prioritySeverity,
      priorityCodename: this.request.priorityCodename,
      priorityIcon: this.request.priorityIcon,
      assigneeFirstName: this.request.assigneeFirstName,
      assigneeId: this.request.assigneeId,
      assigneeLastName: this.request.assigneeLastName,
      assigneePicture: this.request.assigneePicture,
      requestText: this.request.requestText,
      processId: this.request.subcategoryId,
      whereName: this.request.roomNumber || this.request.publicArea,
      whereType: this.request.roomNumber ? 'rooms' : 'publicArea'
    }
  }

  changeContent() {
    this.disabledButton = (this.data.followUpQuestions || []).filter(fuq => fuq.reply.length === 0).length > 0 ||
      !this.selectedWhat || !this.selectedWere || (JSON.stringify(this.data) === JSON.stringify(this.initialData()));
  }

  limitField(ev, regex?) {
    if (ev.target.value === ' ') ev.target.value = ev.target.value.replace(' ', '')
    if (ev.target.value.includes('  ')) ev.target.value = ev.target.value.replace('  ', ' ')
    if (regex && ev.target.value.match(regex)) ev.target.value = ev.target.value.replace(regex, "");
  }

  cancel() {
    this.cancelEvent.emit(null);
  }

  openDialogConfirm() {
    this.disabledButton = true;
    const saveObj = { ...this.data, what: this.selectedWhat, where: this.selectedWere }
    const fuqNotEqual = JSON.stringify(this.initialData().followUpQuestions) !== JSON.stringify(saveObj.followUpQuestions)
    const final = {
      ...(JSON.stringify(this.initialWhat()) !== JSON.stringify(saveObj.what) ?
        {
          subcategoryId: saveObj.what.id,
          departmentId: saveObj.what.departmentId,
          subcategory: saveObj.what.name,
          requestText: saveObj.requestText
        } : {}),
      ...(this.initialData().assigneeId !== saveObj.assigneeId ? { assigneId: saveObj.assigneeId } : {}),
      ...(this.initialData().priorityCodename !== saveObj.priorityCodename ? { priorityCodename: saveObj.priorityCodename } : {}),
      ...(fuqNotEqual ? { fuqs: saveObj.followUpQuestions } : {}),
      ...(this.data.whereName !== this.initialData().whereName ? {
        whereName: this.data.whereName,
        whereType: this.data.whereType
      } : {}),
    }

    this.otherActionEvent.emit({
      type: 'all-update',
      value: final
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  removeAssignee() {
    this.data.assigneeId = null;
    this.data.assigneeFirstName = null;
    this.data.assigneeLastName = null;
    this.data.assigneePicture = null;
    this.changeContent()
  }


  updateAssignee(event) {
    const user = event.option.value;
    this.data.assigneeId = user && user.id ? user.id : null;
    this.data.assigneeFirstName =
      user && user.firstName ? user.firstName : null;
    this.data.assigneeLastName =
      user && user.lastName ? user.lastName : null;
    this.data.assigneePicture = user && user.picture ? user.picture : null;
    this.changeContent()
  }

  searchResultWhat(e) {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(e.key)) return
    this.loadingWhat = true;
    if (typeof e.target.value === 'string' && e.target.value !== '[object Object]') {
      this.whatList = [];
      this.naturalText = e.target.value
      this.data.requestText = e.target.value;
      this.service.searchWhat(e.target.value)
      .then(response => {
        this.whatList = response.subcategories.concat(response.suggestedes);
        this.loadingWhat = false;
      });
    }
    this.data.processId = null;
    this.selectedWhat = null;
    
    this.changeContent()
  }

  searchResultWhere(event) {
    if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(event.key)) return
    this.loadingWhere = true;
    this.list = Object.assign({}, this.list, {
      rooms: [],
      publicAreas: []
    });
    this.searchTxt = event.target.value;
    this.data.whereType = null;
    this.data.whereName = null;
    this.selectedWere = null;
    this.service.searchWhere(this.searchTxt)
      .then(response => {
        this.list = {
          ...this.list,
          ...response,
          publicAreas: response.publicAreas.map(publicArea => ({
            ...publicArea,
            name: publicArea.names.find(name => (name.language == 2 ? 'es' : 'en') === JSON.parse(localStorage.getItem("lang")).lang)?.name || publicArea.name
          }))
        };
        this.loadingWhere = false;
      });
    this.changeContent();
  }

  setWhere(event) {
    this.data.whereType = event.option.value.type;
    if (event.option.value.names) {
      this.data.whereName = event.option.value.names || [];
      this.data.whereType = 'publicArea'
    } else {
      this.data.whereName = event.option.value.name;
    }
    this.selectedWere = event.option.value;
    this.searchTxt = `${event.option.value.name}`;
    setTimeout(() => this.onFocusInputWhere.nativeElement.value = event.option.value.name, 10);
    document.getElementById("where").blur();
    this.changeContent();
  }

  clearWhat(all?: boolean) {
    this.whatList = [];
    if (all) {
      this.data.requestText = "";
      this.onFocusInputWhat.nativeElement.value = this.data.requestText;
    }
    this.data.processId = null;
    this.selectedWhat = null;
    this.changeContent()
  }

  clearWhere() {
    this.list = {
      ...this.list,
      rooms: [],
      publicAreas: [],
    };
    this.searchTxt = "";
    this.data.whereType = null;
    this.data.whereName = null;
    this.selectedWere = null;
    this.onFocusInputWhere.nativeElement.value = this.searchTxt;
    this.changeContent();
  }

  setWhat(event) {
    Promise.all([
      this.service.getCategoryById(event.option.value.categoryId),
      this.service.getSubcategoryById(event.option.value.id)
    ]).then(([category, process]) => {
      this.data.followUpQuestions = category.followUpQuestions.map(f => Object.assign({}, {
        id: f.id,
        question: f.question,
        reply: ""
      })).concat(process.followUpQuestions.map(f => Object.assign({}, {
        id: f.id,
        question: f.question,
        reply: ""
      })))
      this.changeContent()
    })
    this.data.processId = event.option.value.id;
    this.selectedWhat = event.option.value;
    this.data.requestText = this.naturalText;
    this.onFocusInputWhat.nativeElement.value = this.data.requestText;
    document.getElementById("whatInput").blur();
    this.changeContent()
  }

  updatePriority(priority) {
    this.data.priorityName = priority.name;
    this.data.prioritySeverity = priority.severity;
    this.data.priorityCodename = priority.codename;
    this.data.priorityColorHex = priority.colorHex;
    this.data.priorityIcon = priority.icon;
    this.changeContent()
  }
}
