import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UrlBuilderService } from "./url-builder.service";

@Injectable()
export class GetDepService {
  /**
   * GetRequestService constructor
   * @param http Http variable
   * @param urlbuilder UrlBuilderService instance, used to concat strings (urls)
   */
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService
  ) { }

  /**
   * Method made to handle errors if any
   * @param error Error description
   */
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /**
   * Method made to convert data into json objects
   * @param res Data to convert
   */
  private extractData(res: any) {
    return res;
  }

  /**
   * Method creates request sended to backend. <br>
   * Contains four elements as described in backend API.
   */

  getDepartments(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.getDeps(id) + "?status=active")
      .toPromise()
      .catch(this.handleError);
  }
  get_all_suggest(): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.get_all_suggest())
      .toPromise()
      .catch(this.handleError);
  }
  getDepartmentsById(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.getDepsById(id))
      .toPromise()
      .then((resp) => {
        //
        return this.extractData(resp);
      })
      .catch(this.handleError);
  }

  getCategoriesByDep(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.getCategoriesByDep(id) + "?status=active")
      .toPromise()
      .then((resp) => {


        return resp;
      })
      .catch(this.handleError);
  }

  getCategoriesByCategorySubcategoryByDep(
    level: number,
    id: number
  ): Promise<any> {
    return Promise.resolve([]);
  }
  getProcessCategory(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.getProcessCategory(id))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  getProcessDepartment(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.getProcessDepartment(id))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  createProcessCategory(dep: Object) {
    return this.http
      .post<any>(this.urlbuilder.createProcessCategory(), dep)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  setRulesToProcessCategory(rules: Object[], catId: number) {
    return Promise.resolve([]);
  }

  buildFuqUpdate(questions) {
    var questionArray = [];
    for (let q of questions) {
      if (q.q && q.q.trim() !== "") {
        var o: Object;
        o = {
          id: q.id,
          question: q.q,
          description: "description",
          statusId: 1,
        };
        questionArray.push(o);
      }
    }

    return questionArray;
  }

  buildFuq(questions) {
    var questionArray = [];
    for (let q of questions) {
      var o: Object;
      o = {
        question: q,
        description: "description",
        statusId: 1,
      };
      questionArray.push(o);
    }

    return questionArray;
  }

  buildProcessCategory(
    name: string,
    color: string,
    level: number,
    parentId: number,
    questions: Object[]
  ): Object {
    var questionArray = this.buildFuq(questions);
    return {
      name: name,
      colorHex: color,
      description: "description",
      parentCategoryId: parentId,
      followUpQuestions: questionArray,
    };
  }

  buildProcess(
    name: string,
    parentId: number,
    questions: Object[],
    timeExecutionHours: number,
    timeExecutionMinutes: number,
    followUpTimeHours: number,
    followUpTimeMinutes: number
  ): Object {
    var questionArray = this.buildFuq(questions);
    var builtProcess = {
      name: name,
      description: "description",
      processCategoryId: parentId,
      timeExecutionHours: timeExecutionHours ? timeExecutionHours : 0,
      timeExecutionMinutes: timeExecutionMinutes ? timeExecutionMinutes : 0,
      followUpTimeHours: followUpTimeHours ? followUpTimeHours : 0,
      followUpTimeMinutes: followUpTimeMinutes ? followUpTimeMinutes : 0,
      followUpQuestions: questionArray,
    };

    return builtProcess;
  }

  createProcess(process: Object) {
    return this.http
      .post<any>(this.urlbuilder.createProcess(), process)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  updateProcessCategory(id, o) {
    return this.http
      .put<any>(this.urlbuilder.updateProcessCategory(id), o)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  updateProcessDepartment(id, o) {
    return this.http
      .put<any>(this.urlbuilder.updateProcessDepartment(id), o)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  objectCatUpdate(
    id: number,
    name: string,
    color: string,
    parentId: number,
    questions: string[]
  ) {
    var questionArray = this.buildFuqUpdate(questions);
    return {
      id: id,
      name: name,
      colorHex: color,
      description: "description",
      parentCategoryId: parentId,
      followUpQuestions: questionArray,
    };
  }

  objectDepUpdate(id: number, name: string, color: string): Object {
    return {
      id: id,
      name: name,
      colorHex: color,
      description: "description",
      followUpQuestions: [],
    };
  }

  getProcessesByCategory(id: number) {
    return this.http
      .get<any>(this.urlbuilder.getProcessesByCategory(id) + "?status=active")
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  getProcessById(id: number) {
    return this.http
      .get<any>(this.urlbuilder.getProcessById(id))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  objectProcessUpdate(
    id,
    name,
    parentId,
    questions,
    timeExecutionHours,
    timeExecutionMinutes,
    followUpTimeHours,
    followUpTimeMinutes
  ) {
    var questionArray = this.buildFuqUpdate(questions);
    return {
      id: id,
      name: name,
      description: "string",
      processCategoryId: parentId,
      timeExecutionHours: timeExecutionHours,
      timeExecutionMinutes: timeExecutionMinutes,
      followUpTimeHours: followUpTimeHours,
      followUpTimeMinutes: followUpTimeMinutes,
      followUpQuestions: questionArray,
    };
  }

  updateProcess(id, o) {
    return this.http
      .put<any>(this.urlbuilder.updateProcess(id), o)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }
  deleteProcessSubcategory(id: number) {
    return this.http
      .delete<any>(this.urlbuilder.deleteProcessSubcategory(id))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }
  deleteSuggestedSubcategoryById(name) {
    return this.http
      .delete<any>(this.urlbuilder.deleteSuggestedSubcategoryById(name))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }

  mergeSuggestedSubcategory(data) {
    return this.http
      .put<any>(this.urlbuilder.mergeSuggestedSubcategory(), {
        SubcategoryId: data.SubcategoryId,
        suggested: data.suggested
      })
      .toPromise()
      .then((resp) => resp)
      .catch(this.handleError);
  }
  searchCat(e: string): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.searchCat(e))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }
  searchSubCat(e: string): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.searchSubCat(e))
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(this.handleError);
  }
}

