import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../services/url-builder.service";
import { Auth0Service } from "../auth0.service";
import { environment } from "../../environments/environment";

@Injectable()
export class MyProfileService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  languages() {
    return Promise.resolve([
      {
        id: 1,
        code: "en",
        name: "English",
        fileName: "en.json"
      }, {
        id: 2,
        code: "es",
        name: "Español",
        fileName: "es.json"
      }
    ]);
  }

  getUserInformation() {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/settings/user/")
      .concat(
        this.auth.userProfile.id
      )}`).toPromise();
  }

  update(data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http.put<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(this.auth.userProfile.id)}`, data).toPromise();
  }

  updatePassword(data) {
    data = Object.assign({}, {
      userId: this.auth.userProfile.id,
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmNewPassword: data.repeatPassword
    });
    return this.http.put<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(this.auth.userProfile.id)
      .concat(`/password`)}`, data).toPromise();
  }
}
