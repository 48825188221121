import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guesthub-component-monitor-result',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {

  @Input() title: string = 'text';
  @Input() titleColor: string = 'text';
  @Input() iconName: string = 'text';
  @Input() iconColor: string = 'text';
  @Input() description: string = 'text';
  @Input() descriptionColor: string = 'text';

  @Input() config = {
    iconName: 'task_alt',
    iconColor: 'var(--gh-color-dynamic-1)',
    title: "You're up to date!",
    titleColor: 'var(--gh-color-dynamic-1)',
    description: "You don't have any pending requests",
    descriptionColor: "var(--gh-color-letter)",
  };

  constructor() { }

  ngOnInit() {
  }
}