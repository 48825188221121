import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "./service";
import { EventsService } from "../../../events.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() module: string;
  loading = true;
  notifications: Array<any> = [];
  showNotifications = false;
  langug = "en";
  subscribeChannelNotification: any;
  showDropdownMoreOptions = false;
  cantPage = 1;
  infinityScrollDisabled = false;
  loadingMoreRequests = false;

  constructor(
    public translate: TranslateService,
    private service: NotificationsService,
    @Inject("EventsService") private eventsService: EventsService
  ) {
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnDestroy(): void {
    this.subscribeChannelNotification.unsubscribe();
    this.subscribeChannelNotification = undefined;
  }

  ngOnInit() {
    this.loading = true;
    this.service
      .listNotifications(undefined, ({ Messages: 'message', Notifications: 'default' })[this.module])
      .then((notifications) => {
        this.notifications = notifications;
        this.loading = false;

        this.subscribeChannelNotification = this.eventsService.channels.notifications.subscribe((notification) => {
          if (notification.type == ({ Messages: 'message', Notifications: 'default' })[this.module]) {
            this.service.listNotifications(
              undefined, ({ Messages: 'message', Notifications: 'default' })[this.module]
            )
              .then((notifications) => {
                this.notifications = notifications;
              });
          }
        });
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });

    const scrollableDiv = document.getElementById("scrollnotify");

    scrollableDiv.addEventListener("scroll", () => {
      const scrollTop = scrollableDiv.scrollTop;
      const scrollHeight = scrollableDiv.scrollHeight;
      const clientHeight = scrollableDiv.clientHeight;

      if (scrollTop / (scrollHeight - clientHeight) > 0.6) {
        if (!this.infinityScrollDisabled) {
          this.infinityScrollDisabled = true;
          this.loadingMoreRequests = true;
          ++this.cantPage;
          this.service
            .listNotifications(this.cantPage)
            .then((notifications) => {
              notifications.map((req) => this.notifications.push(req));
              this.infinityScrollDisabled =
                notifications.length < 20 ? true : false;
              this.loadingMoreRequests = false;
            });
        }
      }
    });
  }

  onClose() {
    this.close.emit(true);
  }

  clearAllNotifications() {
    this.service.deleteAll(({ Messages: 'message', Notifications: 'default' })[this.module]);
    this.onClose();
  }
}
